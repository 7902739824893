import { AuthorizationDto, CurrentSubscriptionStatusResponse } from '@swagger-codegen/*';
import _ from 'lodash';
import { createDateNoTime, isToday, isWeekendDay } from '@services/functions';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

/**
 * model used to track and cache plan information
 */
export interface UserSubscriptionModel {
  hasMonthly: boolean;
  hasWarrior: boolean;
  hasWeekPass: boolean;
  hasWeekendPass: boolean;
  hasMeetPass: boolean;
  hasDayPasses: string[];
  needsNewPaymentMethod: boolean;
  recurringSubscriptions: CurrentSubscriptionStatusResponse[];
  dayPasses: CurrentSubscriptionStatusResponse[];
  weekPasses: CurrentSubscriptionStatusResponse[];
  weekendPasses: CurrentSubscriptionStatusResponse[];
  freePass: CurrentSubscriptionStatusResponse;
  meetPasses: CurrentSubscriptionStatusResponse[];
  isNotFreePass: boolean;
  weekPassStartDate: string;
  weekendPassStartDate: string;
  firstSubscription: CurrentSubscriptionStatusResponse;
  currentDaySubscription: AuthorizationDto;
}

/**
 * access checker to track the user's current subscription.
 */
export interface UserAuthorizationModel {
  model?: { [key: string]: AuthorizationDto };
}

/**
 * used to check access to app
 */
export class FeatureFlagService {
  model: { [key: string]: AuthorizationDto };

  constructor(data?: UserAuthorizationModel) {
    this.model = data.model;
  }

  get racePickerSelectedDate(): string {
    if (!localStorage.getItem('RacePickerSelectedDate')) {
      localStorage.setItem('RacePickerSelectedDate', moment().tz('America/Los_Angeles').format('yyyy/MM/DD'));
    }
    return localStorage.getItem('RacePickerSelectedDate');
  }

  canAccessHorseTimes(): boolean {
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'PP_HORSE_RESULT_TIME');
    }
    return false;
  }

  canAccessDay(day: string): boolean {
    if (_.isEmpty(day)) {
      return false;
    }
    const todayPlan = this.model[day];
    if (!todayPlan) {
      return false;
    }
    switch (todayPlan?.planCode) {
      case 'WEEKENDWARRIOR':
        return isWeekendDay(day) || isToday(createDateNoTime(day), createDateNoTime());
      case 'NONE':
      case 'None':
        return false;
    }
    return true;
  }

  canAccessTrack(day: string, track: string): boolean {
    if (!this.canAccessDay(day) || _.isEmpty(track)) {
      return false;
    }
    if (this.model[day]?.availableTracks?.length > 0) {
      return this.model[day]?.availableTracks.find((t) => t?.toLowerCase() === track?.toLowerCase()) !== undefined;
    }
    return true;
  }

  canAccessRace(day: string, track: string, raceNumber: number): boolean {
    if (!this.canAccessTrack(day, track) || raceNumber < 1) {
      return false;
    }
    if (this.model[day]?.availableRaces?.length > 0) {
      return this.model[day]?.availableRaces.find((t) => t === raceNumber) !== undefined;
    }
    return true;
  }

  canAccessGsrPlus(): boolean {
    if (!environment.enable.enableGsr) {
      return false;
    }
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'GSR_PLUS');
    }
    return false;
  }

  canAccessJockey(): boolean {
    if (!environment.enable.enableJockey) {
      return false;
    }
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'JOCKEY');
    }
    return false;
  }

  canAccessTrouble(): boolean {
    if (!environment.enable.enableTrouble) {
      return false;
    }
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'TROUBLE');
    }
    return false;
  }

  canAccessLifetime(): boolean {
    if (!environment.enable.enableLifetime) {
      return false;
    }
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'LIFETIME');
    }
    return false;
  }

  canAccessTrainersEdge(): boolean {
    if (!environment.enable.enableTrainersEdge) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'TRAINERS_EDGE');
    }
    return false;
  }

  canAccessTrainersSearch(): boolean {
    if (!environment.enable.enableTrainersSearch) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'TRAINERS_SEARCH');
    }
    return false;
  }

  canAccessMorningLine(): boolean {
    if (!environment.enable.enableMorningLines) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'MORNING_LINE');
    }
    return false;
  }

  canAccessDashboard(): boolean {
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'DASHBOARD');
    }
    return false;
  }

  canAccessHandiView(): boolean {
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'HANDIVIEW');
    }
    return false;
  }

  canAccessHandiViewPlus(): boolean {
    if (!environment.enable.enableLinkFeature) {
      return false;
    }
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'HANDIVIEW_PLUS');
    }
    return false;
  }

  canAccessNotes(): boolean {
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'NOTES');
    }
    return false;
  }

  canAccessPreRaceSor(): boolean {
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'PRE_RACE_SOR') &&
        !this.canStarterView();
    }
    return false;
  }

  canAccessTicketBook(): boolean {
    if (!environment.enable.enableTicketBook) {
      return false;
    }

    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'TICKET_BOOK');
    }
    return false;
  }

  canAccessContests(): boolean {
    if (!environment.enable.enableContests) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'CONTESTS');
    }
    return false;
  }

  canAccessNeedsTheLead(): boolean {
    return environment.enable.enableNeedsTheLead;
  }

  canAccessProgenyPredictor(): boolean {
    return environment.enable.enableProgenyPredictor;
  }

  canAccessOutstandingHorses(): boolean {
    if (!environment.enable.enableOutstandingHorses) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'OUTSTANDING_HORSES');
    }
    return false;
  }

  getRedirectUrl(): string {
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]?.redirectUrl) {
      return this.model[currentDate].redirectUrl;
    }
    return 'account-settings';
  }

  canAccessScottyPicksAdmin(): boolean {
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'SCOTTY_PICKS_ADMIN');
    }
    return false;
  }

  canHorseSearch(): boolean {
    if (!environment.enable.enableHorseSearch) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'HORSE_SEARCH');
    }
    return false;
  }

  canStarterView(): boolean {
    if (!environment.enable.enableStarterView) {
      return false;
    }
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      const starter_view = !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'STARTER_VIEW');
      if (!!_.find(this.model[currentDate].availableFeatures, (x) => x === 'RACEVIEW')
        && starter_view) {
        return !!localStorage.getItem('show-starter-view');
      }
      return starter_view;
    }
    return false;
  }

  canAccessWeatherWidgetButtons(): boolean {
    const currentDate = this.racePickerSelectedDate;
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'RACEVIEW');
    }

    return true;
  }

  canAccessStarterViewToggle(): boolean {
    return true;
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'RACEVIEW')
        && !!_.find(this.model[currentDate].availableFeatures, (x) => x === 'STARTER_VIEW');
    }
    return false;
  }

  canAccessScottyPicks(): boolean {
    return environment.enable.enableScottyPicks;
  }

  canAccessHorsesToWatch(): boolean {
    return environment.enable.enableHorsesToWatch;
  }

  canAccessEdgeBets(): boolean {
    return environment.enable.enableEdgeBets;

  }
  canAccessRaceSimulator(): boolean {
    return true;
    // return environment.enable.enableRaceSimulator;
  }
  canAccessAdminOverride(): boolean {
    const currentDate = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
    if (this.model && this.model[currentDate]) {
      return this.model[currentDate].availableFeatures.includes('ADMIN_OVERRIDE');
    }
    return false;
  }
}
