import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  public needReload = false;
  public needReload$ = new BehaviorSubject<boolean>(false);
  // this will be replaced by actual hash post-build.js
  private currentHash: string;
  private currentVersion: string;
  // private currentVersion = '2021-04-04T14:45:22.693Z';
  private newHash = '';

  constructor(private http: HttpClient, private updates: SwUpdate, private db: AngularFireDatabase) {
    this.currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    this.currentVersion = '{{POST_BUILD_ENTERS_VERSION_HERE}}';
  }

  /**
   * Checks in every set frequency the version of frontend application
   * param url
   * param number frequency - in milliseconds, defaults to 10 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 10): void {
    this.updates.available.subscribe((event) => {
      if (event.current !== event.available) {
        this._needReload(true);
      }
    });
    if (environment.firebase.enable) {
      this.db
        .object<{ currentVersion: string }>(environment.firebase.url.buildTime)
        .valueChanges()
        .subscribe((x) => {
          if (this.currentVersion.indexOf('POST_BUILD') === -1 && (!x || this.currentVersion > x?.currentVersion)) {
            this.db
              .object(environment.firebase.url.buildTime)
              .update({ currentVersion: this.currentVersion })
              .then((x) => {});
          }
          if (x?.currentVersion && this.currentVersion < x?.currentVersion) {
            this.checkVersion(url);
          }
          if (environment.firebase.showConsoleLog) {
            console.log([environment.firebase.url.buildTime, x]);
          }
        });
    }
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  public reload(): void {
    this._needReload(false);
    location.reload();
  }

  private _needReload(value: boolean): void {
    if (value) {
      this.clearSwCaches();
    }
    // Update login status subject
    this.needReload$.next(value);
    this.needReload = value;
  }

  public clearSwCaches() {
    try {
      if ('serviceWorker' in navigator) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Will do the call and check if the hash has changed or not
   * param url
   */
  private checkVersion(url): void {
    // timestamp these requests to invalidate caches
    this.http
      .get(url + '?t=' + new Date().getTime())
      .first()
      .subscribe(
        (response: any) => {
          this.newHash = response.hash;
          this.hasHashChanged();
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * param currentHash
   * param newHash
   * returns boolean
   */
  private hasHashChanged(): void {
    // If new version, do something
    if (this.currentHash && this.newHash && this.currentHash !== this.newHash) {
      // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
      // for an example: location.reload();
      // console.log('the page need Reload');
      this._needReload(true);
    } else {
      this._needReload(false);
      // store the new hash so we wouldn't trigger versionChange again
      // only necessary in case you did not force refresh
      this.currentHash = this.newHash;
    }
  }
}
