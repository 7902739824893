import Rollbar from 'rollbar';
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

const rollbarConfig = {
  accessToken: 'f256067ac5b64ac2a12eef1b71786efd',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.production ? 'prod' : 'staging',
    person: {
      id: localStorage.getItem('userId'),
      username: localStorage.getItem('email'),
      email: localStorage.getItem('email'),
    },
    client: {
      javascript: {
        source_map_enabled: true, // this is will be true by default if you have enabled this in your settings
        code_version: '{{POST_BUILD_ENTERS_VERSION_HERE}}',
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
        captureUncaught: true,
        captureUnhandledRejections: true,
      },
    },
  },
  checkIgnore: (isUncaught: boolean, args: any, payload: any) => {
    // we want to check the status which buried inside the payload
    if (payload && payload.body && payload.body.message && payload.body.message.extra) {
      if (payload.body.message.extra.status === 0 && payload.body.message.extra.statusText === 'Unknown Error') {
        return true;
      }
    }

    return false;
  },
};
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root',
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    console.error(err.originalError || err);
    if (environment.enable.enableRollbar) {
      this.rollbar.error(err.originalError || err);
    }
  }
}

export function rollbarFactory(): any {
  return new Rollbar(rollbarConfig);
}
