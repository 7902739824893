export class ProductCodes {
  public static singleTrack = "118-USD-Daily";
  public static singleDay = "116-USD-Daily";
  public static earlyBird = "120-USD-Weekly";
  public static starterPass = "StarterPlan-USD-Daily";
  public static starterPlan = "StarterPlan-USD-Monthly";
  public static starterPlanYearly = "StarterPlan-USD-Yearly";
  public static freeSignup = "FreeSignup-USD-Daily";
  public static singleWeekend = "115-USD-Weekly";
  public static eliteMonthly = "113-USD-Monthly";
  public static eliteAnnual = "113-USD-Yearly";
  public static eliteWeekend = "114-USD-Monthly";
  public static eliteWeekendYearly = "114-USD-Yearly";
  public static specialSingleTrack = "Surge-Single-Day-Single-Track-Pass-USD-Daily";
  public static specialAllTrack = "Surge-Single-Day-All-Track-Pass-USD-Daily";
  public static specialWeekend = "Surge-Single-Weekend-All-Track-Pass-USD-Weekly";
}
