import { MetaReducer } from '@ngrx/store';
import { ApplicationState } from './state/application.state';
import { environment } from '../../environments/environment';
import {debug} from "./metareducer/debug.reducer";

export const metaReducers: MetaReducer<ApplicationState>[] = environment.production ? [] : [debug];

export function getMetaReducers(): MetaReducer<ApplicationState>[] {
  return metaReducers;
}
