import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSandboxService } from '@services/sandbox.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  mobile$: Observable<boolean> = this.sb.mobile$;

  constructor(private sb: AppSandboxService) {}

  ngOnInit(): void {}
}
