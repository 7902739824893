import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor() {
  }

  public headerState$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isLandscape$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public showModalWillPays$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showModalPool$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showModalProbables$: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
