<ng-template #emptyAnswer> -</ng-template>

<div class="close-block">
  <u (click)="closeModal()">close</u>
</div>
<div class="sire-dam-header d-flex">
  <div class="flex-even name-block">
    {{data.parentType}} of {{data?.horseData?.horseProfile?.horseName}}
  </div>
  <div class="flex-even link-block">
    <ng-container *ngIf="data.parentType==='sire'">
      <span class="name" (click)="changeView(data?.horseData?.raceDetails?.id,
               data?.horseData?.horseProfile?.damRegistrationNumber, 'dam')"><span
        class="link-name"> See Dam:</span>  {{data?.horseData?.horseProfile?.damName}}</span>
    </ng-container>
    <ng-container *ngIf="data.parentType==='dam'">
      <span class="name" (click)="changeView(
           data?.horseData?.raceDetails?.id,
               data?.horseData?.horseProfile?.sireRegistrationNumber, 'sire')"><span
        class="link-name"> See Sire:</span>  {{data?.horseData?.horseProfile?.sireName}}</span>
    </ng-container>
  </div>
</div>
<div class="sire-dam-body">
  <div class="grey-block">
    <ng-container *ngIf="data.parentType==='sire'">
      Sire Name: {{generateSireName()}}
    </ng-container>
    <ng-container *ngIf="data.parentType==='dam'">
      Dam Name: {{data?.horseData?.horseProfile?.damName}}
    </ng-container>
  </div>
  <div class="d-flex">
<!--    <div>-->
<!--      <div class="toggle">-->
<!--        <div (click)="toggleView(View.SireOrDamPpsView)"-->
<!--             [ngClass]="{ active:  currentView === View.SireOrDamPpsView }"-->
<!--             class="font-b">Past Performances-->
<!--        </div>-->
<!--        <div (click)="toggleView(View.GsrPlusView)"-->
<!--             [ngClass]="{ active:  currentView === View.GsrPlusView }"-->
<!--             class="font-b">GSR Plus-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="flex-fill">
      <app-sire-dam-profile-header [sireDamProfile]="sireDamPpsDto" [raceDetails]="data?.horseData?.raceDetails">
      </app-sire-dam-profile-header>
    </div>
  </div>
  <div>
    <!--  sireDamPpsDto.gsrGridRows,-->
    <ng-container *ngIf="currentView === View.GsrPlusView">
      <app-gsr *ngIf="data" [gsrGrid]="{
                  gsrGrid:data?.horseData?.horseProfile?.gsrGrid,
                  surface: data?.horseData?.raceDetails?.surface,
                  distance: data?.horseData?.raceDetails?.distance,
                  isWet: data?.horseData?.raceDetails?.isWet
                }">
      </app-gsr>
    </ng-container>
    <ng-container *ngIf="currentView === View.SireOrDamPpsView">
      <i class="fa fa-spinner fa-pulse sire-dam-loading" aria-hidden="true" *ngIf="sireDamPpsLoading"></i>
      <ng-container *ngIf="!sireDamPpsLoading">
        <ng-container *ngIf="!sireDamPps?.length">
          <div class="text-center no-data">
            No lifetime races...
          </div>
        </ng-container>
        <ng-container *ngIf="sireDamPps?.length > 0">
          <div class="table-fixed">
            <table class="table">
              <thead>
              <tr class="table-header">
                <th>Date</th>
                <th>Track</th>
                <th>Con</th>
                <th
                  *ngIf="data.horseData.raceDetails.breedType === 'MX' || data.horseData.raceDetails.breedType === 'QH'">
                  Wind
                </th>
                <th>
                  <ng-container *ngIf="data.horseData.raceDetails.breedType === 'QH'"> Distance</ng-container>
                  <ng-container *ngIf="data.horseData.raceDetails.breedType !== 'QH'"> Dist-Surf</ng-container>
                </th>
                <th>Age</th>
                <th>EQ</th>
                <th class="pb-0">
                  <div class="desktop-handiview">
                    <div (click)="changeToggleJockey()" class="handiview-toggle">
                      <div [ngClass]="{ active: toggleJockey }" class="font-gotham-b">Jockey</div>
                      <div [ngClass]="{ active: !toggleJockey }" class="font-gotham-b">Trainer</div>
                    </div>
                  </div>
                </th>
                <th class="pb-0">
                  <div class="desktop-handiview">
                    <div (click)="changeToggle()" class="handiview-toggle">
                      <div [ngClass]="{ active: toggleTrouble }" class="font-gotham-b">Trouble</div>
                      <div [ngClass]="{ active: !toggleTrouble }" class="font-gotham-b">Level</div>
                    </div>
                  </div>
                </th>
                <th class="header-sor">
                  <div class="font-gotham-b">SoR</div>
                </th>
                <!--                <th class="th-gsr">
                                  <div class="font-gotham-b">GSR</div>
                                </th>-->
                <th>Odds</th>
                <th *ngIf="data.horseData.raceDetails?.breedType !== 'TB'">Horse</th>
                <th class="background background-left">
                  <div>Pst/Fld</div>
                </th>
                <th class="background">
                  <div>Broke</div>
                </th>
                <th class="background">
                  <div>1st</div>
                </th>
                <th
                  *ngIf="data.horseData.raceDetails.breedType !== 'MX' && data.horseData.raceDetails.breedType !== 'QH'"
                  class="background">
                  <div>2nd</div>
                </th>
                <th class="background">
                  <div>Stretch</div>
                </th>
                <th class="background background-right">
                  <div>Finish</div>
                </th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let p of sireDamPps; let i = index">
                <tr
                  [ngClass]="{ 'was-race-clicked': p?.wasRaceClicked,  'current-track-win': p.finishPosition === 1 }">
                  <td class="col-w-71 text-center">
                      <span *ngIf="p.date != null; else emptyAnswer">
                        <span *ngIf="p.isLayoffRace == true" style="border-top: 1px solid blue">{{
                          p.date | date : 'shortDate'
                          }}</span>
                        <span *ngIf="p.isLayoffRace == false">{{ p.date | date : 'shortDate' }}</span>
                      </span>

                  </td>
                  <td class="col-w-55">
                    <span *ngIf="p.track != null; else emptyAnswer">{{ p.track }}{{ p.raceNumber + '' | slice : 0 : 3 }}
                    </span>
                  </td>
                  <td class="col-w-30">
                    <span *ngIf="p.trackCondition != null; else emptyAnswer">{{ replaceDashes(p.trackCondition) }}
                    </span>
                  </td>
                  <td
                    *ngIf="data.horseData.raceDetails.breedType === 'MX' || data.horseData.raceDetails.breedType === 'QH'"
                    class="col-w-55">
                    <span *ngIf="p.wind != null; else emptyAnswer">{{ replaceDashes(p.wind) }}</span>
                  </td>
                  <td [ngClass]="{
                      'col-w-56':data.horseData.raceDetails?.breedType !== 'TB',
                      'col-w-70':data.horseData.raceDetails?.breedType === 'TB'
                    }">
                    <span *ngIf="p.distance != null; else emptyAnswer">{{ p.distance
                      }}<span *ngIf="data.horseData.raceDetails.breedType !== 'QH'" class="font-b" [ngClass]="{
                          turf: checkTurf(p.surface),
                          dirt: checkDirt(p.surface),
                          syn: checkSyn(p.surface),
                          inner: checkInner(p.surface),
                          outer: checkOuter(p.surface)
                        }">
                        {{ replaceSurface(p.surface) }}</span>
                    </span>
                  </td>
                  <td class="col-w-45">
                    <span *ngIf="p.ageRestriction != null; else emptyAnswer">{{ p.ageRestriction }}</span>
                  </td>
                  <td class="col-w-30 text-lowercase">
                    <span *ngIf="p.equipment && p.medication != null; else emptyAnswer"><span
                      *ngFor="let equip of p.equipment" [style.color]="equip.isFirstTime ? 'red' : null">{{
                      equip.name
                      }}</span><span [style.color]="p.medication.isFirstTime ? 'red' : null">{{
                      p.medication.name
                      }}</span></span>
                  </td>
                  <ng-container *ngIf="toggleJockey">
                    <td class="td-jockey-name text-truncate">
                      <ng-container *ngIf="p?.jockeyName">
                        <div class="d-flex">
                        <app-modal-jockey [data]="{
                            jockeyStats: {
                              name: p.jockeyName,
                              winPercentage: p.jockeyWinPct,
                              weight: p.jockeyWeight
                            }
                          }"></app-modal-jockey>
                          <div class="flex-even text-right pr-1">{{ p.jockeyWeight }}lbs</div>
                        </div>
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!toggleJockey">
                    <td class="td-trainer-name">
                      <ng-container *ngIf="p?.trainerName">
                        <div [ngClass]="{
                            'first-time': p?.hasTrainerChanged || p?.isFirstTimeTrainer
                          }" class="d-flex">
                          <div class="text-truncate">
                            {{p.trainerName}}
                          </div>
                        </div>
                        <ng-container *ngIf="p?.showClaim">
                          Claimed -
                          {{ p?.claimedPrice | currency : 'USD' : 'symbol' : '1.0-0' }}
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                  <td *ngIf="!toggleTrouble" [ngClass]="{
                      'col-w-170': data.horseData.raceDetails?.breedType !== 'TB'
                    }" class="td-trouble">
                    <app-program-number *ngIf="p.sexRestriction || p.stateRestricted" [restrictionIndicator]="
                        p.sexRestriction
                          ? p.stateRestricted
                            ? 'S' + p.sexRestriction
                            : p.sexRestriction
                          : p.stateRestricted
                          ? 'S'
                          : null
                      " [small]="true" class="d-inline-block">
                    </app-program-number>
                    <span *ngIf="p.level != null; else emptyAnswer">{{ replaceClm(p?.level) }} </span>
                  </td>
                  <td *ngIf="toggleTrouble" [ngClass]="{
                      'col-w-170': data.horseData.raceDetails?.breedType !== 'TB'
                    }" class="td-trouble">
                    <span *ngIf="p.trouble != null; else emptyAnswer">{{ replaceString(p.trouble) }}</span>
                  </td>
                  <td class="col-w-40 td-sor">
                    <ng-container *ngIf="p.soR != null && p.soR != 0; else emptyAnswer">
                      {{ p.soR }}
                    </ng-container>
                  </td>
                  <!--                  <td class="td-gsr col-w-40">
                                      <ng-container *ngIf="p?.gsr?.length > 1 && p?.gsr[1].value; else emptyAnswer">
                                        {{ p.gsr[1].value }}
                                      </ng-container>
                                    </td>-->
                  <td class="col-w-60">
                    <div *ngIf="p.oddsValue != null; else emptyAnswer" [ngClass]="{ 'is-favorite': p.isFavorite }"
                         class="horse-odds">
                      {{ p.oddsValue | number : '1.1-1' }}
                    </div>
                  </td>
                  <td *ngIf="data.horseData.raceDetails?.breedType !== 'TB'" class="col-w-55">
                    <span *ngIf="p?.raceTimeFormatted != null; else emptyAnswer"><span
                      *ngIf="!(p?.raceTimeFormatted.minutes == '0')">
                        {{ p?.raceTimeFormatted.minutes + ':' }}</span>{{ p?.raceTimeFormatted.seconds + '.' +
                    p?.raceTimeFormatted.superscript }}</span>
                  </td>
                  <td class="col-w-45">
                    <div class="down-1">
                      <span *ngIf="p.isDnf">DNF</span>
                      <ng-container *ngIf="!p.isDnf">
                        <span *ngIf="p.postPosition != null; else emptyAnswer">{{ p.postPosition
                          }}/{{ p.numberOfHorses }}</span>
                      </ng-container>
                    </div>
                  </td>
                  <td class="col-w-45">
                    <div class="down-1">
                      <span *ngIf="p.isDnf">DNF</span>
                      <ng-container *ngIf="!p.isDnf">
                        <span *ngIf="p.positionAtBreak != null; else emptyAnswer">{{ p.positionAtBreak }}</span>
                      </ng-container>
                    </div>
                  </td>
                  <td class="col-w-55">
                    <div class="down-1">
                      <span *ngIf="p.isDnf">DNF</span>
                      <ng-container *ngIf="!p.isDnf">
                        <span *ngIf="p.firstCallPosition != null; else emptyAnswer">{{ p.firstCallPosition }} <sup>{{
                          p.firstCallLengths }}</sup></span>
                      </ng-container>
                    </div>
                  </td>
                  <td
                    *ngIf="data.horseData.raceDetails.breedType !== 'MX' && data.horseData.raceDetails.breedType !== 'QH'"
                    class="col-w-55">
                    <div class="down-1">
                      <span *ngIf="p.isDnf">DNF</span>
                      <ng-container *ngIf="!p.isDnf">
                        <span *ngIf="p.secondCallPosition != null; else emptyAnswer">{{ p.secondCallPosition }} <sup>{{
                          p.secondCallLengths }}</sup></span>
                      </ng-container>
                    </div>
                  </td>
                  <td class="col-w-55">
                    <div class="down-1">
                      <span *ngIf="p.isDnf">DNF</span>
                      <ng-container *ngIf="!p.isDnf">
                        <span *ngIf="p.stretchPosition != null; else emptyAnswer">{{ p.stretchPosition }} <sup>{{
                          p.stretchLengths }}</sup></span>
                      </ng-container>
                    </div>
                  </td>
                  <td class="col-w-55">
                    <div class="down-1">
                      <span *ngIf="p.isDnf">DNF</span>
                      <ng-container *ngIf="!p.isDnf&&p?.finishPosition">
                        <span *ngIf="p.preFinishPosition != null; else emptyAnswer">{{ p.preFinishPosition }} <sup>{{
                          p.finishLengths }}</sup></span>
                        <br/>
                        <sub *ngIf="p.isDeadHeat">DH</sub>
                        <sub *ngIf="p.finishPosition > p.preFinishPosition" style="color: red">DQ{{ p.finishPosition }}
                          <sup>{{ data.horseData.getOrdinal(p.finishPosition) }}</sup> </sub><sub
                        *ngIf="p.finishPosition < p.preFinishPosition" style="color: green">PL{{ p.finishPosition }}
                        <sup>{{ data.horseData.getOrdinal(p.finishPosition) }}</sup>
                      </sub>
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
            <!--          <ng-container *ngIf="((tablet$ | async) === false || data.horseProfile?.pastPerformances.length === 0) && currentView ===-->
            <!--          HandiviewViews.PastPerformances">-->
            <!--            <div class="workouts-label">-->
            <!--              <span *ngIf="data.horseProfile?.pastPerformances.length === 0">FIRST TIME STARTER</span>-->
            <!--              WORKOUTS-->
            <!--            </div>-->
            <!--            <ng-container *ngTemplateOutlet="workout"></ng-container>-->
            <!--          </ng-container>-->
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

