import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AccountService, MyAccountResponse, PaymentInfoUpdateArguments } from '@swagger-codegen/*';
import { AppSandboxService } from './sandbox.service';
import { ToastService } from './toast.service';
import { BlockUiService } from './block-ui.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TakeUntilDestroy } from './take-until-destroy.decorator';
import { finalize, takeUntil } from 'rxjs/operators';

@TakeUntilDestroy
@Injectable({
  providedIn: 'root',
})
export class PaymentService implements OnDestroy {
  public account: MyAccountResponse;
  // tslint:disable-next-line:variable-name
  public authorize_net: { loginId: string; clientKey: string; url: string };
  componentDestroy: () => Observable<boolean>;
  paymentIsPutting = false;

  constructor(
    private accountService: AccountService,
    private sb: AppSandboxService,
    private toast: ToastService,
    private ngZone: NgZone
  ) {
    this.clearAccept();
    this.authorize_net = environment.authorize_net;
    this.addAcceptDom(this.authorize_net.url);
  }

  public initAcceptUI(): void {
    BlockUiService.start();
    this.accountService
      .apiAccountMyAccountGet()
      .pipe(finalize(BlockUiService.stop), takeUntil(this.componentDestroy()))
      .subscribe((result) => {
        this.account = result;
        window['SubscriptionComponent'] = {
          component: this,
          zone: this.ngZone,
        };
        BlockUiService.stop();
      });
  }

  addAcceptDom(url: string): void {
    if (!document.getElementById('AcceptUI')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.setAttribute('charset', 'utf-8');
      script.setAttribute('id', 'AcceptUI');
      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.setAttribute('id', 'AcceptUI2');
      script2.innerText = `function responseHandler(response) {
             window['SubscriptionComponent'].zone.run(() => {
             window['SubscriptionComponent'].component.setPaymentData(response);
           })
        }`;
      document.getElementsByTagName('head')[0].appendChild(script);
      document.getElementsByTagName('head')[0].appendChild(script2);
    }
  }

  clearAccept(): void {
    if (document.getElementById('AcceptUI')) {
      this.clearById('AcceptUI');
      this.clearById('AcceptUI2');
      this.clearById('AcceptUIContainer');
      this.clearById('AcceptUIBackground');
    }
  }

  clearById(s: string): void {
    const temp = document.getElementById(s);
    if (temp) {
      temp.parentNode.removeChild(temp);
    }
  }

  setPaymentData(response: any): void {
    if (response.messages.resultCode === 'Error') {
      let i = 0;
      let errorMessage = '';
      while (i < response.messages.message.length) {
        if (i === 0) {
          errorMessage += response.messages.message[i].code + ': ' + response.messages.message[i].text;
        } else {
          errorMessage += ', ' + response.messages.message[i].code + ': ' + response.messages.message[i].text;
        }
        i = i + 1;
      }
      this.toast.error(errorMessage);
    } else {
      if (!this.paymentIsPutting) {
        this.paymentIsPutting = true;
        const paymentInfo: PaymentInfoUpdateArguments = {
          authorizeNetDataDescriptor: response.opaqueData.dataDescriptor,
          authorizeNetDataValue: response.opaqueData.dataValue,
        };
        BlockUiService.start();
        this.accountService
          .apiAccountPaymentInfoPut(paymentInfo)
          .pipe(
            finalize(() => {
              this.paymentIsPutting = false;
              BlockUiService.stop();
            }),
            takeUntil(this.componentDestroy())
          )
          .subscribe((result) => {
            if (result) {
              this.account = result;
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.clearAccept();
  }
}
