<div class="block-ui-backdrop" *ngIf="blockUiMessage">
  <div class="block-ui-body">
    <div class="spinner-container">
      <i class="fa fa-spinner fa-pulse fa-4x"></i>
    </div>
    <div class="block-ui-message">
      {{ blockUiMessage }}
    </div>
    <ng-container *ngIf="blockUiShowReloadButton">
      <div class="block-ui-message-stuck">Tired of waiting? Click the Reload button to give up waiting and reload.</div>
      <div class="block-ui-button">
        <button type="button" class="btn btn-light" (click)="reload()">Reload</button>
      </div>
    </ng-container>
  </div>
</div>
