import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-device',
  template: '',
})
export class ResetDeviceComponent implements OnInit {
  constructor() {
    localStorage.clear();
    window.location.href = '';
  }

  ngOnInit(): void {}
}
