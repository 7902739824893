import {createReducer, on} from "@ngrx/store";
import {MOBILE_MAX_WIDTH, reset, setScreen} from "../actions/screen.actions";
import {ScreenState} from "../state/screen.state";

const initialState: ScreenState = {
  mobile: false,
  tablet: false,
  desktop: false,
};

export const screenReducer = createReducer(
  initialState,
  on(setScreen, (state, {width}) => {
    const mobile = width <= MOBILE_MAX_WIDTH
    const tablet = false; //width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH;
    return ({
      mobile,
      tablet,
      desktop: !mobile && !tablet,
    });
  }),
  on(reset, () => initialState),
);
