import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { LoginService } from '@services/login.service';
import { AccountService } from './swagger-codegen';
import { AppSandboxService } from '@services/sandbox.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ActivityService } from '@services/activity.service';
import { IntercomService } from '@services/intercom.service';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import _ from 'lodash';
import { createUuidv4, getDeviceId, setDeviceId } from '@services/functions';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@services/toast.service';
import { HeaderService } from "./modules/authenticated-module/services/header.service";

@TakeUntilDestroy
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  environment = environment;
  title = 'dashboard';
  allScreens$ = this.sb.allScreens$;
  orientation = {};
  mobile$ = this.sb.mobile$;
  tablet$ = this.sb.tablet$;
  desktop$ = this.sb.desktop$;
  componentDestroy: () => Observable<boolean>;
  private _resize$: Observable<number>;

  constructor(
    private meta: Meta,
    public account: AccountService,
    public loginService: LoginService,
    public sb: AppSandboxService,
    public activity: ActivityService,
    private intercom: IntercomService,
    private headerService: HeaderService,
    private router: Router,
    private readonly titleService: Title,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    activity.log('start app', '{{POST_BUILD_ENTERS_VERSION_HERE}}');
    try {
      // @ts-ignore
      this.orientation = screen['msOrientation'] || screen['mozOrientation'] || (screen.orientation || {}).type;
      // make sure the deviceId is in place from login
      _.map([localStorage.getItem('deviceId')], (id) => {
        if (_.isEmpty(id)) {
          setDeviceId(getDeviceId());
        }
      });
    } catch (e) {
      this.orientation = 'not support';
    }
    this.allScreens$.pipe(takeUntil(this.componentDestroy())).subscribe((result) => {
      if (
        (result.mobile && !result.desktop && !result.tablet) ||
        (!result.mobile && result.desktop && !result.tablet) ||
        (!result.mobile && !result.desktop && result.tablet)
      ) {
        if (result.mobile) {
          this.apply_viewport(375);
          document.body.classList.add('is-mobile-view');
        } else {
          document.body.classList.remove('is-mobile-view');
        }
        if (result.tablet) {
          this.meta.updateTag({
            name: 'viewport',
            content: 'width=1024, initial-scale=1.0',
          });
          document.body.classList.add('is-tablet-view');
        } else {
          document.body.classList.remove('is-tablet-view');
        }
        if (result.desktop) {
          this.meta.updateTag({
            name: 'viewport',
            content: 'width=1366, initial-scale=1.0',
          });
        }
      }
    });

    // only track in production
    if (environment.production) {
      if (this.loginService.isAuthenticated()) {
        this.account
          .apiAccountProfileGet()
          .pipe(takeUntil(this.componentDestroy()))
          .subscribe((result) => {
            if (result) {
              localStorage['email'] = result.email;
              localStorage['userId'] = result.rollbarUserId;
              this.intercom.login(result.firstName, result.lastName, result.email);
            }
          });
      } else {
        this.intercom.visitor();
      }
    }
  }

  getNestedRouteTitles(): string[] {
    let currentRoute = this.router.routerState.root.firstChild;
    const titles: string[] = [];
    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig.data?.title) {
        titles.push(currentRoute.snapshot.routeConfig.data.title);
      }
      currentRoute = currentRoute.firstChild;
    }
    return titles;
  }

  apply_viewport(mw: number): void {
    const ww = window.screen.width;
    const ratio = ww / mw; // calculate ratio
    if (ww < mw) {
      // smaller than minimum size
      this.meta.updateTag({
        name: 'viewport',
        content: `initial-scale=${ratio}, maximum-scale=${ratio}, minimum-scale=${ratio}, user-scalable=no, width=${mw}`,
      });
    } else {
      // regular size
      this.meta.updateTag({
        name: 'viewport',
        content: `initial-scale=1.0, maximum-scale=1, minimum-scale=1.0, user-scalable=yes, width=${ww}`,
      });
    }
  }

  ngOnInit(): void {
    this._resize$ = Observable.fromEvent(window, 'resize').pipe(
      debounceTime(200),
      map(() => window.innerWidth || document.documentElement.clientWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth || document.documentElement.clientWidth),
      tap((width) => this.sb.setWindowWidth(width)),
      tap(() => {
        if (this.loginService.isAuthenticated()) {
          let newOrientation = {};
          try {
            // @ts-ignore
            newOrientation = screen['msOrientation'] || screen['mozOrientation'] || (screen.orientation || {}).type;
          } catch (e) {
            newOrientation = 'not support';
          }

          if (this.orientation !== newOrientation) {
            this.activity.log('rotate');
            this.orientation = newOrientation;
          } else {
            this.activity.log('resize');
          }
          if (newOrientation === 'landscape-primary') {
            this.headerService.isLandscape$.next(true);
          } else {
            this.headerService.isLandscape$.next(false);
          }
          this.activity.logUserDevice();
        }
      })
    );
    this._resize$.pipe(takeUntil(this.componentDestroy())).subscribe();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const titleArray = this.getNestedRouteTitles();
      if (titleArray && titleArray.length) {
        this.titleService.setTitle(this.getNestedRouteTitles().join(' | '));
      }
    });
    const userLang = navigator.language || navigator['userLanguage'];
    if (!localStorage.getItem('lang') && userLang && userLang.slice(0, 2) === 'es') {
      this.toastService.successRequiresClick(
        'Encontramos que su navegador está en español. Si desea cambiar a inglés, vaya a la página "My Account" y cambie el "Help Text Language".'
      );
      localStorage.setItem('lang', 'es');
    }
    this.translateService.use(localStorage.getItem('lang') || 'en');
  }

  ngOnDestroy(): void {
    this._resize$.distinctUntilChanged();
  }
}

declare let window: any;
declare let _mfq: any;
