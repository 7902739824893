import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { AddMetricOverrideService } from './api/addMetricOverride.service';
import { AdminRaceService } from './api/adminRace.service';
import { AdminTrackService } from './api/adminTrack.service';
import { CouponService } from './api/coupon.service';
import { CustomerService } from './api/customer.service';
import { DashboardService } from './api/dashboard.service';
import { GenerateTicketService } from './api/generateTicket.service';
import { GroupsService } from './api/groups.service';
import { HandicappingService } from './api/handicapping.service';
import { HistoryService } from './api/history.service';
import { HorseService } from './api/horse.service';
import { HorseNotesService } from './api/horseNotes.service';
import { HorseWorkoutService } from './api/horseWorkout.service';
import { HorsesToWatchService } from './api/horsesToWatch.service';
import { HorsesToWatchResultsService } from './api/horsesToWatchResults.service';
import { LiveUpdateService } from './api/liveUpdate.service';
import { MetricOverrideService } from './api/metricOverride.service';
import { MetricOverridesService } from './api/metricOverrides.service';
import { MigrateHorseService } from './api/migrateHorse.service';
import { MiscService } from './api/misc.service';
import { MphMetricUpdateService } from './api/mphMetricUpdate.service';
import { PastRaceService } from './api/pastRace.service';
import { PlanService } from './api/plan.service';
import { ProductService } from './api/product.service';
import { ProductIndexService } from './api/productIndex.service';
import { ProductsForUserQueryService } from './api/productsForUserQuery.service';
import { PromoCodeService } from './api/promoCode.service';
import { RaceService } from './api/race.service';
import { RaceNotesService } from './api/raceNotes.service';
import { RaceVersionHandlerService } from './api/raceVersionHandler.service';
import { RacesService } from './api/races.service';
import { RealTimeService } from './api/realTime.service';
import { RemoveMetricOverrideService } from './api/removeMetricOverride.service';
import { ResultsService } from './api/results.service';
import { ScottyPickService } from './api/scottyPick.service';
import { StaticFileService } from './api/staticFile.service';
import { TicketBookService } from './api/ticketBook.service';
import { TrackService } from './api/track.service';
import { TwitterService } from './api/twitter.service';
import { UserService } from './api/user.service';
import { UserNotesByRaceService } from './api/userNotesByRace.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    AddMetricOverrideService,
    AdminRaceService,
    AdminTrackService,
    CouponService,
    CustomerService,
    DashboardService,
    GenerateTicketService,
    GroupsService,
    HandicappingService,
    HistoryService,
    HorseService,
    HorseNotesService,
    HorseWorkoutService,
    HorsesToWatchService,
    HorsesToWatchResultsService,
    LiveUpdateService,
    MetricOverrideService,
    MetricOverridesService,
    MigrateHorseService,
    MiscService,
    MphMetricUpdateService,
    PastRaceService,
    PlanService,
    ProductService,
    ProductIndexService,
    ProductsForUserQueryService,
    PromoCodeService,
    RaceService,
    RaceNotesService,
    RaceVersionHandlerService,
    RacesService,
    RealTimeService,
    RemoveMetricOverrideService,
    ResultsService,
    ScottyPickService,
    StaticFileService,
    TicketBookService,
    TrackService,
    TwitterService,
    UserService,
    UserNotesByRaceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
