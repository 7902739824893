/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CurrentPick456PoolResponse } from '../model/currentPick456PoolResponse';
import { DashboardCarryoverDTO } from '../model/dashboardCarryoverDTO';
import { DashboardDataDTO } from '../model/dashboardDataDTO';
import { DashboardHotJockeyDTO } from '../model/dashboardHotJockeyDTO';
import { DashboardHotTrainerDTO } from '../model/dashboardHotTrainerDTO';
import { DashboardTopChalkDTO } from '../model/dashboardTopChalkDTO';
import { DashboardTopGsrPlayDto } from '../model/dashboardTopGsrPlayDto';
import { DashboardTopRaceDTO } from '../model/dashboardTopRaceDTO';
import { DashboardUpcomingPickDTO } from '../model/dashboardUpcomingPickDTO';
import { HotTrackDto } from '../model/hotTrackDto';
import { TopSquarePlay } from '../model/topSquarePlay';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class DashboardService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param StartedAt 
     * @param EndedAt 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardCurrentPick456PoolsGet(StartedAt?: string, EndedAt?: string, observe?: 'body', reportProgress?: boolean): Observable<CurrentPick456PoolResponse>;
    public apiDashboardCurrentPick456PoolsGet(StartedAt?: string, EndedAt?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentPick456PoolResponse>>;
    public apiDashboardCurrentPick456PoolsGet(StartedAt?: string, EndedAt?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentPick456PoolResponse>>;
    public apiDashboardCurrentPick456PoolsGet(StartedAt?: string, EndedAt?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (StartedAt !== undefined && StartedAt !== null) {
            queryParameters = queryParameters.set('StartedAt', <any>StartedAt);
        }
        if (EndedAt !== undefined && EndedAt !== null) {
            queryParameters = queryParameters.set('EndedAt', <any>EndedAt);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CurrentPick456PoolResponse>('get',`${this.basePath}/api/dashboard/currentPick456Pools`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardHotJockeysGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardHotJockeyDTO>>;
    public apiDashboardHotJockeysGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardHotJockeyDTO>>>;
    public apiDashboardHotJockeysGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardHotJockeyDTO>>>;
    public apiDashboardHotJockeysGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardHotJockeyDTO>>('get',`${this.basePath}/api/dashboard/HotJockeys`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardHotTracksServiceGet(observe?: 'body', reportProgress?: boolean): Observable<Array<HotTrackDto>>;
    public apiDashboardHotTracksServiceGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HotTrackDto>>>;
    public apiDashboardHotTracksServiceGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HotTrackDto>>>;
    public apiDashboardHotTracksServiceGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HotTrackDto>>('get',`${this.basePath}/api/dashboard/HotTracksService`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardHotTrainersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardHotTrainerDTO>>;
    public apiDashboardHotTrainersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardHotTrainerDTO>>>;
    public apiDashboardHotTrainersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardHotTrainerDTO>>>;
    public apiDashboardHotTrainersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardHotTrainerDTO>>('get',`${this.basePath}/api/dashboard/HotTrainers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardLoadAllGet(observe?: 'body', reportProgress?: boolean): Observable<DashboardDataDTO>;
    public apiDashboardLoadAllGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardDataDTO>>;
    public apiDashboardLoadAllGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardDataDTO>>;
    public apiDashboardLoadAllGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardDataDTO>('get',`${this.basePath}/api/dashboard/loadAll`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardPricePlaysGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardTopChalkDTO>>;
    public apiDashboardPricePlaysGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardTopChalkDTO>>>;
    public apiDashboardPricePlaysGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardTopChalkDTO>>>;
    public apiDashboardPricePlaysGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardTopChalkDTO>>('get',`${this.basePath}/api/dashboard/PricePlays`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardTopGsrPlaysGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardTopGsrPlayDto>>;
    public apiDashboardTopGsrPlaysGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardTopGsrPlayDto>>>;
    public apiDashboardTopGsrPlaysGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardTopGsrPlayDto>>>;
    public apiDashboardTopGsrPlaysGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardTopGsrPlayDto>>('get',`${this.basePath}/api/dashboard/TopGsrPlays`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardTopRacesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardTopRaceDTO>>;
    public apiDashboardTopRacesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardTopRaceDTO>>>;
    public apiDashboardTopRacesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardTopRaceDTO>>>;
    public apiDashboardTopRacesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardTopRaceDTO>>('get',`${this.basePath}/api/dashboard/TopRaces`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardTopSquarePlaysGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TopSquarePlay>>;
    public apiDashboardTopSquarePlaysGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TopSquarePlay>>>;
    public apiDashboardTopSquarePlaysGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TopSquarePlay>>>;
    public apiDashboardTopSquarePlaysGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TopSquarePlay>>('get',`${this.basePath}/api/dashboard/TopSquarePlays`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardUpcomingCarryoversGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardCarryoverDTO>>;
    public apiDashboardUpcomingCarryoversGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardCarryoverDTO>>>;
    public apiDashboardUpcomingCarryoversGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardCarryoverDTO>>>;
    public apiDashboardUpcomingCarryoversGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardCarryoverDTO>>('get',`${this.basePath}/api/dashboard/UpcomingCarryovers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardUpcomingPicksGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardUpcomingPickDTO>>;
    public apiDashboardUpcomingPicksGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardUpcomingPickDTO>>>;
    public apiDashboardUpcomingPicksGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardUpcomingPickDTO>>>;
    public apiDashboardUpcomingPicksGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardUpcomingPickDTO>>('get',`${this.basePath}/api/dashboard/UpcomingPicks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDashboardUpcommingChalksGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardTopChalkDTO>>;
    public apiDashboardUpcommingChalksGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardTopChalkDTO>>>;
    public apiDashboardUpcommingChalksGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardTopChalkDTO>>>;
    public apiDashboardUpcommingChalksGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardTopChalkDTO>>('get',`${this.basePath}/api/dashboard/UpcommingChalks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
