
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LiveOddsTuple } from '../../models/public-live-odds-tuple.model';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import {
  ContestantScratchedDto,
  PoolsDto, ProbablesDto,
  PublicCompactRace,
  RaceStatusDto,
  WillpayDto1
} from '@swagger-codegen/*';
import { FirebaseLiveUpdateService } from './firebase-live-update.service';
import { PollingLiveUpdateService } from './polling-live-update.service';

@TakeUntilDestroy
@Injectable({
  providedIn: 'root',
})
export class LiveUpdateService {
  public postTimeUpdates = new EventEmitter<PostTimeUpdate>();
  public raceStatusUpdates = new EventEmitter<RaceStatusUpdate>();
  componentDestroy: () => Observable<boolean>;

  constructor(
    private readonly firebaseLiveService: FirebaseLiveUpdateService,
    private readonly pollLiveService: PollingLiveUpdateService
  ) {
    this.raceStatusUpdates = this.firebaseLiveService.raceStatusUpdates;
    this.postTimeUpdates = this.firebaseLiveService.postTimeUpdates;
  }
  title = new BehaviorSubject('Equinedge');

  public setTitle(title: string): void {
    this.title.next(title);
  }
  public updateAllRaces(): Observable<{ AllRaces: number }> {
    return this.firebaseLiveService.updateAllRaces();
  }

  public updateUser(userId: number): Observable<{ method: string; changedTime }[]> {
    return this.firebaseLiveService.updateUser(userId);
  }

  public getRaceStatus(raceId: number): Observable<RaceStatusDto> {
    return this.pollLiveService.getRaceStatus(raceId);
  }

  public getRaceScratches(raceId: number): Observable<{
    [key: number]: {
      BettingNumber: number;
      IsScratched: boolean;
      RaceId: number;
      updateAt: number;
    };
  }> {
    return this.firebaseLiveService.getRaceScratches(raceId);
  }

  public subscribeToRaceLiveOdds(raceId: number): Observable<LiveOddsTuple> {
    // return this.pollLiveService.subscribeToRaceLiveOdds(raceId);
    return this.firebaseLiveService.subscribeToRaceLiveOdds(raceId);
  }

  public subscribeToRaceScratches(raceId: number): Observable<ContestantScratchedDto> {
    return this.pollLiveService.subscribeToRaceScratches(raceId);
  }

  public subscribeToUpcomingRaces(): Observable<PublicCompactRace[]> {
    return this.pollLiveService.subscribeToUpcomingRaces();
  }

  public subscribeToWillpays(raceId: number): Observable<WillpayDto1[]> {
    return this.pollLiveService.subscribeToWillpays(raceId);
  }

  public subscribeToProbables(raceId: number): Observable<ProbablesDto> {
    return this.pollLiveService.subscribeToProbables(raceId);
  }

  public subscribeToPools(raceId: number): Observable<PoolsDto> {
    return this.pollLiveService.subscribeToPools(raceId);
  }

}

export interface PostTimeUpdate {
  raceId: number;
  postTime: number;
  updatedAt: number;
}

export interface RaceStatusUpdate {
  raceId: number;
  raceStatus: string;
}

