import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizationDto } from '@swagger-codegen/*';
import { DataCache } from '../../services/data-cache.service';
import { Router } from '@angular/router';
import { AppSandboxService } from '@services/sandbox.service';
import { Observable } from 'rxjs';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '@services/toast.service';

@TakeUntilDestroy
@Component({
  selector: 'app-modal-sub-upsell',
  templateUrl: './modal-sub-upsell.component.html',
  styleUrls: ['./modal-sub-upsell.component.scss'],
})
export class ModalSubUpsellComponent implements OnInit {
  @Input()
  reason:
    | 'date'
    | 'morning-line'
    | 'trainers-edge'
    | 'track'
    | 'race'
    | 'handiview'
    | 'handiview-plus'
    | 'notes'
    | 'pre-race-sor'
    | 'ticket-book'
    | 'gsr'
    | 'dashboard' = 'date';
  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();
  componentDestroy: () => Observable<boolean>;
  currentSub: AuthorizationDto = null;
  mobile$: Observable<boolean> = this.sb.mobile$;
  private modalElementId = 'custom-modal-container';

  constructor(
    private sb: AppSandboxService,
    public dataCache: DataCache,
    private router: Router,
    private toast: ToastService
  ) {
    const modal = document.getElementById(this.modalElementId);
    this.mobile$.pipe(takeUntil(this.componentDestroy())).subscribe((result) => {
      if (!result) {
        modal.style.marginLeft = '-660px'
      } else {
        modal.style.marginLeft = '0'
      }
    })
  }

  ngOnInit(): void {
    this.dataCache
      .getUserSubscriptionModel(true)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((response) => {
        this.currentSub = response.currentDaySubscription;
      });
  }

  getFreeRaceName(): string {
    return this.currentSub?.availableTracks?.length > 0
      ? `Today's Free Races are at ${this.currentSub?.availableTracks[0]} ${this.currentSub?.availableRaces?.join(
        ', '
      )}`
      : ``;
  }

  routeTo(url: string): void {
    this.closeModal();
    if (url) {
      this.router.navigate([url]).then(() => {
        this.closeModal();
      });
    } else {
      this.toast.error('Sorry, first race url is incorrect');
      this.closeModal();
    }
  }

  closeModal(): void {
    this.closeModal$.emit(true);
  }
}
