import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { environment } from '../../../../../environments/environment';
import { LoginService } from '@services/login.service';
import {RealTimeService} from '@swagger-codegen/*';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import {LiveOddsTuple, LiveOddsXform} from "../../models/public-live-odds-tuple.model";

@TakeUntilDestroy
@Injectable({
  providedIn: 'root',
})
export class FirebaseLiveUpdateService {
  public postTimeUpdates = new EventEmitter<PostTimeUpdate>();
  public raceStatusUpdates = new EventEmitter<RaceStatusUpdate>();
  componentDestroy: () => Observable<boolean>;
  currentGroups: string[] = [];
  public isRunning = false;

  constructor(
    private loginService: LoginService,
    private db: AngularFireDatabase,
    private readonly realTimeService: RealTimeService
  ) {
    if (environment.firebase.enable) {
      db.object<{ [key: string]: { postTime: number, updatedAt: number } }>(environment.firebase.queue.postTimeUpdates)
        .valueChanges()
        .pipe(
          filter((x) => !!x),
          takeUntil(this.componentDestroy())
        )
        .subscribe((x) => {
          if (environment.firebase.showConsoleLog) {
            console.log([environment.firebase.queue.postTimeUpdates, x]);
          }
          _.forEach(Object.keys(x), (item) => {
            if (x[item]?.postTime) {
              this.postTimeUpdates.emit({
                raceId: +item,
                postTime: x[item].postTime,
                updatedAt: x[item].updatedAt,
              });
            }
          });
        });

      db.object<{ [key: string]: { raceStatus: string } }>(environment.firebase.queue.raceStatusUpdates)
        .valueChanges()
        .pipe(
          filter((x) => !!x),
          takeUntil(this.componentDestroy())
        )
        .subscribe((x) => {
          if (environment.firebase.showConsoleLog) {
            console.log([environment.firebase.queue.raceStatusUpdates, x]);
          }
          _.forEach(Object.keys(x), (item) => {
            if (x[item]?.raceStatus) {
              this.raceStatusUpdates.emit({
                raceId: +item,
                raceStatus: x[item].raceStatus,
              });
            }
          });
        });
    }
  }

  public updateAllRaces(): Observable<{ AllRaces: number }> {
    return this.db
      .object<{ AllRaces: number }>(environment.firebase.queue.resourceUpdates)
      .valueChanges()
      .pipe(
        filter((x) => !!x),
        tap((x) => {
          if (environment.firebase.showConsoleLog) {
            console.log([environment.firebase.queue.resourceUpdates, x]);
          }
        }),
        takeUntil(this.componentDestroy())
      );
  }

  public updateUser(userId: number): Observable<{ method: string; changedTime }[]> {
    return this.db
      .object<any>(environment.firebase.url.usersMethodsLastUpdate + '/' + userId)
      .valueChanges()
      .pipe(
        filter((x) => !!x),
        tap((x) => {
          if (environment.firebase.showConsoleLog) {
            console.log([environment.firebase.url.usersMethodsLastUpdate, x]);
          }
        }),
        map((x) => {
          const methodChanges: { method: string; changedTime }[] = [];
          _.forEach(Object.keys(x), (y) => {
            if (y) {
              methodChanges.push({
                method: y.replace(/_/g, '/'),
                changedTime: x[y],
              });
            }
          });
          return methodChanges;
        }),
        takeUntil(this.componentDestroy())
      );
  }

  public getRaceStatus(raceId: number): Observable<RaceStatusUpdate> {
    return this.db
      .object<{ raceStatus?: string }>(environment.firebase.queue.raceStatusUpdates + '/' + raceId.toString())
      .valueChanges()
      .pipe(
        filter((x) => !!x),
        map((result) => {
          if (environment.firebase.showConsoleLog) {
            console.log([environment.firebase.queue.raceStatusUpdates, raceId, result]);
          }
          return {
            raceId,
            raceStatus: result?.raceStatus,
          };
        }),
        takeUntil(this.componentDestroy())
      );
  }

  public getRaceScratches(raceId: number): Observable<{
    [key: number]: {
      BettingNumber: number;
      IsScratched: boolean;
      RaceId: number;
      updateAt: number;
    };
  }> {
    return this.db
      .object<any>(environment.firebase.queue.contestantScratches + '/' + raceId.toString())
      .valueChanges()
      .pipe(
        filter(
          (x: {
            [key: number]: {
              BettingNumber: number;
              IsScratched: boolean;
              RaceId: number;
              updateAt: number;
            };
          }) => !!x
        ),
        takeUntil(this.componentDestroy())
      );
  }

  /**
   * Subscribes to live odds for a specific race
   * @param raceId - the id of the race to subscribe to
   * @returns an observable that emits live odds for the specified race
   */
  public subscribeToRaceLiveOdds(raceId: number): Observable<LiveOddsTuple> {
    console.log('liveodds', [environment.firebase.queue.liveOddsUpdates + '/' + raceId.toString()]);
    return this.db
      .object<any>(environment.firebase.queue.liveOddsUpdates + '/' + raceId.toString())
      .valueChanges()
      .pipe(
        filter((x) => x?.bettingNumberOdds?.length > 0),
        map((result) => {
          if (environment.firebase.showConsoleLog) {
            console.log('liveodds', [environment.firebase.queue.liveOddsUpdates, result]);
          }
          // need to check backend on these props
          const normalizeBettingNumbers = result.bettingNumberOdds.map( bn => _.transform(bn, function (result, val, key: string) {
            result[_.lowerFirst(key)] = val;
          }));
          console.log('from server', normalizeBettingNumbers);
          const output = LiveOddsXform.toLiveOddsTuple({
            raceId,
            bettingNumberOdds: normalizeBettingNumbers,
          });
          console.log(output);
          return output;
        }),
        takeUntil(this.componentDestroy())
      );
  }

  /*  public subscribeToRaceLiveOdds(raceId: number): Observable<LiveOddsTuple> {

  }

  public subscribeToRaceScratches(raceId: number): Observable<ContestantScratchedDto> {

  }*/
}

export interface PostTimeUpdate {
  raceId: number;
  postTime: number;
  updatedAt: number;
}

export interface RaceStatusUpdate {
  raceId: number;
  raceStatus: string;
}
