import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PlanSummaryDto, PromoCodeDetailDto } from '@swagger-codegen/*';
import _ from 'lodash';
import moment from 'moment-timezone';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { Observable } from 'rxjs';
import {NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { DataCache } from '../../authenticated-module/services/data-cache.service';
import { take } from 'rxjs/operators';
import { LoginService } from '@services/login.service';
import {ProductCodes} from '../models/product-codes.model';
import $ from 'jquery';
import {ModalService} from "../../authenticated-module/services/modal.service";
import {AppSandboxService} from "@services/sandbox.service";

@TakeUntilDestroy
@Component({
  selector: 'app-plan-passes',
  templateUrl: './plan-passes.component.html',
  styleUrls: ['./plan-passes.component.scss'],
})
export class PlanPassesComponent implements OnInit, AfterViewInit {
  @Input()
  plan: PlanSummaryDto;
  @Input()
  hideButton = false;

  title='Confirm Purchase';
  text = '';
  modalRef: NgbModalRef;

  @Input()
  set promoCode(v: PromoCodeDetailDto) {
    this._promoCode = v;
    if (this._promoCode && this._promoCode.plans.indexOf(this.plan.planCode) !== -1) {
      this.currentPlan = _.filter(this._promoCode.plansSummary, (x) => x.planCode === this.plan.planCode).pop();
    } else {
      this.currentPlan = this.plan;
    }
  }

  @ViewChild('d') datePicker;
  currentName = '';
  selectedDate: NgbDateStruct;
  date: { year: number; month: number; day: number };
  componentDestroy: () => Observable<boolean>;
  currentDate: string = moment().tz('America/Los_Angeles').format('yy/MM/DD');
  currentPlan: PlanSummaryDto;
  _promoCode: PromoCodeDetailDto = null;
  @Output()
  submit: EventEmitter<PlanSummaryDto> = new EventEmitter<PlanSummaryDto>();
  tracksByDateFiltered: string[] = [];
  minDate: NgbDate;
  maxDate: NgbDate;
  mobile$ = this.sb.mobile$;
  disableLink = false;

  constructor(private calendar: NgbCalendar,
              private dataCache: DataCache,
              private loginService: LoginService,
              private modalService: NgbModal,
              public mdlService: ModalService,
              private sb: AppSandboxService) {
  }

  get trackOptionLabel(): string {
    const isoDate = this.currentPlan?.selectedDate.indexOf(" - ")
      ? moment(this.currentPlan?.selectedDate.split(" ")[0]).toISOString()
      : moment(this.currentPlan?.selectedDate).toISOString();
    return `${isoDate.split('T')[0]}T00:00:00Z`;
  }

  selectToday(): void {
    this.selectedDate = this.calendar.getToday();
  }

  get pricePeriod(): string {
    return this.currentPlan?.amount < 500 ? '/mo.' : '/yr.';
  }

  get name(): string {
    return this.getNameFromCode(this.currentPlan?.planCode, this.currentName);
  }

  getNameFromCode(code: string, name: string): string {
    // need to change this for each special plan
    const nameParts = name.split(' ');
    const newName = nameParts.map((part, index) => {
      return index === nameParts.length - 1 ? '<br/>' + part : part;
    }).join(' ');
    return {
      [ProductCodes.specialSingleTrack]: 'Belmont Stakes Single Day Single Track',
      [ProductCodes.specialAllTrack]: 'Belmont Stakes Single Day All Tracks',
      [ProductCodes.specialWeekend]: 'Belmont Stakes Single Weekend',
    }[code] ?? newName;
  }

  isDisabled (date: NgbDateStruct, minDate: NgbDateStruct, maxDate: NgbDateStruct) {
    const inRange = (
        typeof date != "undefined"
        && typeof minDate != "undefined"
        && date.year >= minDate.year
        && date.month >= minDate.month
        && date.day >= minDate.day
        && date.year <= maxDate.year
        && date.month <= maxDate.month
        && date.day <= maxDate.day
      );
    return inRange;
  }

  ngOnInit(): void {
    if (!this.currentPlan) {
      this.currentPlan = this.plan;
    }
    this.currentName = _.chain(this.currentPlan?.name)
      .toUpper()
      .replace('DAY', 'DAY<br/>')
      .replace('BIRD', 'BIRD<br/>')
      .replace('WEEKEND', 'WEEKEND<br/>')
      .value();

    if (this.currentPlan.planCode === '129-USD-Weekly' || this.currentPlan.planCode === ProductCodes.freeSignup) {
      this.currentName = 'Free Signup';
    }
    if (
      this.currentPlan.planCode === 'SDSTPASS'
      || this.currentPlan.planCode === ProductCodes.singleTrack
      || this.currentPlan.planCode === ProductCodes.specialSingleTrack) {
      if (
        !this.currentPlan?.selectedDate &&
        this.currentPlan?.trackOptions &&
        this.currentPlan?.datesAvailable?.length
      ) {
        this.currentPlan.selectedDate = this.currentPlan.datesAvailable[0];
        if (this.currentPlan?.selectedTrack) {
          this.currentPlan.selectedTrack =
            this.currentPlan.trackOptions[this.trackOptionLabel][
              this.currentPlan.trackOptions[this.trackOptionLabel].indexOf(this.currentPlan.selectedTrack)
              ];
        } else {
          this.currentPlan.selectedTrack = this.currentPlan.trackOptions[this.trackOptionLabel][0];
        }
      }
    } else if (this.currentPlan?.datesAvailable?.length && !this.currentPlan.selectedDate) {
      //TODO: remove 1/31/2022
      this.currentPlan.selectedDate = this.currentPlan.datesAvailable[0];
    }

    this.selectToday();
    this.filterTrack();
  }

  filterTrack(): void {
    if (this.currentPlan?.selectedDate && this.currentPlan.trackOptions) {
      const tracks: string[] = [];
      const currentDate = this.currentPlan?.selectedDate.split(' ')[0].replace(/-/g, '/');
      this.loginService.loggedIn$.pipe(take(1)).subscribe((isLogin) => {
        if (isLogin) {
          this.dataCache.allRacesByDay.pipe(take(1)).subscribe((races) => {
            const temp1 = _.filter(races, (x) => {
              return x.date === currentDate;
            });

            _.forEach(this.currentPlan.trackOptions[this.trackOptionLabel], (t) => {
              _.forEach(temp1[0].tracks, (y) => {
                if (y.name === t && !y.isCancelled) {
                  tracks.push(t);
                }
              });
            });
            this.tracksByDateFiltered = tracks;
            let temp = false;
            _.forEach(this.tracksByDateFiltered, (track) => {
              if (track === this.currentPlan.selectedTrack) {
                temp = true;
              }
            });
            if (!temp && this.tracksByDateFiltered.length) {
              this.currentPlan.selectedTrack = this.tracksByDateFiltered[0];
            }
          });
        } else {
          this.tracksByDateFiltered = this.currentPlan.trackOptions[this.trackOptionLabel];
        }
      });
    } else {
      this.tracksByDateFiltered = [];
    }
  }

  checkDate(date: string): boolean {
    return moment(date, 'YYYY-MM-DD').format('yy/MM/DD') === this.currentDate;
  }
  datePickerClose(): void {
    $('.date-picker').css("display","none");
  }

  purchase(content): void {
    switch (this.currentPlan?.planCode) {
      case ProductCodes.singleDay:
      case ProductCodes.singleTrack:
      case ProductCodes.starterPass:
      case ProductCodes.specialSingleTrack:
      case ProductCodes.specialAllTrack:
        $('.date-picker').css("display","flex");
        $(`#dp-${this.currentPlan.planCode}`).css("display","flex");
        $('.date-picker').not(`#dp-${this.currentPlan.planCode}`).css("display","none");
        break;
      case '120-USD-Weekly':
      case ProductCodes.singleWeekend:
        this.text = `You are purchasing ${this.currentPlan.name} on<br/>${moment(new Date(this.selectedDate.year, this.selectedDate.month-1, this.selectedDate.day)).format('M-DD-YYYY')}.<br/>Is this correct?`;
        this.modalRef = this.modalService.open(content);
        break;
      case ProductCodes.earlyBird:
        this.text = `You are purchasing ${this.currentPlan.name} on<br/>${moment(new Date(this.selectedDate.year, this.selectedDate.month-1, this.selectedDate.day)).format('M-DD-YYYY')}.<br/>Is this correct?`;
        this.modalRef = this.modalService.open(content);
        break;
      default:
        this.submit.emit(this.currentPlan);
    }
  }

  purchaseWithDate(content): void {
    this.updateDate();
    $('.date-picker').css("display","none");

    switch(this.currentPlan?.planCode) {
      case ProductCodes.singleTrack:
      case ProductCodes.specialSingleTrack:
        this.text = `You are purchasing ${this.currentPlan.name} on<br/>${moment(new Date(this.selectedDate.year, this.selectedDate.month-1, this.selectedDate.day)).format('M-DD-YYYY')} for ${this.currentPlan?.selectedTrack}.<br/>Is this correct?`;
        this.modalRef = this.modalService.open(content);
        break;
      case '120-USD-Weekly':
      case ProductCodes.singleWeekend:
        this.text = `You are purchasing ${this.currentPlan.name} on<br/>${moment(new Date(this.selectedDate.year, this.selectedDate.month-1, this.selectedDate.day)).format('M-DD-YYYY')}.<br/>Is this correct?`;
        this.modalRef = this.modalService.open(content);
        break;
      case ProductCodes.singleDay:
      case ProductCodes.specialAllTrack:
        this.text = `You are purchasing ${this.currentPlan.name} on<br/>${moment(new Date(this.selectedDate.year, this.selectedDate.month-1, this.selectedDate.day)).format('M-DD-YYYY')}.<br/>Is this correct?`;
        this.modalRef = this.modalService.open(content);
        break;
      case 'StarterPlan-USD-Daily':
      case ProductCodes.starterPass:
        this.text = `You are purchasing ${this.currentPlan.name} on<br/>${moment(new Date(this.selectedDate.year, this.selectedDate.month-1, this.selectedDate.day)).format('M-DD-YYYY')}.<br/>Is this correct?`;
        this.modalRef = this.modalService.open(content);
        break;
      default:
        this.submit.emit(this.currentPlan);
    }
  }

  submitModal(result: boolean) {
    if (result) {
      this.submit.emit(this.currentPlan);
    }
    this.modalRef.close();
  }

  get disableButton() {
    return (
      (this._promoCode && this._promoCode.plans.indexOf(this.plan.planCode) === -1) ||
      this.plan.action === 'None' ||
      this.plan.action === 'Active'
    );
  }

  ngAfterViewInit(): void {
    if (this.datePicker) {
      this.datePicker.minDate = this.calendar.getToday();
      if (this.currentPlan?.datesAvailable?.length > 1) {
        const minDate = _.split(_.split(this.currentPlan?.datesAvailable[0], ' - ')[0], '-');
        const maxDate = _.split(
          _.split(this.currentPlan?.datesAvailable[this.currentPlan?.datesAvailable?.length - 1], ' - ')[0],
          '-'
        );
        if (minDate?.length === 3) {
          this.datePicker.minDate = {
            year: +minDate[0],
            month: +minDate[1],
            day: +minDate[2],
          };
        }
        if (maxDate?.length === 3) {
          this.datePicker.maxDate = {
            year: +maxDate[0],
            month: +maxDate[1],
            day: +maxDate[2],
          };
        }
      } else {
        if (this.currentPlan?.datesAvailable[0]?.length < 19) {
          const dateAll = _.split(_.split(this.currentPlan?.datesAvailable[0], ' - ')[0], '-');
          if (dateAll?.length === 3) {
            this.datePicker.minDate = {
              year: +dateAll[0],
              month: +dateAll[1],
              day: +dateAll[2],
            };
            this.datePicker.maxDate = {
              year: +dateAll[0],
              month: +dateAll[1],
              day: +dateAll[2],
            };
          }
        }
      }
      this.minDate = this.datePicker.minDate;
      this.maxDate = this.datePicker.maxDate;
    }
  }

  updateDate(): void {
    for (let i = 0; i < this.currentPlan.datesAvailable.length; i++) {
      const temp = _.split(_.split(this.currentPlan?.datesAvailable[i], ' - ')[0], '-');
      if (
        temp?.length === 3 &&
        this.selectedDate.day === +temp[2] &&
        this.selectedDate.month === +temp[1] &&
        this.selectedDate.year === +temp[0]
      ) {
        this.currentPlan.selectedDate = this.currentPlan.datesAvailable[i];
        if (this.currentPlan?.planCode === ProductCodes.singleTrack || this.currentPlan?.planCode === ProductCodes.specialSingleTrack) {
          this.filterTrack();
        }
        break;
      }
    }
  }

  public readonly ProductCodes = ProductCodes;
}
