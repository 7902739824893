import { Component, OnInit } from '@angular/core';
import { AppSandboxService } from '@services/sandbox.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  mobile$: Observable<boolean> = this.sb.mobile$;

  constructor(private sb: AppSandboxService) {}

  ngOnInit(): void {}
}
