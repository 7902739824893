import { BehaviorSubject, Subject } from 'rxjs';

const blockingMessage: Subject<string> = new BehaviorSubject(null);
let blockUiIsWorking = false;
let messageStack = [];

function normalizeMessage(message: string): string {
  if (message && message.endsWith('.')) {
    return message;
  }

  if (!message) {
    message = 'Loading';
  }

  if (message.endsWith('.')) {
    return message;
  }

  return message + '...';
}

export let BlockUiService = {
  start: (message: string = null) => {
    blockingMessage.next(normalizeMessage(message));
    messageStack.push(normalizeMessage(message));
    blockUiIsWorking = true;
  },
  stop: () => {
    messageStack.pop();
    if (messageStack.length === 0) {
      blockingMessage.next(null);
      blockUiIsWorking = false;
    }
  },
  stopAll: () => {
    messageStack = [];
    blockingMessage.next(null);
    blockUiIsWorking = false;
  },
  getBlockingMessageSubject: () => {
    return blockingMessage;
  },
  isWorking: () => {
    return blockUiIsWorking;
  },
};
