import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSandboxService } from '@services/sandbox.service';
import {ModalService} from "../../modules/authenticated-module/services/modal.service";

@Component({
  selector: 'app-signup-plans',
  templateUrl: './signup-plans.component.html',
  styleUrls: ['./signup-plans.component.scss'],
})
export class SignupPlansComponent implements OnInit {
  mobile$: Observable<boolean> = this.sb.mobile$;

  constructor(private sb: AppSandboxService, private modalService: ModalService) {}

  ngOnInit(): void {}
  removeModal(): void {
    this.modalService.destroy();
  }
}
