import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/internal/operators';
import { ToastService } from '@services/toast.service';
import { async } from 'rxjs/internal/scheduler/async';
import { BlockUiService } from '@services/block-ui.service';
import { ROUTES } from './routes';
import _ from 'lodash';
import { HorseStats, PublicCompactRace, TrainerChange } from '@swagger-codegen/*';

@Injectable()
export class JtHttp {
  private _cache: {
    [url: string]: {
      response?: any;
      pending?: boolean;
      observers?: any[];
      time?: number;
    };
  } = {};

  cacheSettings: { cacheSec: number; urlContain: string; disable: boolean }[] = [
    {
      cacheSec: 600,
      urlContain: 'api/product',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: 'api/Plan/UiPlans',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: 'api/Plan/UiPlansByUser',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: 'api/Plan/CurrentTrackNames',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: 'api/Horse/GsrPlus?',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: 'api/Horse/GsrPlusByRace?',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: ' api/Horse/siredampps?',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: 'api/Horse/LifetimeStatsBySurfaceDistance?',
      disable: false,
    },
    {
      cacheSec: 60,
      urlContain: '/api/Account/CurrentSubscriptionStatus',
      disable: false,
    },
    {
      cacheSec: 300,
      urlContain: '/api/Account/MyAccount',
      disable: false,
    },
    {
      cacheSec: 0,
      urlContain: '/api/Races/',
      disable: true,
    },
    {
      cacheSec: 3600,
      urlContain: 'api/dashboard/loadAll',
      disable: false,
    },
    {
      cacheSec: 600,
      urlContain: '/api/Races/All',
      disable: true,
    },
    {
      cacheSec: 60,
      urlContain: '/api/horses-to-watch-results',
      disable: false,
    },
    {
      cacheSec: 60,
      urlContain: '/api/horses-to-watch',
      disable: true,
    },
    {
      cacheSec: 1,
      urlContain: 'api/scottypick',
      disable: true,
    },
    {
      cacheSec: 1000000,
      urlContain: 'api/Account/AvatarFileBase64',
      disable: false,
    },
  ];
  private currentVersion = '{{POST_BUILD_ENTERS_VERSION_HERE}}';

  constructor(private http: HttpClient, private toast: ToastService, private router: Router) {
  }

  public get<T>(url: string, options: Options): Observable<T> {
    options = this.setClientVersionHeader(options);
    const urlWithStringQuery = !options?.params ? url : url + '?' + options?.params?.toString();
    const o = new ReplaySubject<T>(1);

    if (this._cache[urlWithStringQuery]?.response) {
      const temp = _.filter(this.cacheSettings, (item) => urlWithStringQuery.indexOf(item.urlContain) > -1).pop();
      if (!temp || !temp.disable) {
        o.next(this._cache[urlWithStringQuery]?.response);
        // if reportProgress == true, must update cache
        if (options && !options?.reportProgress) {
          // not need do request if it is cache time
          if (temp && this._cache[urlWithStringQuery].time + temp.cacheSec * 1000 > +new Date()) {
            o.complete();
            return o.asObservable();
          }
        }
      }
    }
    let refresh = null;
    Observable.create((observer) => {
      refresh = () => {
        observer.next();
      };
    })
      .throttleTime(3000, async, {
        leading: true,
        trailing: true,
      })
      .subscribe(() => {
        if (this._cache[urlWithStringQuery] && this._cache[urlWithStringQuery].pending) {
          if (this._cache[urlWithStringQuery].observers) {
            this._cache[urlWithStringQuery].observers.push(o);
          } else {
            this._cache[urlWithStringQuery].observers = [o];
          }
          // not need request if it is wait response
          return;
        }
        if (this._cache[urlWithStringQuery] && this._cache[urlWithStringQuery].response) {
          this._cache[urlWithStringQuery].pending = true;
        } else {
          this._cache[urlWithStringQuery] = {
            response: null,
            pending: true,
          };
        }
        return this.http
          .get(url, options)
          .pipe(map((r) => this.processResponse<T>(r, urlWithStringQuery)))
          .subscribe((response) => {
            this._cache[urlWithStringQuery] = {
              observers: null,
              response,
              pending: false,
              time: +new Date(),
            };
            o.next(response);
            o.complete();
          });
      });
    refresh();
    return o.asObservable();
  }

  public post<T>(url: string, body: any | null, options: any): Observable<T> {
    options = this.setClientVersionHeader(options);
    return this.http.post(url, body, options).pipe(map((r) => this.processResponse(r)));
  }

  public put<T>(url: string, body: any | null, options: any): Observable<T> {
    options = this.setClientVersionHeader(options);
    return this.http.put(url, body, options).pipe(map((r) => this.processResponse(r)));
  }

  public delete<T>(url: string, options: Options): Observable<T> {
    options = this.setClientVersionHeader(options);
    return this.http.delete(url, options).pipe(map((r) => this.processResponse(r)));
  }

  public request<T>(cmd: string, url: string, options: Options): Observable<T> {
    switch (cmd) {
      case 'get':
        return this.get<T>(url, options);
      case 'post':
        return this.post<T>(url, options?.body, options);
      case 'put':
        return this.put<T>(url, options?.body, options);
      case 'delete':
        return this.delete<T>(url, options);
      default:
        return this.get<T>(url, options);
    }
  }

  setClientVersionHeader(options: any): any {
    if (options && options.headers) {
      options.headers = options.headers.set('CLIENT-VERSION', this.currentVersion);
    }
    return options;
  }

  private processResponse<T>(response: any, urlWithStringQuery?: string): T {
    if (response && response.body) {
      return response.body;
    }
    const resp = response as HttpResponse<T>;
    if (resp && resp.isSuccess) {
      // return response for all the same waiting requests
      if (urlWithStringQuery && this._cache[urlWithStringQuery] && this._cache[urlWithStringQuery]?.observers) {
        _.forEach(this._cache[urlWithStringQuery].observers, (x) => {
          x.next(resp.successData);
          x.complete();
        });
      }
      return resp.successData;
    } else {
      if (urlWithStringQuery && this._cache[urlWithStringQuery] && this._cache[urlWithStringQuery]?.observers) {
        // finish all waiting requests if response has error
        _.forEach(this._cache[urlWithStringQuery].observers, (x) => {
          x.complete();
        });
      }
    }
    if (resp && (resp.errorCode === 'Unauthenticated' || resp.errorCode === 'Unauthorized')) {
      const errorCustom = localStorage['error'];
      if (!errorCustom || errorCustom !== resp.errorHumanReadableMessage) {
        this.toast.warning(resp.errorHumanReadableMessage);
        localStorage.clear();
        sessionStorage.clear();
        localStorage['error'] = resp.errorHumanReadableMessage;
        // this.redirectToSignUp();
        this.redirectToLogin();
        return;
      } else if (errorCustom === resp.errorHumanReadableMessage) {
        // do not duplicate error
        return;
      }
    }
    if (resp && resp.errorCode === 'SubscriptionPastDue:NeedsPaymentMethodUpdate') {
      this.router.navigate(['/', ROUTES.SubscriptionPastDue]).then();
      BlockUiService.stopAll();
      return;
    }
    // TODO: redirect the following error code to the subscribe page when it's done
    if (resp && resp.errorCode === 'SubscriptionCanceled:NeedsNewSubscription') {
      this.toast.warning(resp.errorHumanReadableMessage);
      this.router.navigate(['/', ROUTES.Subscription]).then((status) => {
        if (status === false) {
          this.redirectToSignUp();
        }
      });
      BlockUiService.stopAll();
      return;
    }
    if(resp) {
      this.toast.error(resp?.errorHumanReadableMessage);
      BlockUiService.stopAll();
    }
  }

  private redirectToLogin(): void {
    this.router.navigate(['/', ROUTES.Login]).then();
    BlockUiService.stopAll();
    return;
  }

  private redirectToSignUp(): void {
    this.router.navigate(['/', ROUTES.SignUp]).then();
    BlockUiService.stopAll();
    return;
  }

  public clearCache(): void {
    this._cache = {};
    try {
      if ('serviceWorker' in navigator) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  public checkCacheUrlValidation(url: string, time: number): void {
    const cacheUrl = _.filter(Object.keys(this._cache), (x) => x.indexOf(url) !== -1);
    if (cacheUrl?.length === 1 && this._cache[cacheUrl[0]].time < time) {
      // cache is not valid. Need delete response from cache
      delete this._cache[cacheUrl[0]];
    }
  }
}

export class HttpResponse<T> {
  errorCode?: string;
  errorHumanReadableMessage?: string;
  isSuccess: boolean;
  successData: T;
}

interface Options {
  body?: any;
  params?: any;
  withCredentials?: any;
  headers?: any;
  observe?: any;
  reportProgress?: boolean;
}

declare module './swagger-codegen/model/availableWager' {
  interface AvailableWager {
    name?: string;
  }
}

declare module './swagger-codegen/model/contestantDetails' {
  interface ContestantDetails {
    isMaxWinPercentage?: boolean;
    isNotesOpen?: boolean;
    isStableOpen?: boolean;
    avgSor?: number;
    currentDistStates?: HorseStats;
    currentDirtStates?: HorseStats;
    currentTurfStates?: HorseStats;
    currentSynStates?: HorseStats;
    current6monthDistStates?: HorseStats;
    current6monthDirtStates?: HorseStats;
    current6monthTurfStates?: HorseStats;
    current6monthSynStates?: HorseStats;
  }
}

declare module './swagger-codegen/model/publicTicket' {
  interface PublicTicket {
    compactRace?: PublicCompactRace;
  }
}

declare module './swagger-codegen/model/contestantChanges' {
  interface ContestantChanges {
    sortedChanges?: TrainerChange[];
  }
}

declare module './swagger-codegen/model/simplePastPerformance' {
  interface SimplePastPerformance {
    raceTime?: number;
    raceTimeFormatted: { minutes: number; seconds: string; superscript: any };
  }
}

declare module './swagger-codegen/model/publicCompactRace' {
  interface PublicCompactRace {
    hasResult?: boolean;
  }
}

declare module './swagger-codegen/model/planSummaryDto' {
  interface PlanSummaryDto {
    selectedDate?: string;
    selectedTrack?: string;
  }
}

declare module './swagger-codegen/model/horseByTrainerDto' {
  interface HorseByTrainerDto {
    selected?: boolean;
    detailsSelected?: boolean;
  }
}

export interface UserDevice {
  clientVersionHeader: string;
  deviceId: any;
  screenOrientation: any;
  viewportSize: any;
  deviceInfo: any;
}

export interface ReplayRace {
  race: string;
  sdpan: Hdho;
  sdho: Hdho;
  hdpan: Hdho;
  hdho: Hdho;
}

export interface Hdho {
  filename: string;
  flash: string;
  mobile: string;
  http: string;
}

// help cache  example
// to use the cache you need to set the last parameter = true;
// racesService.apiResultsByRaceIdGet(race.raceId, null, true)
//         .subscribe(raceDetails => {
//         });

// help How get full request example
// need to add 'response'
// racesService.apiResultsByRaceIdGet(race.raceId, 'response')
//         .subscribe(result => {
//             const temp= result as HttpResponse<RaceDetails>;
//         });
