<div [ngClass]="{ 'mobile-version overflow responsive-width': mobile$ | async }" class="account-section">
  <div *ngIf="!(mobile$ | async)" class="close-container">
    <button (click)="cancel()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="m-0">
    <h5 class="font-b py-3 text-center">Change Password</h5>
    <form (ngSubmit)="setNewPassword()" *ngIf="passwordForm" [formGroup]="passwordForm">
      <div class="form-group row mx-0">
        <label class="col col-form-label" for="currentPassword">Current Password</label>
        <div
          [ngClass]="{
            'col-12': mobile$ | async,
            'col-8': !(mobile$ | async)
          }"
        >
          <input
            [ngClass]="{
              'is-valid': isValid('currentPassword'),
              'is-invalid': isInValid('currentPassword')
            }"
            class="form-control"
            formControlName="currentPassword"
            id="currentPassword"
            name="currentPassword"
            placeholder="Current Password"
            type="password"
          />
          <div class="valid-feedback">Looks good!</div>
          <div class="invalid-feedback">
            <ng-container *ngIf="passwordForm.controls['currentPassword']?.errors?.customValidator">
              {{ passwordForm.controls['currentPassword']?.errors['customValidator'] }}
            </ng-container>
            <ng-container *ngIf="!passwordForm.controls['currentPassword']?.errors?.customValidator">
              The password must be at least 8 characters long.
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-group row mx-0">
        <label class="col col-form-label" for="newPassword">New Password</label>
        <div
          [ngClass]="{
            'col-12': mobile$ | async,
            'col-8': !(mobile$ | async)
          }"
        >
          <input
            [ngClass]="{
              'is-valid': isValid('newPassword'),
              'is-invalid': isInValid('newPassword')
            }"
            class="form-control"
            formControlName="newPassword"
            id="newPassword"
            name="newPassword"
            placeholder="New Password"
            type="password"
          />
          <div class="valid-feedback">Looks good!</div>
          <div class="invalid-feedback">
            <ng-container *ngIf="passwordForm.controls['newPassword']?.errors?.customValidator">
              {{ passwordForm.controls['newPassword']?.errors['customValidator'] }}
            </ng-container>
            <ng-container *ngIf="!passwordForm.controls['newPassword']?.errors?.customValidator">
              The password must be at least 8 characters long.
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-group row mx-0">
        <label class="col col-form-label" for="confirmNewPassword">Confirm New Password</label>
        <div
          [ngClass]="{
            'col-12': mobile$ | async,
            'col-8': !(mobile$ | async)
          }"
        >
          <input
            [ngClass]="{
              'is-valid': isValid('confirmNewPassword'),
              'is-invalid': isInValid('confirmNewPassword')
            }"
            class="form-control"
            formControlName="confirmNewPassword"
            id="confirmNewPassword"
            name="confirmNewPassword"
            placeholder="Confirm New Password"
            type="password"
          />
          <div class="valid-feedback">Looks good!</div>
          <div class="invalid-feedback">The confirm new password needs to be the same as the new password.</div>
        </div>
      </div>
      <div class="form-group row mx-0">
        <div class="col pl-2">
          <div *ngIf="!(mobile$ | async)" (click)="cancel()" class="btn btn-link">Cancel</div>
          <div *ngIf="mobile$ | async" (click)="cancell()" class="btn btn-link">Cancel</div>
        </div>
        <div
          [ngClass]="{
            'col-12': mobile$ | async,
            'col-8': !(mobile$ | async)
          }"
        >
          <input class="btn btn-primary btn-block" type="submit" value="Change" />
        </div>
      </div>
    </form>
  </div>
</div>
