import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService, ResetPasswordCommand } from '@swagger-codegen/*';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import _ from 'lodash';
import zxcvbn from 'zxcvbn';
import { ToastService } from '@services/toast.service';
import { LoginService } from '@services/login.service';
import { BlockUiService } from '@services/block-ui.service';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { finalize, takeUntil } from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  resetPasswordArg: ResetPasswordCommand = {};
  running = false;
  componentDestroy: () => Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public accountService: AccountService,
    public toastService: ToastService,
    public loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.componentDestroy())).subscribe((queryparams) => {
      if (queryparams && queryparams.token) {
        this.resetPasswordArg.token = queryparams.token;
        this.resetPasswordForm = new FormGroup({
          password: new FormControl(null, [Validators.required, Validators.minLength(8), this.customValidator()]),
        });
      } else {
        this.router.navigate(['/login']).then();
      }
    });
  }

  isValid(controlName: string): boolean {
    return (
      this.resetPasswordForm.controls[controlName].valid &&
      (this.resetPasswordForm.controls[controlName].dirty || this.resetPasswordForm.controls[controlName].touched)
    );
  }

  isInValid(controlName: string): boolean {
    return (
      this.resetPasswordForm.controls[controlName].invalid &&
      (this.resetPasswordForm.controls[controlName].dirty || this.resetPasswordForm.controls[controlName].touched)
    );
  }

  validateForm(): void {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.controls.password.markAsTouched();
    } else {
      if (!this.running) {
        this.running = true;
        BlockUiService.start();
        this.resetPasswordArg.password = this.resetPasswordForm.value.password;
        this.accountService
          .apiAccountResetPasswordPost(this.resetPasswordArg, 'response')
          .pipe(
            finalize(() => {
              BlockUiService.stop();
              this.running = false;
            }),
            takeUntil(this.componentDestroy())
          )
          .subscribe((result) => {
            if (result && result.isSuccess) {
              if (!result.successData.remainingLoginMessage) {
                result.successData.remainingLoginMessage = 'Your password has been reset.';
              }
              this.loginService.checkResponse(result);
            } else {
              this.toastService.error(result.errorHumanReadableMessage);
            }
          });
      }
    }
  }

  customValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const test = zxcvbn(control.value) as {
          feedback: { warning: string; suggestions: string[] };
          score: number;
        };
        if (test.feedback.warning) {
          let error = '';
          error += test.feedback.warning + '.';
          _.forEach(test.feedback.suggestions, (result) => {
            error += ' ' + result;
          });
          return { customValidator: error };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
}
