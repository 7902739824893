/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CheckoutNewCustomerErrorCommand { 
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    locale?: string;
    city?: string;
    stateCode?: string;
    countryCode?: string;
    zipCode?: string;
    deviceId: string;
    affiliateToken?: string;
    accountType?: CheckoutNewCustomerErrorCommand.AccountTypeEnum;
    emailVerificationCode?: number;
    subscriptionAction?: string;
    planCode: string;
    trackName?: string;
    startDate?: string;
    promoCode?: string;
}
export namespace CheckoutNewCustomerErrorCommand {
    export type AccountTypeEnum = 'PAID' | 'FREE';
    export const AccountTypeEnum = {
        PAID: 'PAID' as AccountTypeEnum,
        FREE: 'FREE' as AccountTypeEnum
    };
}