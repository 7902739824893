import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppSandboxService } from '@services/sandbox.service';
import { AccountService } from '@swagger-codegen/*';

@Component({
  selector: 'app-modal-handiview-plus',
  templateUrl: './modal-handiview-plus.component.html',
  styleUrls: ['./modal-handiview-plus.component.scss'],
})
export class ModalHandiviewPlusComponent implements OnInit, OnDestroy {
  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();
  mobile$ = this.sb.mobile$;

  constructor(private sb: AppSandboxService, public accountService: AccountService) {}

  ngOnDestroy(): void {
    this.closeModal$.emit(true);
  }

  ngOnInit(): void {}

  close(): void {
    this.closeModal$.emit(true);
  }
}
