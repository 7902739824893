/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HorseWorkout } from '../model/horseWorkout';
import { HorseWorkoutData } from '../model/horseWorkoutData';
import { WorkoutByHorseResponse } from '../model/workoutByHorseResponse';
import { WorkoutResponse } from '../model/workoutResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class HorseWorkoutService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param horseName 
     * @param onlyWorkoutsWithVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseWorkoutGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<HorseWorkout>>;
    public apiHorseWorkoutGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorseWorkout>>>;
    public apiHorseWorkoutGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorseWorkout>>>;
    public apiHorseWorkoutGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (horseName !== undefined && horseName !== null) {
            queryParameters = queryParameters.set('horseName', <any>horseName);
        }
        if (onlyWorkoutsWithVideo !== undefined && onlyWorkoutsWithVideo !== null) {
            queryParameters = queryParameters.set('onlyWorkoutsWithVideo', <any>onlyWorkoutsWithVideo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HorseWorkout>>('get',`${this.basePath}/api/horse-workout`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workoutDate 
     * @param track 
     * @param race 
     * @param onlyWorkoutsWithVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseWorkoutListDateGet(workoutDate?: string, track?: string, race?: number, onlyWorkoutsWithVideo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<HorseWorkoutData>;
    public apiHorseWorkoutListDateGet(workoutDate?: string, track?: string, race?: number, onlyWorkoutsWithVideo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorseWorkoutData>>;
    public apiHorseWorkoutListDateGet(workoutDate?: string, track?: string, race?: number, onlyWorkoutsWithVideo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorseWorkoutData>>;
    public apiHorseWorkoutListDateGet(workoutDate?: string, track?: string, race?: number, onlyWorkoutsWithVideo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workoutDate !== undefined && workoutDate !== null) {
            queryParameters = queryParameters.set('workoutDate', <any>workoutDate);
        }
        if (track !== undefined && track !== null) {
            queryParameters = queryParameters.set('track', <any>track);
        }
        if (race !== undefined && race !== null) {
            queryParameters = queryParameters.set('race', <any>race);
        }
        if (onlyWorkoutsWithVideo !== undefined && onlyWorkoutsWithVideo !== null) {
            queryParameters = queryParameters.set('onlyWorkoutsWithVideo', <any>onlyWorkoutsWithVideo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HorseWorkoutData>('get',`${this.basePath}/api/horse-workout/list/date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param horseName 
     * @param onlyWorkoutsWithVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseWorkoutListGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<HorseWorkout>>;
    public apiHorseWorkoutListGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorseWorkout>>>;
    public apiHorseWorkoutListGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorseWorkout>>>;
    public apiHorseWorkoutListGet(horseName?: string, onlyWorkoutsWithVideo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (horseName !== undefined && horseName !== null) {
            queryParameters = queryParameters.set('horseName', <any>horseName);
        }
        if (onlyWorkoutsWithVideo !== undefined && onlyWorkoutsWithVideo !== null) {
            queryParameters = queryParameters.set('onlyWorkoutsWithVideo', <any>onlyWorkoutsWithVideo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HorseWorkout>>('get',`${this.basePath}/api/horse-workout/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param registrationNumber 
     * @param numberOfWorkoutsPerHorse 
     * @param onlyWorkoutsWithVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseWorkoutListHorseGet(registrationNumber?: string, numberOfWorkoutsPerHorse?: number, onlyWorkoutsWithVideo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<WorkoutByHorseResponse>;
    public apiHorseWorkoutListHorseGet(registrationNumber?: string, numberOfWorkoutsPerHorse?: number, onlyWorkoutsWithVideo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkoutByHorseResponse>>;
    public apiHorseWorkoutListHorseGet(registrationNumber?: string, numberOfWorkoutsPerHorse?: number, onlyWorkoutsWithVideo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkoutByHorseResponse>>;
    public apiHorseWorkoutListHorseGet(registrationNumber?: string, numberOfWorkoutsPerHorse?: number, onlyWorkoutsWithVideo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }
        if (numberOfWorkoutsPerHorse !== undefined && numberOfWorkoutsPerHorse !== null) {
            queryParameters = queryParameters.set('numberOfWorkoutsPerHorse', <any>numberOfWorkoutsPerHorse);
        }
        if (onlyWorkoutsWithVideo !== undefined && onlyWorkoutsWithVideo !== null) {
            queryParameters = queryParameters.set('onlyWorkoutsWithVideo', <any>onlyWorkoutsWithVideo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WorkoutByHorseResponse>('get',`${this.basePath}/api/horse-workout/list/horse`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceDate 
     * @param raceNumber 
     * @param trackId 
     * @param numberOfWorkoutsPerHorse 
     * @param registrationNumber 
     * @param onlyWorkoutsWithVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseWorkoutListRaceGet(raceDate?: string, raceNumber?: number, trackId?: string, numberOfWorkoutsPerHorse?: number, registrationNumber?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<WorkoutResponse>;
    public apiHorseWorkoutListRaceGet(raceDate?: string, raceNumber?: number, trackId?: string, numberOfWorkoutsPerHorse?: number, registrationNumber?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkoutResponse>>;
    public apiHorseWorkoutListRaceGet(raceDate?: string, raceNumber?: number, trackId?: string, numberOfWorkoutsPerHorse?: number, registrationNumber?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkoutResponse>>;
    public apiHorseWorkoutListRaceGet(raceDate?: string, raceNumber?: number, trackId?: string, numberOfWorkoutsPerHorse?: number, registrationNumber?: string, onlyWorkoutsWithVideo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceDate !== undefined && raceDate !== null) {
            queryParameters = queryParameters.set('raceDate', <any>raceDate);
        }
        if (raceNumber !== undefined && raceNumber !== null) {
            queryParameters = queryParameters.set('raceNumber', <any>raceNumber);
        }
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (numberOfWorkoutsPerHorse !== undefined && numberOfWorkoutsPerHorse !== null) {
            queryParameters = queryParameters.set('numberOfWorkoutsPerHorse', <any>numberOfWorkoutsPerHorse);
        }
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }
        if (onlyWorkoutsWithVideo !== undefined && onlyWorkoutsWithVideo !== null) {
            queryParameters = queryParameters.set('onlyWorkoutsWithVideo', <any>onlyWorkoutsWithVideo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WorkoutResponse>('get',`${this.basePath}/api/horse-workout/list/race`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param trainerName 
     * @param onlyWorkoutsWithVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseWorkoutListTrainerGet(trainerName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<HorseWorkoutData>;
    public apiHorseWorkoutListTrainerGet(trainerName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorseWorkoutData>>;
    public apiHorseWorkoutListTrainerGet(trainerName?: string, onlyWorkoutsWithVideo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorseWorkoutData>>;
    public apiHorseWorkoutListTrainerGet(trainerName?: string, onlyWorkoutsWithVideo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trainerName !== undefined && trainerName !== null) {
            queryParameters = queryParameters.set('trainerName', <any>trainerName);
        }
        if (onlyWorkoutsWithVideo !== undefined && onlyWorkoutsWithVideo !== null) {
            queryParameters = queryParameters.set('onlyWorkoutsWithVideo', <any>onlyWorkoutsWithVideo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HorseWorkoutData>('get',`${this.basePath}/api/horse-workout/list/trainer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
