<section class="responsive-width" [ngStyle]="{ padding: (mobile$ | async) ? '20px' : '60px' }">
  <p>TERMS OF USE</p>
  <p>Effective Date: May 20, 2019</p>
  <p>
    This website is operated by Equinedge, LLC (&ldquo;EQUINEDGE&rdquo;). &nbsp;Throughout these Terms of Use, the terms
    &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to EQUINEDGE. &nbsp;Please read these Terms of Use
    (&ldquo;Terms of Use&rdquo;) and our Privacy Policy (&ldquo;Privacy Policy&rdquo;) before using our website
    (&ldquo;site&rdquo;). &nbsp;By accessing this site and using the services offered on and through this site
    (&ldquo;Services&rdquo; as further defined below), you agree to be bound by these Terms of Use and the Privacy
    Policy. &nbsp;These Terms of Use and the Privacy Policy may be updated from time to time. &nbsp;Accordingly, you
    should check the date of these Terms of Use and the Privacy Policy, and review any changes since the last version.
    &nbsp;If you do not agree to these Terms of Use or the Privacy Policy, please do not use the site or any of the
    Services provided on the site.
  </p>
  <ol>
    <li>
      <strong><u>Definitions</u></strong>
    </li>
  </ol>
  <p>
    1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;<strong>EQUINEDGE Technology</strong>&rdquo; means all of
    EQUINEDGE&rsquo;s proprietary technology (including documentation, software, products, processes, algorithms, user
    interfaces, know-how, techniques, designs and other tangible or intangible technical material or information) made
    available to you by EQUINEDGE in providing the Services.
  </p>
  <p>
    1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;<strong>Services</strong>&rdquo; means the provisioning of access and
    use of this site, any EQUINEDGE Technology, your Account and any Subscriptions, including as related to horse
    racing, handicapping, wagering data and betting tickets as developed, operated, and maintained by EQUINEDGE, having
    characteristics as described in the site.
  </p>
  <ol start="2">
    <li>
      <strong> <u>Account Setup</u></strong>
    </li>
  </ol>
  <p>
    2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Some of the Services offered through the site require you to establish an
    online user account (&ldquo;Account&rdquo;) with us.&nbsp; Your Account will be identified by a unique Account Name
    and Password.
  </p>
  <p>
    2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In setting up an Account you will need to provide your first name, last
    name, state of residence, and email address.&nbsp; We may additionally allow you to optionally include a profile
    picture associated with your Account as well.
  </p>
  <p>
    2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You are responsible for all activity occurring under your account to access
    the Services and shall abide by all applicable laws and regulations in connection with your use of the Services.
  </p>
  <p>
    2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree not to disclose, transmit, post or give away or otherwise share
    your Password with any third party.&nbsp; You shall notify Us immediately of any unauthorized use of your Password
    or Account or any other known or suspected breach of security.
  </p>
  <p>
    2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You may only use the site if you are 18 years of age or older.&nbsp;
    Likewise, to register for an Account, purchase products via the site, subscribe to receive any content or other
    marketing or promotional material from us (in any form and via any media) (a
    &ldquo;<strong>Subscription</strong>&rdquo;), you must be 18 years of age or over. &nbsp;If you are under 18 years
    of age or not of legal age to form a binding contract in your place of residence, you must have your parent or legal
    guardian&rsquo;s permission to use the site.
  </p>
  <p>2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subscriptions automatically renew until cancelled.</p>
  <p>
    2.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You may cancel a Subscription at any time by following the instructions
    provided in the site or as included in your Subscription product purchase, redemption, or renewal confirmation
    email, all subject to these Terms of Use.
  </p>
  <p>
    2.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cancellation will cease auto-renewal of Subscriptions, but Subscription Fees
    are non-refundable except where required by law.
  </p>
  <ol start="3">
    <li>
      <strong> <u>Subscription Fees</u></strong>
    </li>
  </ol>
  <p>
    3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Some the Subscriptions require payment of Subscription Fees as described in
    the site.&nbsp; Subscription Fee will be collected in advance of provision of the applicable Subscription.&nbsp;
    Subscription Fees will be collected via credit card payment.
  </p>
  <p>
    3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We reserve the right to modify Our fees, including Subscription Fees, for
    any renewal term and to introduce new charges, upon at least 30 days&rsquo; notice to you prior to the end of the
    then current term (which notice may be provided by email).&nbsp; You shall pay all fees or charges to your account
    in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable.
  </p>
  <p>
    3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All payment obligations are noncancelable and all amounts paid are
    nonrefundable.&nbsp; You are responsible for paying for all fees the entire term, whether or not the Services are
    actively used. &nbsp;Our fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you
    shall be responsible for payment of all such taxes, levies, or duties, excluding only United States (federal or
    state) taxes based solely on Our income.
  </p>
  <ol start="4">
    <li>
      <strong> <u>Use of Material on the Site</u></strong>
    </li>
  </ol>
  <p>
    4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All content on this site (including without limitation, text, design,
    graphics, logos, icons, images, audio clips, downloads, interfaces, code and software, as well as the selection and
    arrangement thereof) and as provided via the Services are the exclusive property of and owned by Us and/or our
    content providers, and is protected by copyright, trademark and other applicable laws. &nbsp;
  </p>
  <p>
    4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The site and its contents are intended solely for personal, non-commercial
    use. &nbsp;
  </p>
  <p>
    4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You may access, copy, download and print the material contained on the site
    for your personal and non-commercial use, provided you do not modify or delete any copyright, trademark or other
    proprietary notice that appears on the materials you access, copy, download or print. &nbsp;No right, title or
    interest in any downloaded content is transferred to you as a result of any such downloading or copying. &nbsp;You
    may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works
    from, sell, or exploit in any way the site or any of its contents, or any information provided via the Services.
    &nbsp;Unless otherwise specified, the site and its contents are intended to promote our products and/or services
    available in the U.S.
  </p>
  <p>
    4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Further Restrictions.&nbsp; Except as expressly provided herein, you will
    not, and you will not encourage or create functionality for you or other third parties to:
  </p>
  <p>
    (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; permit a third party to use the Services, access, upload, generate, or
    maintain content or information on any of Our computer servers;
  </p>
  <p>
    (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; reverse engineer or access the Services in order to (i) build a
    competitive product or service, (ii) build a product using similar ideas, features, functions or graphics of the
    Services, or (iii) copy any ideas, features, functions or graphics of the Services or the EQUINEDGE Technology;
  </p>
  <p>
    (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; attempt to gain unauthorized access to the Services or the EQUINEDGE
    Technology, or its related systems or networks;
  </p>
  <p>
    (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; sell, lease, rent, redistribute, or sublicense any access to or use of the
    site, the Services, or the EQUINEDGE Technology, or otherwise transfer any rights to use the site, the Services, or
    the EQUINEDGE Technology;
  </p>
  <p>
    (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; interfere or attempt to interfere in any manner with the proper
    working of the site or the Services, including sending or storing material containing software viruses, worms,
    Trojan horses or other harmful computer code, files, scripts, agents or programs;
  </p>
  <p>
    (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; circumvent or render ineffective any geographical restrictions,
    including IP address-based restrictions when using the site or the Services;
  </p>
  <p>
    (g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use the site or the Services in any manner or for any purpose that
    violates any law or regulation, or any right of any third person, including but not limited to transmitting or
    storing infringing, obscene, threatening, libelous, or otherwise unlawful or tortuous material; or
  </p>
  <p>
    (j)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; misrepresent your identity when registering for an Account or
    mask your usage of the Services.
  </p>
  <ol start="5">
    <li>
      <strong> <u>Disclaimers</u></strong>
    </li>
  </ol>
  <p>
    5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your use of this site and the Services is at your sole risk. &nbsp;The site
    and the Services are provided on an &ldquo;<strong>as is</strong>&rdquo; and &ldquo;<strong>as available</strong
    >&rdquo; basis. &nbsp;We reserve the right to restrict or terminate your access to the site or any feature or part
    thereof at any time. &nbsp;
  </p>
  <p>
    5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We expressly disclaim all warranties of any kind, whether expressed or
    implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose
    and any warranties that materials on the site are noninfringing, as well as warranties implied from a course of
    performance or course of dealing, that access to the site will be uninterrupted or error-free, that the site will be
    secure, that the site or the server that makes the site available will be virus-free, or that information on the
    site will be complete, accurate or timely. &nbsp;
  </p>
  <p>
    5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No advice or information whether oral or written, obtained by you from Us or
    through or from the site or the Services shall create any warranty of any kind. &nbsp;We do not make any warranties
    or representations regarding the use of the materials on this site or the Services in terms of their completeness,
    correctness, accuracy, adequacy, usefulness, timeliness, reliability or otherwise. &nbsp;Past performance is no
    guarantee of future results, including as in relation to betting tickets and handicapping information.
  </p>
  <p>
    5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you download any materials from this site or the Services (including
    betting tickets), you do so at your own discretion and risk.&nbsp; You will be solely responsible for any damage to
    your computer system or loss of data that results from the download of any such materials.&nbsp;
  </p>
  <ol start="6">
    <li>
      <strong> <u>Limitation of Liability</u></strong>
    </li>
  </ol>
  <p>
    6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You acknowledge and agree that you assume full responsibility for your use
    of the site and the Services. &nbsp;Recognizing such, you acknowledge and agree that, to the fullest extent
    permitted by applicable law neither us nor our affiliates, suppliers or third party content providers will be liable
    for any direct, indirect, punitive, exemplary, incidental, special, consequential or other damages arising out of or
    in any way related to the site, or any other site you access through a link from this site or from any actions we
    take or fail to take as a result of communications you send to us or the delay or inability to use the site or for
    any information, products, or services advertised in or obtained through the site, or our removal or deletion of any
    materials submitted or posted on the site or otherwise arising out of the use of the site, whether based on
    contract, tort, strict liability or otherwise, even if we, our affiliates or any of our suppliers have been advised
    of the possibility of damages. &nbsp;
  </p>
  <p>
    6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This disclaimer applies without limitation to any damages or injury arising
    from any failure of performance, error, omission, interruption, deletion, defects, delay in operation or
    transmission, computer viruses, file corruption, communication on-line failure, network or system outage, your loss
    of profits or theft, destruction, unauthorized access to, alteration of, loss or use of any record or data, and any
    other tangible or intangible loss. &nbsp;
  </p>
  <p>
    6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your sole and exclusive remedy for any of the above claims or any dispute
    with us regarding the site is to discontinue your use of the site and the Services. &nbsp;
  </p>
  <p>
    6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You and Us agree that any cause of action arising out of or related to the
    site or the Services must commence within one (1) year after the cause of action accrues or the cause of action is
    permanently barred.
  </p>
  <ol start="7">
    <li>
      <strong> <u>Indemnification</u></strong>
    </li>
  </ol>
  <p>
    You agree to indemnify, defend and hold harmless Us and any of Our affiliates and our respective officers,
    directors, employees, contractors, agents, licensors, service providers, subcontractors and suppliers from and
    against any and all losses, liabilities, expenses, damages and costs, including reasonable attorneys fees and court
    costs, arising or resulting from your use of the site and any violation of these Terms of Use. &nbsp;If you cause a
    technical disruption of the site or the systems transmitting the site to you or others, you agree to be responsible
    for any and all losses, liabilities, expenses, damages and costs, including reasonable attorney&rsquo;s fees and
    court costs, arising or resulting from that disruption. &nbsp;We reserve the right, at Our own expense, to assume
    exclusive defense and control of any matter otherwise subject to indemnification by you and in such a case, you
    agree to cooperate with us in the defense of such matter.
  </p>
  <ol start="8">
    <li>
      <strong> <u>User Comments, Feedback, and Other Submissions</u></strong>
    </li>
  </ol>
  <p>
    Our policy does not allow us to accept or to consider creative ideas, suggestions, proposals, plans, or other
    materials other than those we have specifically requested. &nbsp;We hope that you will understand that the intent of
    this policy is to avoid the possibility of future misunderstandings when projects developed by our employees and
    agents might seem to be similar to creative works submitted by users.&nbsp; Accordingly, while we value your
    feedback, we must ask that you do not send creative ideas, suggestions, proposals, plans, or other materials for our
    business. &nbsp;If, at our request, you send certain specific submissions (for example contest entries) or without
    out a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by
    email, by postal mail, or otherwise (collectively, &ldquo;Comments&rdquo;), you agree that we may, at any time,
    without restriction, edit, copy, publish, distribute, translate, and otherwise use in any medium any Comments that
    you forward to us.&nbsp; We are and shall be under no obligation (1) to maintain any Comments in confidence; (2) to
    pay compensation for any Comments; or (3) to respond to any Comments. &nbsp;We have the right but not the obligation
    to monitor, edit and/or remove any Comments.&nbsp; You agree that your Comments will not violate any right of any
    third party, including copyright, trademark, privacy or other personal or proprietary right. &nbsp;You further agree
    that your Comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any
    computer virus or other malware that could in any way affect the operation of the site.&nbsp; You may not use a
    false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the
    origin of any Comments.&nbsp; You are solely responsible for any Comments you make and their accuracy.&nbsp; We take
    no responsibility and assume no liability for any Comments posted by you or any third party.
  </p>
  <ol start="8">
    <li>
      <strong> <u>Assignment</u></strong>
    </li>
  </ol>
  <p>
    These Terms of Use shall be binding upon and inure to the benefit of both of Our and your heirs, legal
    representatives, successors, and assignees.&nbsp; You may not assign these Terms of Use or any of its rights or
    obligations hereunder without prior written consent from Us.&nbsp;
    <a name="_Toc257701804"></a>We may freely assign these Terms of Use to another party provided We give you written
    notice, including my updating these Terms of Use.
  </p>
  <ol start="9">
    <li>
      <strong> <u>General</u></strong>
    </li>
  </ol>
  <p>
    9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Relationship of the Parties.&nbsp; The parties agree and acknowledge that
    the relationship of the parties is in the nature of an independent contractor.&nbsp; These Terms of Use shall not be
    deemed to create a partnership or joint venture and neither party is the other&rsquo;s agent, partner, employee or
    representative.
  </p>
  <p>
    9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No Trademark Usage.&nbsp; Except as provided herein, neither party may use
    the name, trade name, trademark, domain name or other designation of the other party in connection with any
    products, promotion or advertising without the prior written permission of the other party.
  </p>
  <p>
    9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Force Majeure.&nbsp; Neither party shall be liable to the other for any
    delay or failure to perform due to causes beyond its reasonable control.&nbsp; Performance times shall be considered
    extended for a period of time equivalent to time lost because of any such delay by providing prompt written notice
    of such expected delay to the other party.
  </p>
  <p>
    9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Governing Law, Venue and Attorney&rsquo;s Fees.&nbsp; These Terms of Use
    shall be construed and enforced in accordance with the laws of the State of California.&nbsp; Any action or
    proceeding brought by any of the parties against the other arising out of or relating to these Terms of Use shall be
    brought exclusively in a court of competent jurisdiction, located within the county of Orange, California, and the
    parties hereby consent to the in personam jurisdiction of such courts for purposes of any such action or
    proceeding.&nbsp; If any action or proceeding is brought to enforce the terms of these Terms of Use the prevailing
    party in such action or proceeding shall be entitled to recover its costs and reasonable attorneys&rsquo; fees
    incurred, whether or not suit is prosecuted to judgment.
  </p>
  <p>
    9.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Remedies.&nbsp; It is understood and agreed that Our remedies at law for a
    breach by you or your obligations under these Terms of Use without Our prior express written consent, will be
    inadequate and that in the event of any such breach or threatened breach, We shall be entitled to equitable relief
    without the necessity of posting any bond (including without limitation preliminary and permanent injunctive relief)
    in addition to all other remedies provided hereunder or available at law.
  </p>
  <p>
    9.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Enforceability.&nbsp; If any condition or provision of these Terms of Use is
    found by a court of competent jurisdiction to be for any reason unenforceable or invalid, the remainder of these
    Terms of Use will remain enforceable with the omission of said invalid or unenforceable portion thereof, as though
    said unenforceable portion had never been included herein.
  </p>
  <p>
    9.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Non-Waiver.&nbsp; The waiver of failure of either party to exercise any
    right in any respect provided for herein shall not be deemed to be a waiver of any further right hereunder.
  </p>
  <p>
    9.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Entire Agreement.&nbsp; These Terms of Use expressly supersedes any and all
    previous agreements either written or oral between the parties and can only be modified by a subsequent written
    agreement between the parties.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</section>
