import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  GetHorsesToWatchDto,
  GetHorsesToWatchResultsDto, GetHorseToWatchDto, HorsesToWatchResultsService, HorsesToWatchService
} from "@swagger-codegen/*";
import { AppSandboxService } from "@services/sandbox.service";
import { ColumnSortedEvent, SortService } from "../../../sortable-column/sort-service";
import { animate, keyframes, transition, trigger } from "@angular/animations";
import * as kf from "../../../services/keyframes";
import {forkJoin, Observable} from "rxjs";
import { Router } from "@angular/router";
import { ActivityService } from "@services/activity.service";
import { DataCache } from "../../../services/data-cache.service";
import { HeaderService } from "../../../services/header.service";
import _ from "lodash";
import { Functions } from "@services/functions";
import moment from "moment/moment";
import { TakeUntilDestroy } from "@services/take-until-destroy.decorator";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { BlockUiService } from '@services/block-ui.service';

/**
 * Horses To Watch Modal is designed to show the current day
 * record fo the Horses To Watch algorithm
 */
@TakeUntilDestroy
@Component({
  selector: 'app-modal-horses-to-watch',
  templateUrl: './modal-horses-to-watch.component.html',
  styleUrls: ['./modal-horses-to-watch.component.scss'],
  animations: [
    trigger('flip', [
      transition('* => runningAnimation', animate(1000, keyframes(kf.flipOutYRight))),
      transition('runningAnimation => *', animate(1000, keyframes(kf.flipInYRight))),
    ]),
  ],
})
export class ModalHorsesToWatchComponent {
  _horsesToWatchResults: GetHorsesToWatchResultsDto = null;
  public _horsesToWatchCurrent: GetHorsesToWatchDto;
  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();
  backgroundId = Math.floor(Math.random() * 13);
  currentDate = moment().tz('America/Los_Angeles').format('yyyy-MM-DD');
  currentDateForTitle = moment().tz('America/Los_Angeles').format('MM/DD/yyyy');
  componentDestroy: () => Observable<boolean>;
  public animationState: { [key: string]: string } = {};
  pageFrom = 0;
  resetPages = new EventEmitter();
  sortEvent: ColumnSortedEvent = {
    sortColumn: 'postTime',
    sortDirection: 'asc',
    sortedComponent: 'topChalks',
  };
  selectedDate: NgbDateStruct;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  @ViewChild('d') datePicker;

  constructor(
    private sb: AppSandboxService,
    private router: Router,
    public sortService: SortService,
    private activity: ActivityService,
    public dataCache: DataCache,
    private headerService: HeaderService,
    private horseToWatchService: HorsesToWatchService,
    private horsesToWatchResultsService: HorsesToWatchResultsService
  ) {
    forkJoin([this.horseToWatchService.apiHorsesToWatchGet(), this.horsesToWatchResultsService.apiHorsesToWatchResultsGet()])
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe(results => {
        this.horsesToWatch = results[0];
        this.horsesToWatchResults = results[1];
      })
  }


  openCalendar() {
    this.datePicker.open();
  }


  @Input()
  set horsesToWatch(value: GetHorsesToWatchDto) {
    this._horsesToWatchCurrent = value??{};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this._horsesToWatchCurrent?.horsesToWatch?.length; i++) {
      this.animationState[this._horsesToWatchCurrent?.horsesToWatch[i]?.bettingNumber] = 'runningAnimation';
    }
    this.onSorted({
      sortColumn: 'postTime',
      sortDirection: 'asc',
      sortedComponent: 'modal-horses-to-watch',
    });
  }

  @Input()
  set horsesToWatchResults(value: GetHorsesToWatchResultsDto) {
    if (!this._horsesToWatchResults) {
      this._horsesToWatchResults = value;
      this.minMaxDateUpdate();
    } else {
      this._horsesToWatchResults = value;
    }
  }

  minMaxDateUpdate() {
    this.minDate = this.convertDate(this._horsesToWatchResults.earliestDate);
    this.maxDate = this.convertDate(this.currentDate);
    this.selectedDate = this.maxDate;
  }

  get horsesToWatchResults() {
    return this._horsesToWatchResults;
  }

  convertDate(dateString: string): { year: number; month: number; day: number; } {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    return {year, month, day};
  }

  updateDate() {
    const arrResults = _.chain(this._horsesToWatchCurrent?.horsesToWatch)
      .filter((x) => +x.raceId > 0)
      .orderBy((x) => Functions.typeSort(x, this.sortEvent.sortColumn), this.sortEvent.sortDirection)
      .concat(
        _.chain(this._horsesToWatchCurrent?.horsesToWatch)
          .filter((x) => !x.raceId)
          .value()
      )
      .slice(this.pageFrom, this.pageFrom + 5)
      .value() as GetHorseToWatchDto[];
    this._horsesToWatchCurrent.horsesToWatch = arrResults??[];
  }

  get horsesToWatch(): GetHorsesToWatchDto {
    return this._horsesToWatchCurrent;
  }

  updateBackgroundId() {
    this.backgroundId = Math.floor(Math.random() * 13);
  }

  applySlice(st: string): string {
    if (st && st.length > 22) {
      return st.slice(0, 22) + '...';
    } else {
      return st;
    }
  }

  getCurrentDate(): Date {
    return new Date();
  }

  checkDate(): void {
    this.datePicker.close();
    if (!this._horsesToWatchResults.horsesToWatchResults.hasOwnProperty(this.formatDate(this.selectedDate))) {
      BlockUiService.start()
      this.horsesToWatchResultsService.apiHorsesToWatchResultsGet(this.formatDate(this.selectedDate)).pipe(
        finalize(BlockUiService.stop),
        takeUntil(this.componentDestroy()))
        .subscribe(results => {
          console.log(results);
          this.horsesToWatchResults = results;
        });
    }
    this.currentDate = this.formatDate(this.selectedDate);
    this.currentDateForTitle = moment(this.currentDate).format('MM/DD/yyyy');
  }

  formatDate(obj: { year: number; month: number; day: number; }): string {
    const year = obj.year.toString().padStart(4, '0');
    const month = obj.month.toString().padStart(2, '0');
    const day = obj.day.toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public resetAnimationState(bettingNumber: number): void {
    if (this.animationState[bettingNumber] === 'runningAnimation') {
      this.animationState[bettingNumber] = '';
    }
  }

  navigateToRace(chalk: GetHorseToWatchDto): void {
    if (chalk.raceId) {
      this.headerService.headerState$.next(true);
      this.router
        .navigateByUrl(
          '/races/' + chalk.raceId + '/' + chalk.postTime.slice(0, 10).replace('/', '-') + '_' + chalk.raceNumber
        )
        .then(() => {
          this.dataCache.updateSelector.next({raceId: +chalk.raceId});
        });
    }
  }

  onSorted(event: ColumnSortedEvent): void {
    if (event && event.sortedComponent === 'modal-horses-to-watch') {
      this.activity.log('sort', event.sortColumn, event.sortDirection);
      this.sortEvent = event;
      this.sortByEvent();
    }
    this.updateDate()
  }

  pageChange(itemFrom: number): void {
    this.pageFrom = itemFrom;
  }

  checkSorting(column: string): 'desc' | 'asc' | boolean {
    if (this.sortEvent && this.sortEvent.sortColumn === column) {
      return this.sortEvent.sortDirection;
    } else {
      return null;
    }
  }

  sortByEvent(): void {
    if (this.sortEvent && this.horsesToWatch?.horsesToWatch) {
      this.sortService.lastSortEvent = this.sortEvent;
    } else if (!this.sortEvent && this.horsesToWatch?.horsesToWatch && this.sortService.lastSortEvent) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  reset(): void {
    this.resetPages.emit();
  }

  closeModal() {
    this.closeModal$.emit(true);
  }
}
