<section class="responsive-width" [ngStyle]="{ padding: (mobile$ | async) ? '20px' : '60px' }">
  <p>PRIVACY POLICY</p>
  <p>Effective Date: May 20, 2019</p>
  <p>
    This website is operated by Equinedge, LLC (&ldquo;EQUINEDGE&rdquo;). &nbsp;Throughout this Privacy Policy, the
    terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to EQUINEDGE.&nbsp; Please read this Privacy
    Policy and our Terms of Use before using our website (&ldquo;site&rdquo;).&nbsp; By accessing this site and using
    the services offered on and through this site (&ldquo;Services&rdquo; as defined in the Terms of Use), you agree to
    be bound by the Terms of Use and this Privacy Policy.&nbsp;
  </p>
  <p>
    We understand that our users are concerned about how personal information is collected and used.&nbsp; Please be
    assured that we take your privacy very seriously and are committed to safeguarding personal information or personal
    data.&nbsp; This notice describes our Privacy Policy, which covers the personal information that we collect on
    site.&nbsp; The Privacy Policy applies to the use and sharing of personal information collected on and after the
    date that this Privacy Policy is posted.&nbsp; The Terms of Use and this Privacy Policy may be updated from time to
    time.&nbsp; Accordingly, you should check the date of the Terms of Use and this Privacy Policy, and review any
    changes since the last version.&nbsp; If you do not agree to the Terms of Use or this Privacy Policy, please do not
    use the site or any of the Services provided on the site.
  </p>
  <ol>
    <li><strong>Information Collection and Use</strong></li>
  </ol>
  <p>
    We are determined to protect your privacy, and takes reasonable steps to this end, such as requiring a password at
    login. &nbsp;No Internet, e-mail, or other electronic communication is ever fully secure or error free, so you
    should take special care in deciding what information you send us. &nbsp;If you set up an account with us through
    the site, you, the user, are responsible for maintaining the secrecy of your login information.
  </p>
  <p>
    <em>Account Information</em>:&nbsp; When you create or reconfigure an EQUINEDGE account, or when you access, login,
    or visit the Service, you provide some personal information that particularly identifies you (&ldquo;Personally
    Identifiable Information&rdquo;). &nbsp;This includes your name, username, password, state of residence and e-mail
    address that are required when you sign up for an account through the site.&nbsp; None of this information is public
    or otherwise viewable by others visiting the site.
  </p>
  <p>
    <em>User Generated Content</em>:&nbsp; You may also choose to create certain content through your account on the
    site, such as your notes regarding horse racing, handicapping, wagering data and betting tickets that you may
    generate and store through the site using the Services (&ldquo;User Generated Content&rdquo;).&nbsp; None of this
    information is public or otherwise viewable by others visiting the site.
  </p>
  <p>
    <em>Use of Personally Identifiable Information and User Generated Content</em>:&nbsp; The Personally Identifiable
    Information and the User Generated Content you provide is used to the extent we have a lawful basis for doing so,
    including our &ldquo;legitimate interest,&rdquo; contractual necessity, and consent. &nbsp;The legitimate interests
    of ours include the purposes of:
  </p>
  <ul>
    <li>
      Providing the Service&rsquo;s functionality, improving our services, personalizing the Services, and fulfilling
      your requests;
    </li>
    <li>
      Research and analysis, including tracking usage of the Services, providing feedback to third parties that are on
      the Services, and researching the effectiveness of our Services;
    </li>
    <li>
      Sales and marketing, including displaying relevant advertising, marketing the Service, and communicating with you
      about specials and new features;
    </li>
    <li>Providing customer support, troubleshooting, and responding to your customer service inquiries; and</li>
    <li>
      Administration, including backing up our systems, allowing for disaster recovery, enhancing the security of our
      Services, preventing misuse of our Services and to comply with legal obligations.
    </li>
  </ul>
  <p>
    Irrespective of which country that you reside in or create information from, your information may be used by us in
    the United States or any other country where we operate. &nbsp;All data for our Services are stored on
    ___&lt;example---Amazon Web Services&gt;___ servers. If you are outside the United States, your information will be
    sent to and stored in the United States, where the servers are located, please see Amazon&rsquo;s Privacy Policy for
    more information.
  </p>
  <p>
    <em>Passive Collection</em>:&nbsp; We collect information passively using servers that automatically record
    information created by your use of the Services. &nbsp;This information may include:
  </p>
  <ul>
    <li>Your IP address</li>
    <li>
      Technical information about your computer or mobile device (such as device type, browser type, your mobile
      carrier, operating system, application identifier, installed application version, and/or last access time)
    </li>
    <li>Your preferences and settings (time zone, language, etc.)</li>
    <li>The unique identification number of your mobile device</li>
    <li>
      The geographic location of your (mobile) device (specific geographic location if you have enabled collection of
      that information, or general geographic location automatically)
    </li>
    <li>The referring domain, pages visited and order in which those pages were visited</li>
    <li>The amount of time spent on particular screens, and the dates and times of your requests</li>
    <li>
      A detailed log of the time and dates of your use of the Services, any information added during your use of the
      Services, any information downloaded during your use of the Services, and the steps taken in the use of the
      Services
    </li>
    <li>Search terms</li>
  </ul>
  <p>
    Other actions, such as interactions with our Services and advertisements, may also be collected. We may use this
    information to determine the frequency with which our users visit various parts of our Services, or use various
    functions of our Services to help diagnose problems with our servers, to administer the Services, and to conduct
    research on its customer demographics, interests and behavior based on the Personally Identifiable Information, the
    User Generated Content, and other information provided to us. Such research may be compiled and analyzed on an
    aggregate basis. &nbsp;We may use a third party service to collect this information, including Google Analytics, and
    our use of the information is within the limits imposed by the Google Analytics Terms of Service.
  </p>
  <p>
    <em>Cookies</em>: &nbsp;We may use &ldquo;cookie&rdquo; technology to collect additional Site usage data and improve
    our Services to deliver content specific to your interests. &nbsp;A cookie is a small data file that is transferred
    to your computer&rsquo;s hard disk. &nbsp;We may use both session cookies and persistent cookies to better
    understand how you interact with our Services, to monitor aggregate usage by our users and web traffic routing on
    our Services. &nbsp;With the use of cookies, you will not be required to reenter your login data upon each visit.
    &nbsp;Your web browser can be configured to warn you each time a cookie is being stored, or to turn off all cookies.
    &nbsp;Please note that changing any of these settings does not prevent the display of certain advertisements to you.
  </p>
  <p>
    <em>Pixel Tags</em>: &nbsp;We may embed pixel tags (also called web beacons or clear GIFs) on web pages, ads, and
    emails. &nbsp;These tiny, invisible graphics are used to access cookies and track user activities (such as how many
    times a page is viewed). &nbsp;We use pixel tags to measure the popularity of our features and services.
  </p>
  <p>
    <em>Mobile Device IDs</em>: &nbsp;We may use mobile device IDs (the unique identifier assigned to a device by the
    manufacturer), instead of or in addition to cookies, to recognize you. &nbsp;We do this to store your preferences
    and track your use of our Services. &nbsp;Unlike cookies, device IDs cannot be deleted.
  </p>
  <p>
    <em>Do Not Track Signals</em>: &nbsp;&ldquo;Do Not Track&rdquo; or DNT is a feature enabled on some browsers that
    sends a signal to request that a web application disable its tracking or cross-site user tracking. &nbsp;At present,
    our site does not respond to or alter its practices when a DNT signal is received.
  </p>
  <p>
    <em>Betting Ticket Output Statistics:</em>&nbsp; We may use any of the betting ticket output for our own purposes,
    including in generating statistics and performance data related to the same.
  </p>
  <ol start="2">
    <li><strong>Information Sharing and Disclosure</strong></li>
  </ol>
  <p>
    <em>Purchase/Sale/Aquisition of Businesses</em>:<em> &nbsp;</em>From time to time we may purchase or acquire a
    business or business assets, or one or more of our businesses may be sold, and your personally identifiable
    information may be transferred as a part of the purchase, sale or acquisition.
  </p>
  <p>
    <em>Disclosures Required by Law and Other Possible Disclosures</em>:<em> &nbsp;</em>We will disclose Personal
    Information when we believe in good faith that such disclosures are required by law or that disclosure is necessary,
    for example, to comply with a court order or subpoena; will help to enforce our Terms of Use; to provide authorities
    with evidence of any breach of our Terms of Use that constitute or suspected to constitute the violation of any law;
    to investigate, prevent, or take action regarding illegal or suspected illegal activities;; enforce contest,
    sweepstakes, promotions, and/or game rules; protect your safety or security; or protect the safety and security of
    the Services, us, or third parties, including the safety and security of property that belongs to us or third
    parties.
  </p>
  <p>
    <em>Service Providers</em>: &nbsp;We may engage certain trusted third parties to perform functions and provide
    services to us. We may share your personal information with these third parties, but only to the extent necessary to
    perform these functions and provide such services, and only pursuant to the obligations mirroring the protections of
    this Privacy Policy.
  </p>
  <p>
    <em>Third Party Advertising:</em>&nbsp; We may use third party advertising companies to serve advertisements to you
    when you visit the site. &nbsp;These companies may use information about your visits to this and other websites in
    order to provide advertisements about goods and services of interest to you. &nbsp;We may allow third parties to use
    Cookies through the Service to collect the same type of information for the same purposes as we do. &nbsp;In doing
    so, we adhere to the Digital Advertising Alliance&rsquo;s Self-Regulatory Principles for Online Behavioral
    Advertising. &nbsp;Third parties may be able to associate the information they collect with other information they
    have about you from other sources. &nbsp;We do not necessarily have access to or control over the Cookies they use,
    but you may be able to opt out of some of their practices by visiting the following links: __&lt;include links to
    advertisers e.g., <a href="http://www.networkadvertising.org/choices/">Network Advertising Initiative</a>,
    <a
      href="https://www.d1.sc.omtrdc.net/optout.html?omniture=1&amp;popup=1&amp;locale=en_US&amp;second=1&amp;second_has_cookie=0"
      >Omniture</a
    >,
    <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance</a>
    and
    <a href="http://privacychoice.org/trackerblock">PrivacyChoice</a>&gt;______. &nbsp;Please note that opting out does
    not prevent the display of all advertisements to you. &nbsp;Additionally, we may share non-personally identifiable
    information from or about you with third parties, such as location data, advertising identifiers, or a cryptographic
    hash of a common account identifier (such as an email address) to facilitate the display of targeted advertising.
    &nbsp;You may be able to limit our sharing of some of this information through your computer settings or mobile
    device settings, or through the Service&rsquo;s settings.
  </p>
  <p>
    <em>Non-Private or Non-Personal Information</em>: &nbsp;We may share or disclose your non-private, aggregated, or
    otherwise non-personal information with affiliates, agents and business partners of ours. &nbsp;This information may
    additionally include betting ticket outputs from use of our Services and statistics regarding such betting ticket
    outputs.&nbsp; We may disclose aggregated user statistics in order to describe our Services to current and
    prospective business partners, and to other third parties for other lawful purposes.
  </p>
  <ol start="3">
    <li>
      <strong>Controlling your Personally Identifiable Information</strong>
    </li>
  </ol>
  <p>
    To keep your Personally Identifiable Information accurate, current and complete, we provide you with tools to access
    or modify such information you provided to us and associated with your account.
  </p>
  <ol start="4">
    <li><strong>Deactivation of Account</strong></li>
  </ol>
  <p>
    You can deactivate your account by clicking this link, and by completing the account closing procedure, your
    Personally Identifiable Information will be deleted within six (6) monthsafter receiving your request.&nbsp; We may
    retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law.
  </p>
  <ol start="5">
    <li><strong>Data Retention</strong></li>
  </ol>
  <p>
    We retain Personally Identifiable Information about you for a period of time consistent with the original purpose of
    collection, whether or not you have an account with us, if doing so is necessary for our legitimate purposes.
    &nbsp;After we deactivate your account, we may retain some information in a depersonalized or aggregated form but
    not in a way that would identify you personally.
  </p>
  <ol start="6">
    <li><strong>Marketing Communications and Sharing Preferences</strong></li>
  </ol>
  <p>
    If you do not want to receive promotional emails from us, you can click the &ldquo;unsubscribe&rdquo; button on the
    promotional e-mail; communications, or email us at
    <a href="mailto:privacy@__________.com">privacy@__________.com</a> with the subject &ldquo;Marketing Opt-Out.&rdquo;
    You will not be able to unsubscribe or opt-out of non-promotional messages regarding your account, such as account
    verification, password reminders, changes or updates to features of the Services, or technical and security notices.
  </p>
  <p>
    If you have agreed to receive communications from us or to permit us to share your Personally Identifiable
    Information with third parties, you may request that, in the future, we not send you communications or share your
    Personally Identifiable Information.
  </p>
  <ol start="7">
    <li><strong>Security</strong></li>
  </ol>
  <p>
    We use various safeguards to protect the personal information submitted to us, both during transmission and once we
    receive it.&nbsp; However, no method of transmission over the internet or via mobile device, or method of electronic
    storage, is 100% secure.&nbsp; Therefore, while we strive to use commercially acceptable means to protect your
    personal information, we cannot guarantee its absolute security. &nbsp;In the event that any information under our
    control is compromised as a result of a breach of security, we will take reasonable steps to investigate the
    situation and where appropriate, notify those individuals whose information may have been compromised and take other
    steps, in accordance with nay applicable laws the regulations.
  </p>
  <ol start="8">
    <li><strong>Our Policy Towards Children</strong></li>
  </ol>
  <p>
    We do not knowingly collect or solicit The Children's Online Privacy Protection Act imposes certain requirements on
    websites directed at children under 13 that collect information on those children, and on websites that knowingly
    collect information on children under 13. &nbsp;Our Services are not directed at children under 13 and does not
    knowingly collect any personal information from children under 13. If you are under 13, you should not register to
    become a member or provide any personally information through this Site. If you have reason to believe that a child
    under 13 has provided personal information to us through the Services, please contact us at
    <a href="mailto:privacy@__________.com">privacy@__________.com</a>, and we will endeavor to delete that information.
  </p>
  <ol start="9">
    <li><strong>Your Privacy Rights</strong></li>
  </ol>
  <p>
    <em>Notice to California Residents</em>: &nbsp;A California resident who has provided personal information to a
    business with whom he/she has established a business relationship for personal, family, or household purposes
    (&ldquo;California customer&rdquo;) is entitled to request information about whether the business has disclosed
    personal information to any third parties for the third parties' direct marketing purposes. &nbsp;In general, if the
    business has made such a disclosure of personal information, upon receipt of a request by a California customer, the
    business is required to provide a list of all third parties to whom personal information was disclosed in the
    preceding calendar year, as well as a list of the categories of personal information that were disclosed.
  </p>
  <p>
    A business is not required to provide the above-described lists if the business adopts and discloses to the public
    (in its Privacy Policy) a policy of not disclosing a customer's personal information to third parties for their
    direct marketing purposes unless the customer first affirmatively agrees to the disclosure or gives customers a
    mechanism to opt out of having their personal information disclosed to third parties for their direct marketing
    purposes. &nbsp;The business may comply with the law by notifying the customer of his or her right to prevent
    disclosure of personal information and providing a cost free means to exercise the right.
  </p>
  <p>
    <em>Notice to European Union &ldquo;EU&rdquo; Residents</em>: &nbsp;If you are located in the EU, the United
    Kingdom, Lichtenstein, Norway, or Iceland, and use or access the Services, you may have certain rights with respect
    to your Personally Identifiable Information. &nbsp;Please note that in some circumstances, we may not be able to
    fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of
    others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a
    decision. &nbsp;In some cases, we may also need to you to provide us with additional information, which may include
    Personally Identifiable Information, if necessary to verify your identity and the nature of your request.
  </p>
  <ul>
    <li>
      Access: &nbsp;You can request more information about the Personally Identifiable Information we hold about you and
      request a copy of it. &nbsp;You can also access certain of your Personally Identifiable Information by logging
      into your account.
    </li>
    <li>
      Rectification: &nbsp;If you believe that any Personally Identifiable Information we are holding about you is
      incorrect or incomplete, you can request that we correct or supplement such information. &nbsp;You can also
      correct some of this information directly by logging into your account. &nbsp;Please contact us as soon as
      possible upon noticing any such inaccuracy or incompleteness.
    </li>
    <li>
      Erasure: &nbsp;You can request that we erase some or all of your Personally Identifiable Information from our
      systems.
    </li>
    <li>
      Withdrawal of Consent: &nbsp;If we are processing your Personally Identifiable Information based on your consent
      (as indicated at the time of collection of such information), you have the right to withdraw your consent at any
      time. &nbsp;Please note, however, that if you exercise this right, you may have to then provide express consent on
      a case-by-case basis for the use or disclosure of certain of your Personally Identifiable Information, if such use
      or disclosure is necessary to enable you to utilize some or all of our Services.
    </li>
    <li>
      Portability: &nbsp;You can ask for a copy of your Personally Identifiable Information in a machine-readable
      format. &nbsp;You can also request that we transmit the data to another controller where technically feasible.
    </li>
    <li>
      Objection: &nbsp;You can contact us to let us know that you object to the further use or disclosure of your
      Personally Identifiable Information for certain purposes.
    </li>
    <li>
      Restriction of Processing: &nbsp;You can ask us to restrict further processing of your Personally Identifiable
      Information.
    </li>
    <li>
      Right to File Complaint: &nbsp;You have the right to lodge a complaint about our practices with respect to your
      Personally Identifiable Information with the supervisory authority of your country or EU Member State.
    </li>
  </ul>
  <ol start="10">
    <li><strong>Contacting Us</strong></li>
  </ol>
  <p>You may contact us online concerning our Privacy Policy, or write to us at the following address:</p>
  <p>EQUINEDGE, LLC</p>
  <p>Attn: Data Privacy Officer</p>
  <p>___________</p>
  <p>____________ CA _____</p>
  <p>&nbsp;</p>
  <ol start="11">
    <li><strong>Notice of Changes</strong></li>
  </ol>
  <p>
    We may revise this Privacy Policy from time to time. &nbsp;The most current version of the Privacy Policy will
    govern our collection, use and disclosure of information about you.&nbsp; If changes are made to this Privacy
    Policy, we will notify you either by e-mail or on the Site. &nbsp;If changes are material, we will provide you
    additional prominent notice as appropriate under the circumstances.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</section>
