<ng-template #footerTemplate>
  <hr class="my-0"/>
  <div *ngIf="currentPlan?.planCode === ProductCodes.singleTrack || currentPlan?.planCode === ProductCodes.specialSingleTrack" class="tracks">
    <label class="font-b text-center" for="tracks">Select Track:</label>
    <select
      (ngModelChange)="filterTrack()"
      [(ngModel)]="currentPlan.selectedTrack"
      class="form-control"
      id="tracks"
      name="tracks">
      <ng-container *ngFor="let item of tracksByDateFiltered">
        <option [selected]="item === currentPlan.selectedTrack" [value]="item">
          {{ item }}
        </option>
      </ng-container>
    </select>
  </div>
  <div class="d-flex justify-content-between">
    <button (click)="datePickerClose()" class="btn btn-secondary btn-sm m-2">Close</button>
    <button (click)="purchaseWithDate(content)" class="btn btn-success btn-sm m-2">
      {{  currentPlan?.action && (currentPlan?.action !== 'None' && currentPlan?.action !== 'Signup') ? currentPlan.action : 'Purchase' }}
    </button>
  </div>
</ng-template>

<ngb-datepicker
#d="ngbDatepicker"
  [(ngModel)]="selectedDate"
  [footerTemplate]="footerTemplate"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [markDisabled]="isDisabled(date, minDate, maxDate)"
  (ngModelChange)="updateDate()"
  autoClose="false"
  class="date-picker"
  name="dp"
  id="dp-{{currentPlan.planCode}}"
></ngb-datepicker>
<div class="selected-product">
  <div class="product">
    <div class="title-box">
      <h4 [innerHTML]="name" class="font-b"></h4>
    </div>
    <div class="price font-m">${{ currentPlan?.amount }}</div>
    <ng-container *ngIf="currentPlan.points.includes('Tutorial')">
      <ng-container *ngIf="!disableLink">
        <ng-container *ngIf="(mobile$ | async) === false">
          <a class="pointer tutorial marginTop3" (click)="mdlService.showTutorialStarterModal()">
            <img src="/assets/icons/play.svg" alt="play">
            Take a sneak peek!
          </a>
        </ng-container>
        <ng-container *ngIf="(mobile$ | async) === true">
          <a class="pointer tutorial marginTop3"
             href="https://www.youtube-nocookie.com/embed/vroS_I7bXco?si=ZMD2N4bFbXx6LC-R" target="_blank">
            <img src="/assets/icons/play.svg" alt="play">
            Take a sneak peek!
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="disableLink">
        <a class="pointer tutorial marginTop3">
          <img src="/assets/icons/play.svg" alt="play">
          Take a sneak peek!
        </a>
      </ng-container>
    </ng-container>
    <div *ngIf="currentPlan?.planCode === ProductCodes.starterPass" style="min-height: 21px;padding: 10px;"></div>
    <div class="dates" *ngIf="currentPlan?.planCode !== ProductCodes.starterPass">
      <ng-container
        *ngIf="
          currentPlan?.planCode === ProductCodes.earlyBird
          || currentPlan?.planCode === ProductCodes.singleWeekend
          || currentPlan?.planCode === ProductCodes.specialWeekend
          || currentPlan?.planCode === ProductCodes.specialAllTrack
          || currentPlan?.planCode === ProductCodes.specialSingleTrack
          ||(hideButton && currentPlan.selectedDate)
        "
      >
        <label class="font-b">Available Dates:</label>
        <div>
          {{ currentPlan.selectedDate }}
        </div>
      </ng-container>
      <ng-container *ngIf="false &&
      currentPlan?.planCode === ProductCodes.singleTrack
      || currentPlan?.planCode === ProductCodes.singleDay">
        <!--
        Special message for special events
        * No Access to Churchill Downs 5/3/2024 - 5/4/2024
        -->
      </ng-container>
    </div>
    <div
      *ngIf="
        (currentPlan?.planCode === 'SDSTPASS'
          || currentPlan?.planCode === ProductCodes.singleTrack
          || currentPlan?.planCode === ProductCodes.specialSingleTrack)
          && currentPlan?.trackOptions
          && currentPlan?.selectedDate
          && hideButton
      "
      class="tracks"
    >
      <label class="font-b">Available Tracks:</label>
      <div>
        {{ currentPlan.selectedTrack }}
      </div>
    </div>
    <div
      *ngIf="!hideButton"
      class="purchase flex-grow-1 justify-content-center align-items-center"
      style=""
    >
      <div (click)="purchase(content)" [ngClass]="{ disabled: disableButton }" class="button font-m">
        <ng-container *ngIf="currentPlan?.planCode === ProductCodes.freeSignup">
          Signup
        </ng-container>
        <ng-container *ngIf="
        currentPlan?.planCode === ProductCodes.singleDay
        || currentPlan?.planCode === ProductCodes.singleTrack
        || currentPlan?.planCode === ProductCodes.starterPass
        || currentPlan?.planCode === ProductCodes.specialSingleTrack
        || currentPlan?.planCode === ProductCodes.specialAllTrack">
          Select Date
        </ng-container>
        <ng-container *ngIf="
        currentPlan?.planCode === ProductCodes.earlyBird
        || currentPlan?.planCode === ProductCodes.singleWeekend
        || currentPlan?.planCode === ProductCodes.specialWeekend">
          {{  currentPlan?.action && (currentPlan?.action !== 'None' && currentPlan?.action !== 'Signup') ? currentPlan.action : 'Purchase' }}
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="currentPlan?.points?.length">
      <div class="points">
        <div class="point-item font-m">Includes:</div>
        <div *ngFor="let item of currentPlan.points" class="point-item pl-2">
          <i class="fa fa-check"></i>{{ item }}
        </div>
        <div *ngIf="currentPlan?.planCode === ProductCodes.singleTrack
        || currentPlan?.planCode === ProductCodes.specialSingleTrack" class="point-item pl-2"><div class="mt-4"></div></div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #content let-modal>
  <div class="popup-blue">
    <div class="title">
      <h1 class="font-b">{{ title }}</h1>
    </div>
  </div>
  <div class="popup-second">
    <div class="f-s-20 font-m pt-2">
      <p [innerHTML]="text"></p>
      <div class="d-flex mb-2">
        <div class="flex-even text-left">
          <button class="btn font-m" (click)="submitModal(false)">No</button>
        </div>
        <div class="flex-even text-right">
          <button class="btn font-m" (click)="submitModal(true)">Yes</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
