<div class="popup-title" [ngClass]="{ 'mobile-popup': (mobile$ | async) }">
  <div class="close-container">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="start-access" *ngIf="reason === 'date'">You do not have a pass or a plan for that date</div>
  <div class="start-access" *ngIf="reason === 'track'">You do not have a pass or plan with access to that track</div>
  <div class="start-access" *ngIf="reason === 'race'">You do not have a pass or plan with access to that race</div>
  <div class="start-access" *ngIf="reason === 'dashboard'"><b> The Dashboard </b>is not available for your package</div>
  <div class="start-access" *ngIf="reason === 'handiview'"><b>The Handiview </b>is not available for your package</div>
  <div class="start-access" *ngIf="reason === 'handiview-plus'">
    <b>The Handiview Plus</b> is not available for your package
  </div>
  <div class="start-access" *ngIf="reason === 'notes'"><b>The Race Notes</b> are not available for your package</div>
  <div class="start-access" *ngIf="reason === 'pre-race-sor'">
    <b>The Race SoR </b>is not available for your package
  </div>
  <div class="start-access" *ngIf="reason === 'ticket-book'">
    <b>The TicketBook </b>is not available for your package
  </div>
  <div class="start-access" *ngIf="reason === 'trainers-edge'">
    <b>The Trainers Edge </b>is not available for your package
  </div>
  <div class="start-access" *ngIf="reason === 'morning-line'">
    <b>The Morning Line </b>is not available for your package
  </div>
  <div class="start-access" *ngIf="reason === 'gsr'">You do not have access to Gsr Plus</div>
</div>
<div class="popup-second" [ngClass]="{ 'mobile-popup': (mobile$ | async) }">
  <div class="mt-2">
    <p class="f-s-25 mb-2">Join now and get immediate access.</p>
  </div>
  <div *ngIf="reason === 'ticket-book'" class="help-link">
    <a href="https://equinedge.com/our-tech/" target="_blank">Click Here to learn more about the Ticketbook!</a>
  </div>
  <div *ngIf="reason === 'pre-race-sor'" class="help-link">
    <a href="https://equinedge.com/our-tech/" target="_blank">Click Here to learn more about the Race SoR!</a>
  </div>
  <div *ngIf="reason === 'notes'" class="help-link">
    <a href="https://equinedge.com/our-tech/" target="_blank">Click Here to learn more about the Race Notes!</a>
  </div>
  <div *ngIf="reason === 'handiview-plus'" class="help-link">
    <a href="https://equinedge.com/our-tech/" target="_blank">Click Here to learn more about the Handiview Plus!</a>
  </div>
  <div *ngIf="reason === 'handiview'" class="help-link">
    <a href="https://equinedge.com/our-tech/" target="_blank">Click Here to learn more about the Handiview!</a>
  </div>
  <div *ngIf="reason === 'dashboard'" class="help-link">
    <a href="https://equinedge.com/our-tech/" target="_blank">Click Here to learn more about the Dashboard!</a>
  </div>
  <div (click)="routeTo('/account-settings/subscription')">
    <app-plans-for-modal></app-plans-for-modal>
  </div>
  <div class="mt-3" *ngIf="currentSub?.redirectUrl">
    <p class="f-s-20 font-b">
      <b>{{ getFreeRaceName() }}</b>
    </p>
    <button
      class="btn btn-primary btn-access"
      (click)="routeTo(currentSub?.redirectUrl)"
      [ngClass]="{ 'f-s-14': (mobile$ | async) }">
      Access Today's Free Races
    </button>
  </div>
</div>
