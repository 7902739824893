/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Slot } from './slot';
import { TicketResult } from './ticketResult';

export interface PublicTicket { 
    id?: number;
    raceId?: number;
    savedTicketId?: number;
    generateTicketRequestId?: number;
    finalRaceTime?: string;
    exoticWagerType?: PublicTicket.ExoticWagerTypeEnum;
    exoticWagerName?: string;
    denomination?: number;
    slots?: Array<Slot>;
    ticketTotal?: number;
    needsRegenerate?: boolean;
    canDelete?: boolean;
    isPastRace?: boolean;
    isVertical?: boolean;
    isTicketWon?: boolean;
    isTicketSettled?: boolean;
    isTicketLost?: boolean;
    result?: TicketResult;
    postTime?: string;
}
export namespace PublicTicket {
    export type ExoticWagerTypeEnum = 'Horizontal' | 'Vertical' | 'Stronach5' | 'GoldenHourPick4' | 'GoldenHourDd' | 'CoastToCoast' | 'Win' | 'Place' | 'AllTurfPick3' | 'AllTurfPick4' | 'CrossCountryPick4' | 'FutureStarsPick5' | 'AllStakesPick5' | 'EddieDSantaAnitaSprintDouble' | 'CaliforniaCrownZenyattaDouble' | 'TwoDayAllStakesPick6' | 'TwoDayAllStakesPick4';
    export const ExoticWagerTypeEnum = {
        Horizontal: 'Horizontal' as ExoticWagerTypeEnum,
        Vertical: 'Vertical' as ExoticWagerTypeEnum,
        Stronach5: 'Stronach5' as ExoticWagerTypeEnum,
        GoldenHourPick4: 'GoldenHourPick4' as ExoticWagerTypeEnum,
        GoldenHourDd: 'GoldenHourDd' as ExoticWagerTypeEnum,
        CoastToCoast: 'CoastToCoast' as ExoticWagerTypeEnum,
        Win: 'Win' as ExoticWagerTypeEnum,
        Place: 'Place' as ExoticWagerTypeEnum,
        AllTurfPick3: 'AllTurfPick3' as ExoticWagerTypeEnum,
        AllTurfPick4: 'AllTurfPick4' as ExoticWagerTypeEnum,
        CrossCountryPick4: 'CrossCountryPick4' as ExoticWagerTypeEnum,
        FutureStarsPick5: 'FutureStarsPick5' as ExoticWagerTypeEnum,
        AllStakesPick5: 'AllStakesPick5' as ExoticWagerTypeEnum,
        EddieDSantaAnitaSprintDouble: 'EddieDSantaAnitaSprintDouble' as ExoticWagerTypeEnum,
        CaliforniaCrownZenyattaDouble: 'CaliforniaCrownZenyattaDouble' as ExoticWagerTypeEnum,
        TwoDayAllStakesPick6: 'TwoDayAllStakesPick6' as ExoticWagerTypeEnum,
        TwoDayAllStakesPick4: 'TwoDayAllStakesPick4' as ExoticWagerTypeEnum
    };
}