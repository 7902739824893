/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GenerateTicketsCommand } from '../model/generateTicketsCommand';
import { ProbableTable } from '../model/probableTable';
import { PublicCompactRace } from '../model/publicCompactRace';
import { PublicRaceHandicapping } from '../model/publicRaceHandicapping';
import { PublicTicket } from '../model/publicTicket';
import { RaceDetails } from '../model/raceDetails';
import { RaceHasResultDto } from '../model/raceHasResultDto';
import { RaceMorningLineDto } from '../model/raceMorningLineDto';
import { RaceReplayQuery } from '../model/raceReplayQuery';
import { SpecialWagerDto } from '../model/specialWagerDto';
import { SpecialWagerQuery } from '../model/specialWagerQuery';
import { StronachFiveDto } from '../model/stronachFiveDto';
import { StronachFiveQuery } from '../model/stronachFiveQuery';
import { TrackSearchDto } from '../model/trackSearchDto';
import { UpdateHorseNoteCommand } from '../model/updateHorseNoteCommand';
import { WillpayDto } from '../model/willpayDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class RacesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesAllGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCompactRace>>;
    public apiRacesAllGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCompactRace>>>;
    public apiRacesAllGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCompactRace>>>;
    public apiRacesAllGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PublicCompactRace>>('get',`${this.basePath}/api/Races/All`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesFutureDatesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public apiRacesFutureDatesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public apiRacesFutureDatesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public apiRacesFutureDatesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/Races/FutureDates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesGenerateTicketsPost(body?: GenerateTicketsCommand, observe?: 'body', reportProgress?: boolean): Observable<PublicTicket>;
    public apiRacesGenerateTicketsPost(body?: GenerateTicketsCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublicTicket>>;
    public apiRacesGenerateTicketsPost(body?: GenerateTicketsCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublicTicket>>;
    public apiRacesGenerateTicketsPost(body?: GenerateTicketsCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PublicTicket>('post',`${this.basePath}/api/Races/GenerateTickets`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesHandicappingPost(body?: PublicRaceHandicapping, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiRacesHandicappingPost(body?: PublicRaceHandicapping, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiRacesHandicappingPost(body?: PublicRaceHandicapping, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiRacesHandicappingPost(body?: PublicRaceHandicapping, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Races/Handicapping`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesHorseNotesPost(body?: UpdateHorseNoteCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiRacesHorseNotesPost(body?: UpdateHorseNoteCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiRacesHorseNotesPost(body?: UpdateHorseNoteCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiRacesHorseNotesPost(body?: UpdateHorseNoteCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Races/HorseNotes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param userId 
     * @param version 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesIdGet(id: number, userId?: number, version?: number, observe?: 'body', reportProgress?: boolean): Observable<RaceDetails>;
    public apiRacesIdGet(id: number, userId?: number, version?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceDetails>>;
    public apiRacesIdGet(id: number, userId?: number, version?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceDetails>>;
    public apiRacesIdGet(id: number, userId?: number, version?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiRacesIdGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RaceDetails>('get',`${this.basePath}/api/Races/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param TrackId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesMorningLineDatesGet(TrackId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public apiRacesMorningLineDatesGet(TrackId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public apiRacesMorningLineDatesGet(TrackId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public apiRacesMorningLineDatesGet(TrackId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (TrackId !== undefined && TrackId !== null) {
            queryParameters = queryParameters.set('TrackId', <any>TrackId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/Races/MorningLineDates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesMorningLineTracksGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TrackSearchDto>>;
    public apiRacesMorningLineTracksGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrackSearchDto>>>;
    public apiRacesMorningLineTracksGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrackSearchDto>>>;
    public apiRacesMorningLineTracksGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TrackSearchDto>>('get',`${this.basePath}/api/Races/MorningLineTracks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param RaceId 
     * @param TrackId 
     * @param GeneratedTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesMorningLinesGet(RaceId?: number, TrackId?: number, GeneratedTime?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RaceMorningLineDto>>;
    public apiRacesMorningLinesGet(RaceId?: number, TrackId?: number, GeneratedTime?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RaceMorningLineDto>>>;
    public apiRacesMorningLinesGet(RaceId?: number, TrackId?: number, GeneratedTime?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RaceMorningLineDto>>>;
    public apiRacesMorningLinesGet(RaceId?: number, TrackId?: number, GeneratedTime?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (RaceId !== undefined && RaceId !== null) {
            queryParameters = queryParameters.set('RaceId', <any>RaceId);
        }
        if (TrackId !== undefined && TrackId !== null) {
            queryParameters = queryParameters.set('TrackId', <any>TrackId);
        }
        if (GeneratedTime !== undefined && GeneratedTime !== null) {
            queryParameters = queryParameters.set('GeneratedTime', <any>GeneratedTime);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RaceMorningLineDto>>('get',`${this.basePath}/api/Races/MorningLines`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesProbablesGet(raceId?: number, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: ProbableTable; }>;
    public apiRacesProbablesGet(raceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: ProbableTable; }>>;
    public apiRacesProbablesGet(raceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: ProbableTable; }>>;
    public apiRacesProbablesGet(raceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceId !== undefined && raceId !== null) {
            queryParameters = queryParameters.set('raceId', <any>raceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<{ [key: string]: ProbableTable; }>('get',`${this.basePath}/api/Races/probables`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesRaceReplayPost(body?: RaceReplayQuery, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiRacesRaceReplayPost(body?: RaceReplayQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiRacesRaceReplayPost(body?: RaceReplayQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiRacesRaceReplayPost(body?: RaceReplayQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/Races/raceReplay`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesRaceResultsGet(raceIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<RaceHasResultDto>>;
    public apiRacesRaceResultsGet(raceIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RaceHasResultDto>>>;
    public apiRacesRaceResultsGet(raceIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RaceHasResultDto>>>;
    public apiRacesRaceResultsGet(raceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceIds) {
            raceIds.forEach((element) => {
                queryParameters = queryParameters.append('raceIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RaceHasResultDto>>('get',`${this.basePath}/api/Races/RaceResults`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesSpecialWagersPost(body?: SpecialWagerQuery, observe?: 'body', reportProgress?: boolean): Observable<Array<SpecialWagerDto>>;
    public apiRacesSpecialWagersPost(body?: SpecialWagerQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SpecialWagerDto>>>;
    public apiRacesSpecialWagersPost(body?: SpecialWagerQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SpecialWagerDto>>>;
    public apiRacesSpecialWagersPost(body?: SpecialWagerQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SpecialWagerDto>>('post',`${this.basePath}/api/Races/SpecialWagers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesStronachFivePost(body?: StronachFiveQuery, observe?: 'body', reportProgress?: boolean): Observable<Array<StronachFiveDto>>;
    public apiRacesStronachFivePost(body?: StronachFiveQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StronachFiveDto>>>;
    public apiRacesStronachFivePost(body?: StronachFiveQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StronachFiveDto>>>;
    public apiRacesStronachFivePost(body?: StronachFiveQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<StronachFiveDto>>('post',`${this.basePath}/api/Races/StronachFive`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRacesWillpayGet(raceId?: number, observe?: 'body', reportProgress?: boolean): Observable<WillpayDto>;
    public apiRacesWillpayGet(raceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WillpayDto>>;
    public apiRacesWillpayGet(raceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WillpayDto>>;
    public apiRacesWillpayGet(raceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceId !== undefined && raceId !== null) {
            queryParameters = queryParameters.set('raceId', <any>raceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WillpayDto>('get',`${this.basePath}/api/Races/willpay`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
