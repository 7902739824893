/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ContestantScratchedDto } from '../model/contestantScratchedDto';
import { LiveOddsDto } from '../model/liveOddsDto';
import { PoolsDto } from '../model/poolsDto';
import { PostTimeDto } from '../model/postTimeDto';
import { ProbablesDto } from '../model/probablesDto';
import { PublicCompactRace } from '../model/publicCompactRace';
import { RaceCanceledDto } from '../model/raceCanceledDto';
import { RaceStatusDto } from '../model/raceStatusDto';
import { Response4 } from '../model/response4';
import { UpcomingRacesQuery } from '../model/upcomingRacesQuery';
import { WillpayDto1 } from '../model/willpayDto1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class RealTimeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param raceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollAllGet(raceIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Response4>;
    public apiPollAllGet(raceIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response4>>;
    public apiPollAllGet(raceIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response4>>;
    public apiPollAllGet(raceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceIds) {
            raceIds.forEach((element) => {
                queryParameters = queryParameters.append('raceIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Response4>('get',`${this.basePath}/api/poll/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollCanceledIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<RaceCanceledDto>;
    public apiPollCanceledIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceCanceledDto>>;
    public apiPollCanceledIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceCanceledDto>>;
    public apiPollCanceledIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPollCanceledIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RaceCanceledDto>('get',`${this.basePath}/api/poll/canceled/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollInprogressRacesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCompactRace>>;
    public apiPollInprogressRacesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCompactRace>>>;
    public apiPollInprogressRacesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCompactRace>>>;
    public apiPollInprogressRacesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PublicCompactRace>>('get',`${this.basePath}/api/poll/inprogress-races`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollInprogressRacesKeeperGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCompactRace>>;
    public apiPollInprogressRacesKeeperGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCompactRace>>>;
    public apiPollInprogressRacesKeeperGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCompactRace>>>;
    public apiPollInprogressRacesKeeperGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PublicCompactRace>>('get',`${this.basePath}/api/poll/inprogress-races-keeper`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollLiveoddsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<LiveOddsDto>;
    public apiPollLiveoddsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LiveOddsDto>>;
    public apiPollLiveoddsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LiveOddsDto>>;
    public apiPollLiveoddsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPollLiveoddsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LiveOddsDto>('get',`${this.basePath}/api/poll/liveodds/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollPoolsGet(raceIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<PoolsDto>;
    public apiPollPoolsGet(raceIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PoolsDto>>;
    public apiPollPoolsGet(raceIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PoolsDto>>;
    public apiPollPoolsGet(raceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceIds) {
            raceIds.forEach((element) => {
                queryParameters = queryParameters.append('raceIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PoolsDto>('get',`${this.basePath}/api/poll/pools`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatedAt 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollPosttimesUpdatedAtGet(updatedAt?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PostTimeDto>>;
    public apiPollPosttimesUpdatedAtGet(updatedAt?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PostTimeDto>>>;
    public apiPollPosttimesUpdatedAtGet(updatedAt?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PostTimeDto>>>;
    public apiPollPosttimesUpdatedAtGet(updatedAt?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updatedAt !== undefined && updatedAt !== null) {
            queryParameters = queryParameters.set('updatedAt', <any>updatedAt);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PostTimeDto>>('get',`${this.basePath}/api/poll/posttimes/${encodeURIComponent(String(updatedAt))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollProbablesGet(raceIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<ProbablesDto>;
    public apiPollProbablesGet(raceIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProbablesDto>>;
    public apiPollProbablesGet(raceIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProbablesDto>>;
    public apiPollProbablesGet(raceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceIds) {
            raceIds.forEach((element) => {
                queryParameters = queryParameters.append('raceIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProbablesDto>('get',`${this.basePath}/api/poll/probables`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollScratchesIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<ContestantScratchedDto>;
    public apiPollScratchesIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContestantScratchedDto>>;
    public apiPollScratchesIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContestantScratchedDto>>;
    public apiPollScratchesIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPollScratchesIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContestantScratchedDto>('get',`${this.basePath}/api/poll/scratches/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollStatusRaceIdGet(raceId: number, observe?: 'body', reportProgress?: boolean): Observable<RaceStatusDto>;
    public apiPollStatusRaceIdGet(raceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceStatusDto>>;
    public apiPollStatusRaceIdGet(raceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceStatusDto>>;
    public apiPollStatusRaceIdGet(raceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (raceId === null || raceId === undefined) {
            throw new Error('Required parameter raceId was null or undefined when calling apiPollStatusRaceIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RaceStatusDto>('get',`${this.basePath}/api/poll/status/${encodeURIComponent(String(raceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatedAt 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollStatusUpdatedAtGet(updatedAt?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RaceStatusDto>>;
    public apiPollStatusUpdatedAtGet(updatedAt?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RaceStatusDto>>>;
    public apiPollStatusUpdatedAtGet(updatedAt?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RaceStatusDto>>>;
    public apiPollStatusUpdatedAtGet(updatedAt?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updatedAt !== undefined && updatedAt !== null) {
            queryParameters = queryParameters.set('updatedAt', <any>updatedAt);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RaceStatusDto>>('get',`${this.basePath}/api/poll/status/${encodeURIComponent(String(updatedAt))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollUpcomingracesGet(raceIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCompactRace>>;
    public apiPollUpcomingracesGet(raceIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCompactRace>>>;
    public apiPollUpcomingracesGet(raceIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCompactRace>>>;
    public apiPollUpcomingracesGet(raceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceIds) {
            raceIds.forEach((element) => {
                queryParameters = queryParameters.append('raceIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PublicCompactRace>>('get',`${this.basePath}/api/poll/upcomingraces`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPollWillpaysGet(raceIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<WillpayDto1>>;
    public apiPollWillpaysGet(raceIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WillpayDto1>>>;
    public apiPollWillpaysGet(raceIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WillpayDto1>>>;
    public apiPollWillpaysGet(raceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceIds) {
            raceIds.forEach((element) => {
                queryParameters = queryParameters.append('raceIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WillpayDto1>>('get',`${this.basePath}/api/poll/willpays`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2PollUpcomingRacesPost(body?: UpcomingRacesQuery, observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCompactRace>>;
    public apiV2PollUpcomingRacesPost(body?: UpcomingRacesQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCompactRace>>>;
    public apiV2PollUpcomingRacesPost(body?: UpcomingRacesQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCompactRace>>>;
    public apiV2PollUpcomingRacesPost(body?: UpcomingRacesQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PublicCompactRace>>('post',`${this.basePath}/api/v2/poll/upcoming-races`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
