<div class="logo-white"></div>
<section class="login responsive-width">
  <div class="form-section" *ngIf="resetPasswordForm">
    <form [formGroup]="resetPasswordForm" (keydown.enter)="validateForm()" (ngSubmit)="validateForm()">
      <div class="form-row">
        <div class="img-login"></div>
      </div>
      <div class="form-row">
        <div class="col">
          <h4>Please enter your new password here</h4>
        </div>
      </div>
      <div class="form-row" style="min-height: 80px">
        <div class="col">
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            name="password"
            formControlName="password"
            [ngClass]="{
              'is-valid': isValid('password'),
              'is-invalid': isInValid('password')
            }"
          />
          <div class="invalid-feedback">
            <ng-container *ngIf="resetPasswordForm.controls['password']?.errors?.customValidator">
              {{ resetPasswordForm.controls['password']?.errors['customValidator'] }}
            </ng-container>
            <ng-container *ngIf="!resetPasswordForm.controls['password']?.errors?.customValidator">
              The password must be at least 8 characters long.
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col text-center">
          <button type="submit" [disabled]="resetPasswordForm.invalid" class="btn m-auto btn-primary btn-login">
            Set new password
          </button>
        </div>
      </div>
      <div class="form-row mt-2">
        <a class="link m-auto" routerLink="/login">Login</a>
      </div>
      <div class="form-row mt-2">
        <a class="link m-auto" routerLink="/signup">Signup</a>
      </div>
    </form>
  </div>
</section>
