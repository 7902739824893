import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanSummaryDto, PromoCodeDetailDto, CurrentSubscriptionStatusResponse } from '@swagger-codegen/*';
import _ from 'lodash';
import { ModalService } from "../../authenticated-module/services/modal.service";
import { AppSandboxService } from "@services/sandbox.service";
import {ProductCodes} from "../models/product-codes.model";

@Component({
  selector: 'app-plan-memberships',
  templateUrl: './plan-memberships.component.html',
  styleUrls: ['./plan-memberships.component.scss'],
})
export class PlanMembershipsComponent implements OnInit {
  @Input()
  plan: PlanSummaryDto;
  @Input()
  hideButton = false;
  @Input()
  smallSize = false;
  @Input()
  current: CurrentSubscriptionStatusResponse;
  @Input()
  pending: CurrentSubscriptionStatusResponse;
  @Input()
  disableLink = false;
  @Input()
  set promoCode(v: PromoCodeDetailDto) {
    this._promoCode = v;
    if (this._promoCode && this._promoCode.plans.indexOf(this.plan.planCode) !== -1) {
      this.currentPlan = _.filter(this._promoCode.plansSummary, (x) => x.planCode === this.plan.planCode).pop();
    } else {
      this.currentPlan = this.plan;
    }
  }

  mobile$ = this.sb.mobile$;
  currentPlan: PlanSummaryDto;
  _promoCode: PromoCodeDetailDto = null;
  @Output()
  submit: EventEmitter<PlanSummaryDto> = new EventEmitter<PlanSummaryDto>();

  constructor(public modalService: ModalService, public sb: AppSandboxService) {
  }

  get pricePeriod() {
    return this.plan?.amount < 500 ? '/mo.' : '/yr.';
  }

  get name() {
    if (this.plan?.planCode === '129-USD-Weekly' || this.plan?.planCode === 'FreeSignup-USD-Daily') {
      return 'Free Signup'
    }
    if (this._promoCode != null && this._promoCode.plans.indexOf(this.plan.planCode)!== -1) {
      return this._promoCode.name;
    }
    return _.chain(this.getNameFromCode(this.currentPlan?.planCode, this.currentPlan?.name)).toUpper().value();
  }

  getNameFromCode(code: string, name: string): string {
    // need to change this for each special plan
    const nameParts = name.split(' ');
    const newName = nameParts.map((part, index) => {
      return index === nameParts.length - 1 ? '<br/>' + part : part;
    }).join(' ');
    return {
      [ProductCodes.specialSingleTrack]: 'Preakness Single Day Single Track',
      [ProductCodes.specialAllTrack]: 'Preakness Single Day All Tracks',
      [ProductCodes.specialWeekend]: 'Preakness Single Weekend',
    }[code] ?? newName;
  }

  ngOnInit(): void {
    if (!this.currentPlan) {
      this.currentPlan = this.plan;
    }
  }

  purchase() {
    if (this.currentPlan?.action !== 'None' && this.currentPlan?.action !== 'Active') {
      this.submit.emit(this.currentPlan);
    }
  }

  get disableButton() {
    return (
      (this._promoCode && this._promoCode.plans.indexOf(this.plan.planCode) === -1) ||
      this.plan.action === 'None' ||
      this.plan.action === 'Active'
    );
  }

  removeModal(): void {
    this.modalService.destroy();
  }
}
