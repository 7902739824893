<ng-template #emptyAnswer>-</ng-template>
<ng-template #footerTemplate>
  <hr class="my-0"/>
  <div class="d-flex justify-content-end">
    <button (click)="checkDate()" class="btn btn-success btn-sm m-2">
      Select date
    </button>
  </div>
</ng-template>

<div class="close-block">
  <u (click)="closeModal()">close</u>
</div>
<div class="d-flex modal-jockey modal-lg">

  <div [attr.class]="'image-block p' + backgroundId"></div>
  <div class="gridTitleContainer flex-fill px-2">
    <input
      #d="ngbDatepicker"
      [(ngModel)]="selectedDate"
      autoClose="false"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [footerTemplate]="footerTemplate"
      class="calendar"
      name="dp"
      ngbDatepicker
    />

    <div>
      <div class="font-b text-center">Horses To Watch <span class="link"
                                                            (click)="openCalendar()">{{ currentDateForTitle }}</span>
      </div>
      <ng-container *ngIf="horsesToWatchResults?.statistics&&horsesToWatchResults?.statistics[currentDate]">
        <table class="table">
          <thead>
          <tr>
            <th class="text-center">1st</th>
            <th class="text-center">2nd</th>
            <th class="text-center">3rd</th>
            <th class="text-center">Win %</th>
            <th class="text-center">ITM %</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <ng-container *ngIf="horsesToWatchResults.statistics[currentDate]?.first !== null; else emptyAnswer">
                {{ horsesToWatchResults.statistics[currentDate]?.first }}
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="horsesToWatchResults.statistics[currentDate]?.second !== null; else emptyAnswer">
                {{ horsesToWatchResults.statistics[currentDate]?.second }}
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="horsesToWatchResults.statistics[currentDate]?.third !== null; else emptyAnswer">
                {{ horsesToWatchResults.statistics[currentDate]?.third }}
              </ng-container>
            </td>
            <td>
              <ng-container
                *ngIf="horsesToWatchResults.statistics[currentDate]?.winPercentage !== null; else emptyAnswer">
                {{ horsesToWatchResults.statistics[currentDate]?.winPercentage  | round }}
              </ng-container>
            </td>
            <td>
              <ng-container
                *ngIf="horsesToWatchResults.statistics[currentDate]?.intTheMoneyPercentage !== null; else emptyAnswer">
                {{ horsesToWatchResults.statistics[currentDate]?.intTheMoneyPercentage   | round }}
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
    <div class="position-relative" *ngIf="horsesToWatch?.horsesToWatch?.length">
      <div class="px-2 resetPages p-absolute" *ngIf="horsesToWatch.horsesToWatch.length>5">
        <app-small-pages
          (pageChange)="pageChange($event)"
          [listLength]="horsesToWatch.horsesToWatch.length"
          [resetPages]="resetPages"
          class="dark-pages"
        ></app-small-pages>
      </div>
      <table class="table" cellspacing="0" sortable-table (sorted)="onSorted($event)"
             sorted-component="modal-horses-to-watch">
        <thead class="thead-type1">
        <tr>
          <th class="cloth pointer">#</th>
          <th class="pointer">
            <div
              [sort-direction]="checkSorting('horseName')"
              sortable-column="horseName"
              sorted-component="modal-horses-to-watch"
            ></div>
            Horse
          </th>
          <th class="tabth pointer">
            <div
              [sort-direction]="checkSorting('winPercentage')"
              sortable-column="winPercentage"
              sorted-component="modal-horses-to-watch"
            ></div>
            Win
          </th>
          <th class="tabth pointer liveOddsTh px-0">
            <div
              [sort-direction]="checkSorting('morningLineDisplay')"
              sortable-column="morningLineDisplay"
              sorted-component="modal-horses-to-watch"
              sort-way="false"
            ></div>
            Live
          </th>
          <th class="timeToPostCol pointer tabth mtp">
            <div [sort-direction]="checkSorting('postTime')" sortable-column="postTime"
                 sorted-component="modal-horses-to-watch"></div>
            MTP
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="!horsesToWatch?.horsesToWatch?.length">
          <tr class="no-data-1">
            <td colspan="5">There is currently no data.</td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let chalk of horsesToWatch?.horsesToWatch; let i = index">
          <tr (click)="navigateToRace(chalk)">
            <td class="w12">
              <div *ngIf="chalk.raceId" class="numberBox program-{{ chalk.bettingNumber }}">
                {{ chalk.programNumber }}
              </div>
            </td>
            <td class="text-left pl-2">
              <div *ngIf="chalk.raceId" class="textBox">
          <span class="block font-m f-s-17">
            {{ applySlice(chalk.horseName) }}
          </span>
                <span *ngIf="chalk.raceId" class="normalText block">{{ chalk.track }} R{{ chalk.raceNumber }}</span>
              </div>
            </td>
            <td class="winChance w15 f-s-18">
              <span *ngIf="chalk.raceId">{{ chalk.winPercentage }}%</span>
            </td>
            <td class="w15 px-1">
        <span
          (@flip.done)="resetAnimationState(chalk?.bettingNumber)"
          *ngIf="chalk.raceId"
          [@flip]="animationState[chalk?.bettingNumber]"
          [ngClass]="{
            'badge-warning': chalk.isFavorite === true,
            'badge-secondary': chalk.isFavorite !== true
          }"
          class="badge">{{ chalk.liveOdds ? chalk.liveOdds : chalk.morningLineDisplay }}</span>
            </td>
            <td class="mtp w20 text-center">
              <app-race-minutes-to-post
                *ngIf="chalk.raceId"
                [hideAfter60m]="true"
                [raceId]="chalk.raceId"
              ></app-race-minutes-to-post>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="horsesToWatchResults?.horsesToWatchResults&&horsesToWatchResults?.horsesToWatchResults[currentDate]" class="result-block">
      <div class="font-b text-center">Results</div>
      <table class="table">
        <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">HorseName</th>
          <th class="text-center">W/P/S</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let chalk of horsesToWatchResults?.horsesToWatchResults[currentDate]; let i = index">
          <td class="w12">
            <div class="numberBox program-{{ chalk.bettingNumber }}">
              {{ chalk.programNumber }}
            </div>
          </td>
          <td class="text-left font-m pl-2">
            {{ applySlice(chalk.horseName) }}
          </td>
          <td>
            {{ chalk.wps }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
