/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GenerateHypotheticalGsrGridResponse } from '../model/generateHypotheticalGsrGridResponse';
import { GeneratePredictedProgenyDto } from '../model/generatePredictedProgenyDto';
import { GenerateProgenyCommand } from '../model/generateProgenyCommand';
import { GsrGrid } from '../model/gsrGrid';
import { GsrGridResponse } from '../model/gsrGridResponse';
import { GsrPlusDto } from '../model/gsrPlusDto';
import { GsrPlusSearchDto } from '../model/gsrPlusSearchDto';
import { HorseByTrainerDto } from '../model/horseByTrainerDto';
import { HorseProfileDto } from '../model/horseProfileDto';
import { LifetimeStatsDto } from '../model/lifetimeStatsDto';
import { ProgenyNonRacingDamQuery } from '../model/progenyNonRacingDamQuery';
import { ProgenySearchQuery } from '../model/progenySearchQuery';
import { Response3 } from '../model/response3';
import { SireDamPpsDto } from '../model/sireDamPpsDto';
import { TrainerDto } from '../model/trainerDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class HorseService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseGradedStakesGridGet(observe?: 'body', reportProgress?: boolean): Observable<Response3>;
    public apiHorseGradedStakesGridGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response3>>;
    public apiHorseGradedStakesGridGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response3>>;
    public apiHorseGradedStakesGridGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Response3>('get',`${this.basePath}/api/horse/gradedStakesGrid`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param RaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseGsrPlusByRaceGet(RaceId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GsrPlusDto>>;
    public apiHorseGsrPlusByRaceGet(RaceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GsrPlusDto>>>;
    public apiHorseGsrPlusByRaceGet(RaceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GsrPlusDto>>>;
    public apiHorseGsrPlusByRaceGet(RaceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (RaceId !== undefined && RaceId !== null) {
            queryParameters = queryParameters.set('RaceId', <any>RaceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GsrPlusDto>>('get',`${this.basePath}/api/horse/GsrPlusByRace`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param HorseId 
     * @param RaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseGsrPlusGet(HorseId?: number, RaceId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GsrGrid>>;
    public apiHorseGsrPlusGet(HorseId?: number, RaceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GsrGrid>>>;
    public apiHorseGsrPlusGet(HorseId?: number, RaceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GsrGrid>>>;
    public apiHorseGsrPlusGet(HorseId?: number, RaceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (HorseId !== undefined && HorseId !== null) {
            queryParameters = queryParameters.set('HorseId', <any>HorseId);
        }
        if (RaceId !== undefined && RaceId !== null) {
            queryParameters = queryParameters.set('RaceId', <any>RaceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GsrGrid>>('get',`${this.basePath}/api/horse/GsrPlus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param HorseName 
     * @param IsSire 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseGsrPlusHorseSearchGet(HorseName?: string, IsSire?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<GsrPlusSearchDto>>;
    public apiHorseGsrPlusHorseSearchGet(HorseName?: string, IsSire?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GsrPlusSearchDto>>>;
    public apiHorseGsrPlusHorseSearchGet(HorseName?: string, IsSire?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GsrPlusSearchDto>>>;
    public apiHorseGsrPlusHorseSearchGet(HorseName?: string, IsSire?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (HorseName !== undefined && HorseName !== null) {
            queryParameters = queryParameters.set('HorseName', <any>HorseName);
        }
        if (IsSire !== undefined && IsSire !== null) {
            queryParameters = queryParameters.set('IsSire', <any>IsSire);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GsrPlusSearchDto>>('get',`${this.basePath}/api/horse/GsrPlusHorseSearch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param HorseId 
     * @param Surface 
     * @param Distance 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseLifetimeStatsBySurfaceDistanceGet(HorseId?: number, Surface?: string, Distance?: string, observe?: 'body', reportProgress?: boolean): Observable<LifetimeStatsDto>;
    public apiHorseLifetimeStatsBySurfaceDistanceGet(HorseId?: number, Surface?: string, Distance?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LifetimeStatsDto>>;
    public apiHorseLifetimeStatsBySurfaceDistanceGet(HorseId?: number, Surface?: string, Distance?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LifetimeStatsDto>>;
    public apiHorseLifetimeStatsBySurfaceDistanceGet(HorseId?: number, Surface?: string, Distance?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (HorseId !== undefined && HorseId !== null) {
            queryParameters = queryParameters.set('HorseId', <any>HorseId);
        }
        if (Surface !== undefined && Surface !== null) {
            queryParameters = queryParameters.set('Surface', <any>Surface);
        }
        if (Distance !== undefined && Distance !== null) {
            queryParameters = queryParameters.set('Distance', <any>Distance);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LifetimeStatsDto>('get',`${this.basePath}/api/horse/LifetimeStatsBySurfaceDistance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param horseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseProfileGet(horseId?: number, observe?: 'body', reportProgress?: boolean): Observable<HorseProfileDto>;
    public apiHorseProfileGet(horseId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorseProfileDto>>;
    public apiHorseProfileGet(horseId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorseProfileDto>>;
    public apiHorseProfileGet(horseId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (horseId !== undefined && horseId !== null) {
            queryParameters = queryParameters.set('horseId', <any>horseId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HorseProfileDto>('get',`${this.basePath}/api/horse/profile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseProgenySearchPost(body?: ProgenySearchQuery, observe?: 'body', reportProgress?: boolean): Observable<GenerateHypotheticalGsrGridResponse>;
    public apiHorseProgenySearchPost(body?: ProgenySearchQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenerateHypotheticalGsrGridResponse>>;
    public apiHorseProgenySearchPost(body?: ProgenySearchQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenerateHypotheticalGsrGridResponse>>;
    public apiHorseProgenySearchPost(body?: ProgenySearchQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenerateHypotheticalGsrGridResponse>('post',`${this.basePath}/api/horse/ProgenySearch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param TrainerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseSearchHorsesGet(TrainerId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<HorseByTrainerDto>>;
    public apiHorseSearchHorsesGet(TrainerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorseByTrainerDto>>>;
    public apiHorseSearchHorsesGet(TrainerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorseByTrainerDto>>>;
    public apiHorseSearchHorsesGet(TrainerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (TrainerId !== undefined && TrainerId !== null) {
            queryParameters = queryParameters.set('TrainerId', <any>TrainerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HorseByTrainerDto>>('get',`${this.basePath}/api/horse/searchHorses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseSearchTrainerGet(Name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TrainerDto>>;
    public apiHorseSearchTrainerGet(Name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrainerDto>>>;
    public apiHorseSearchTrainerGet(Name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrainerDto>>>;
    public apiHorseSearchTrainerGet(Name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Name !== undefined && Name !== null) {
            queryParameters = queryParameters.set('Name', <any>Name);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TrainerDto>>('get',`${this.basePath}/api/horse/searchTrainer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceId 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseSiredamppsGet(raceId?: number, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<SireDamPpsDto>;
    public apiHorseSiredamppsGet(raceId?: number, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SireDamPpsDto>>;
    public apiHorseSiredamppsGet(raceId?: number, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SireDamPpsDto>>;
    public apiHorseSiredamppsGet(raceId?: number, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceId !== undefined && raceId !== null) {
            queryParameters = queryParameters.set('raceId', <any>raceId);
        }
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SireDamPpsDto>('get',`${this.basePath}/api/horse/siredampps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseV2NonRacingDamProgenyPost(body?: ProgenyNonRacingDamQuery, observe?: 'body', reportProgress?: boolean): Observable<GsrGridResponse>;
    public apiHorseV2NonRacingDamProgenyPost(body?: ProgenyNonRacingDamQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GsrGridResponse>>;
    public apiHorseV2NonRacingDamProgenyPost(body?: ProgenyNonRacingDamQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GsrGridResponse>>;
    public apiHorseV2NonRacingDamProgenyPost(body?: ProgenyNonRacingDamQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GsrGridResponse>('post',`${this.basePath}/api/horse/v2/NonRacingDamProgeny`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseV2ProgenySearchPost(body?: ProgenySearchQuery, observe?: 'body', reportProgress?: boolean): Observable<GsrGridResponse>;
    public apiHorseV2ProgenySearchPost(body?: ProgenySearchQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GsrGridResponse>>;
    public apiHorseV2ProgenySearchPost(body?: ProgenySearchQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GsrGridResponse>>;
    public apiHorseV2ProgenySearchPost(body?: ProgenySearchQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GsrGridResponse>('post',`${this.basePath}/api/horse/v2/ProgenySearch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseV3BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe?: 'body', reportProgress?: boolean): Observable<Array<GsrGridResponse>>;
    public apiHorseV3BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GsrGridResponse>>>;
    public apiHorseV3BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GsrGridResponse>>>;
    public apiHorseV3BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<GsrGridResponse>>('post',`${this.basePath}/api/horse/v3/BatchGenerateGsrGrid`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHorseV3ProgenySearchPost(body?: GenerateProgenyCommand, observe?: 'body', reportProgress?: boolean): Observable<GeneratePredictedProgenyDto>;
    public apiHorseV3ProgenySearchPost(body?: GenerateProgenyCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneratePredictedProgenyDto>>;
    public apiHorseV3ProgenySearchPost(body?: GenerateProgenyCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneratePredictedProgenyDto>>;
    public apiHorseV3ProgenySearchPost(body?: GenerateProgenyCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GeneratePredictedProgenyDto>('post',`${this.basePath}/api/horse/v3/ProgenySearch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe?: 'body', reportProgress?: boolean): Observable<Array<GsrGridResponse>>;
    public apiV2BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GsrGridResponse>>>;
    public apiV2BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GsrGridResponse>>>;
    public apiV2BatchGenerateGsrGridPost(body?: Array<ProgenySearchQuery>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<GsrGridResponse>>('post',`${this.basePath}/api/v2/BatchGenerateGsrGrid`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
