import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {AuthorizationDto, CheckoutExistingCustomerCommand, CustomerService} from "@swagger-codegen/*";
import {TakeUntilDestroy} from "@services/take-until-destroy.decorator";
import {finalize, takeUntil} from "rxjs/operators";
import {ToastService} from "@services/toast.service";
import {AppSandboxService} from "@services/sandbox.service";
import {DataCache} from "../../services/data-cache.service";
import {Router} from "@angular/router";
import {BlockUiService} from "@services/block-ui.service";
import {environment} from "../../../../../environments/environment";
import {getDeviceId} from "@services/functions";

/**
 * when a user is a free user, then we will show this upsell on the ticket generator
 * to allow them to get access to a single day single track pass.
 */
@TakeUntilDestroy
@Component({
  selector: 'app-modal-tg-upsell',
  templateUrl: './modal-tg-upsell.component.html',
  styleUrls: ['./modal-tg-upsell.component.scss']
})
export class ModalTgUpsellComponent implements OnInit {
  @Input()
  reason:
    | 'date'
    | 'morning-line'
    | 'trainers-edge'
    | 'track'
    | 'race'
    | 'handiview'
    | 'handiview-plus'
    | 'notes'
    | 'pre-race-sor'
    | 'ticket-book'
    | 'gsr'
    | 'dashboard' = 'date';
  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();
  componentDestroy: () => Observable<boolean>;
  currentSub: AuthorizationDto = null;
  mobile$: Observable<boolean> = this.sb.mobile$;
  cbInstance: any;
  loading: boolean;
  errMsg: boolean;

  constructor(
    private sb: AppSandboxService,
    public dataCache: DataCache,
    private router: Router,
    private toast: ToastService,
    private customerService: CustomerService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.dataCache
      .getUserSubscriptionModel(true)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((response) => {
        this.currentSub = response.currentDaySubscription;
      });
    this.cbInstance = window['Chargebee'].init({
      site: environment.chargebee.site,
      apiKey: environment.chargebee.apiKey,
    });
  }

  getTodaysFreeRaceMessage(): string {
    return this.currentSub?.availableTracks?.length > 0
      ? `No thanks, take me to  ${this.currentSub?.availableTracks[0]} ${this.currentSub?.availableRaces?.join(
        ', '
      )}`
      : ``;
  }

  getTodaysFreeTrack(): string {
    return this.currentSub?.availableTracks?.length > 0 ? `${this.currentSub?.availableTracks[0]}`: '';
  }

  getCurrentDate(): string {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate()).toISOString();
  }

  /**
   * when a user is a free user, then we will show this upsell to a single track pass
   * @param payload
   */
  purchase(payload: {
    planCode: string;
    action: string;
    startDate: string;
    promoCode: string;
    trackName: string;
    subscriptionAction: string;
    deviceId: string;
  }): void {
    // right now, we are only instantly upgrading the single track subscription. We can expand this in the future...
    //
    payload.action = 'Purchase';
    payload.planCode = '118-USD-Daily';
    payload.trackName = this.getTodaysFreeTrack();
    payload.deviceId = getDeviceId();
    payload.subscriptionAction = 'Purchase';
    payload.startDate = this.getCurrentDate();

    const pl = payload as CheckoutExistingCustomerCommand;
    let isSuccess = false;
    this.cbInstance.openCheckout({
      hostedPage: () => {
        this.loading = true;
        return this.customerService
          .apiCustomerCheckoutExistingPost(pl)
          .pipe(finalize(BlockUiService.stop), takeUntil(this.componentDestroy()))
          .toPromise();
      },
      loaded: () => {
        console.log('checkout opened');
      },
      error: () => {
        this.loading = false;
        this.ref.markForCheck();
        this.errMsg = true;
        return this.customerService
          .apiCustomerCheckoutExistingErrorPost(pl)
          .toPromise()
          .then((resp) => {
            this.toast.success(
              'We had an unexpected error. You can contact support via the blue button in the bottom left.'
            );
          });
      },
      close: () => {
        this.loading = false;
        this.ref.detectChanges();
        if (isSuccess == false) {
          this.toast.success('We have canceled the transaction. No further action needed.');
        }
      },
      success: (hostedPageId) => {
        isSuccess = true;
        const load = { hostedPageId, promoCode: pl.promoCode };

        // Hosted page id will be unique token for the checkout that happened
        // You can pass this hosted page id to your backend
        // and then call our retrieve hosted page api to get subscription details
        // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page

        // I am following the above instructions and don't see an automated order summary here....
        // the api call return a hostedpage

        // nopt sure what this does now...

        return this.customerService
          .apiCustomerCheckoutExistingSuccessPost(load)
          .toPromise()
          .then((resp) => {
            this.toast.success('Thank you for your purchase.');
            setTimeout(() => {
              this.navigateToNextUpcomingRace();
            }, 5000);
          });
      },
      step: (value) => {
        // value -> which step in checkout
        console.log(value);
      },
    });
  }

  routeTo(url: string): void {
    if (url) {
      this.router.navigate([url]).then(() => {
        this.closeModal();
      });
    } else {
      this.closeModal();
      this.toast.error('Sorry, first race url is incorrect');
    }
  }

  closeModal(): void {
    this.closeModal$.emit(true);
  }

  navigateToNextUpcomingRace(): void {
    window.location.href = '/';
  }
}
