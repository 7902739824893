import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '@services/login.service';
import {
  AccountService,
  CheckoutNewCustomerCommand, CheckoutNewCustomerErrorCommand,
  CustomerService,
  MiscService,
  PlanService,
  PlanSummaryDto,
  ProductService,
  PromoCodeDetailDto,
  PromoCodeService,
  RacesService,
  UserService,
} from '@swagger-codegen/*';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { JoyrideService } from 'ngx-joyride';
import zxcvbn from 'zxcvbn';
import _ from 'lodash';
import { AppSandboxService } from '@services/sandbox.service';
import { ToastService } from '@services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUiService } from '@services/block-ui.service';
import { PaymentService } from '@services/payment.service';
import { getDeviceId } from '@services/functions';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { map, takeUntil } from 'rxjs/operators';
import moment from 'moment-timezone';
import { IntercomService } from '@services/intercom.service';
import { environment } from 'src/environments/environment';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { HttpResponse } from '../../JtHttp';
import { ROUTES } from '../../routes';
import {ActivityService} from "@services/activity.service";
import {ModalService} from "../../modules/authenticated-module/services/modal.service";

@TakeUntilDestroy
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [PaymentService],
})
export class SignupComponent implements OnInit, OnDestroy {
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
    CountryISO.Canada,
    CountryISO.Australia,
  ];
  phone: {
    countryCode: string;
    dialCode: string;
    e164Number: string;
    internationalNumber: string;
    nationalNumber: string;
    number: string;
  };

  accountForm: FormGroup;
  emailForm: FormGroup;
  cardError = false;
  errorMessage = '';
  cardNumber = '';
  ra: CheckoutNewCustomerErrorCommand | any = null;
  isEmailInUseShowModal = false;
  mobile$: Observable<boolean> = this.sb.mobile$;
  routeSubscription: Subscription;
  planCode: string;
  planResult: PlanSummaryDto;
  planNotFound = false;
  // tslint:disable-next-line:array-type
  racingDayOptions: { date: string; dayOfWeek: string }[];
  promoCode = '';
  promoCodeDetails: PromoCodeDetailDto = null;
  currentDate = '';
  componentDestroy: () => Observable<boolean>;
  timerId: number;
  timerSeconds = 60;
  step = 1;
  disableDaySelector = false;
  cbInstance: any;
  loading: boolean;
  errMsg: boolean;

  constructor(
    private _ngZone: NgZone,
    public loginService: LoginService,
    public accountService: AccountService,
    public userService: UserService,
    public racesService: RacesService,
    private readonly joyrideService: JoyrideService,
    private sb: AppSandboxService,
    public toastService: ToastService,
    public miscService: MiscService,
    public planService: PlanService,
    private route: ActivatedRoute,
    private router: Router,
    private promoCodeService: PromoCodeService,
    public paymentService: PaymentService,
    private intercomService: IntercomService,
    private customerService: CustomerService,
    private ref: ChangeDetectorRef,
    private productService: ProductService,
    private readonly activityService: ActivityService,
    public readonly modalService: ModalService
  ) {
    this.currentDate = moment().tz('America/Los_Angeles').format('MM/DD/YYYY');
    if (loginService.isAuthenticated()) {
      // router.navigateByUrl('/');
    }
    window['SubscriptionComponent'] = {component: this, zone: _ngZone};
    this.route.queryParams.pipe(takeUntil(this.componentDestroy())).subscribe((params) => {
      if (params['code']) {
        this.planCode = _.toUpper(params['code']);
        if (this.planCode === 'WEEKPASS') {
          this.planCode = '129-USD-WEEKLY';
        }
        this.promoCodeDetails = null;
        this.promoCode = '';
        if (params['promoCode']) {
          this.promoCode = _.toUpper(params['promoCode']);
          this.getPromoCodeDetails(params['promoCode']);
        }
        BlockUiService.start();
        let prodService;
        console.log(this.planCode);
        if (!_.isEmpty(this.planCode) && !_.isEmpty(this.promoCode)) {
          if (this.planCode.indexOf('SURGE') > -1) {
            prodService = this.productService.apiProductGet(this.planCode, [this.promoCode], 'Special-Event-Pricing');
          }
          else {
            prodService = this.productService.apiProductGet(this.planCode, [this.promoCode]);
          }
        } else {
          if (this.planCode.indexOf('SURGE') > -1) {
            prodService = this.productService.apiProductGet(this.planCode, null, 'Special-Event-Pricing');
          }
          else {
            prodService = this.productService.apiProductGet(this.planCode);
          }
        }
        prodService.pipe(takeUntil(this.componentDestroy())).subscribe((planResult) => {
          console.log(planResult);
          // if (planResult.isSuccess && planResult.successData) { }
          this.planResult = planResult.plans.find((pr) => _.toUpper(pr.planCode) === this.planCode);
          if (params['isFreeTrial']) {
            this.planResult = planResult.find((pr) => _.toUpper(pr.planCode) === this.planCode && pr.isFreeTrial);
            this.planResult.isFreeTrial = params['isFreeTrial'];
          }
          // TODO: pull this out on 1/31/2022
          //
          console.log(this.planResult);
          console.log(params['selectedDate']);

          if (params['selectedDate']) {
            this.planResult.selectedDate = params['selectedDate'];
          }

          if (params['selectedTrack']) {
            this.planResult.selectedTrack = params['selectedTrack'];
          }

          this.planNotFound = false;
          this.errorMessage = '';
          this.emailForm = new FormGroup({
            emailVC: new FormControl(
              null,
              [Validators.required, Validators.pattern('[0-9]{6}')],
              this.checkEmailVC.bind(this)
            ),
          });
          this.accountForm = new FormGroup(
            {
              firstName: new FormControl(null),
              lastName: new FormControl(null),
              email: new FormControl(
                null,
                [
                  Validators.required,
                  Validators.pattern("[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,}@.{1,}[.]{1}[a-zA-Z]{2,}"),
                ],
                this.checkEmail.bind(this)
              ),
              phoneNumber: new FormControl(null),
              password: new FormControl(null, [Validators.required, Validators.minLength(8), this.customValidator()]),
              confirmPassword: new FormControl(null, [Validators.required]),
            },
            this.passwordMatchValidator
          );
          BlockUiService.stop();
        });
      } else {
        this.planNotFound = true;
        this.router.navigate(['/', ROUTES.SignUp]).then();
        BlockUiService.stopAll();
      }
    });
  }

  get creditCardRequired(): boolean {
    if (this.promoCodeDetails) {
      return this.promoCodeDetails?.creditCardRequired;
    } else {
      return this.planResult?.creditCardRequired;
    }
  }

  get checkValidSteps(): boolean {
    return (
      (this.step === 1 && this.accountForm.valid) || (this.step === 2 && this.emailForm.valid && this.accountForm.valid)
    );
  }

  checkType(type: string): boolean {
    return _.toUpper(this.planResult?.type) === _.toUpper(type);
  }

  public getPromoCodeDetails(val: string): void {
    this.promoCodeService
      .apiPromoCodePromoCodeDetailsGet(val)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((result) => {
        this.promoCodeDetails = result;
        const startDate = _.find(
          this.promoCodeDetails?.promoCodeClaims,
          (x) => x.claimType === 'PurchaseDate'
        )?.claimValue;
        if (this.promoCodeDetails?.plans.indexOf('DAY') !== -1 && startDate) {
          this.currentDate = moment(startDate).format('MM/DD/YYYY');
          this.disableDaySelector = true;
        }
      });
  }

  cleanText(s: string): string {
    return s.replace('EquinEdge ', '');
  }

  public checkEmail(control: FormControl): Promise<any> | Observable<any> {
    const checkEmails = this.accountService.apiAccountIsEmailInUseGet(control.value).pipe(
      map((result) => {
        if (this.planCode === 'SHFL_TRACKPASS') {
          this.isEmailInUseShowModal = false;
          return null;
        }
        if (result && !result.isEmailInUse) {
          this.isEmailInUseShowModal = false;
          return null;
        }
        this.isEmailInUseShowModal = true;

        this.activityService.log('Signup - Check Email', {
          email: control.value,
          planCode: this.planCode,
          track: this.planResult?.selectedTrack,
          date: this.planResult?.selectedDate,
        });

        return {isEmailInUse: true};
      }),
      takeUntil(this.componentDestroy())
    );
    return checkEmails;
  }

  public checkEmailVC(control: FormControl): Promise<any> | Observable<any> {
    if (this.accountForm.invalid) {
      return of(null);
    }
    const checkEmailsVC = this.userService
      .apiUserVerifyCodePost({
        code: +_.trim(control.value),
        email: this.accountForm.controls.email.value,
      })
      .pipe(
        map((result) => {
          if (result) {
            return null;
          } else {
            return {EmailsVCIsInvalid: true};
          }
        }),
        takeUntil(this.componentDestroy())
      );
    return checkEmailsVC;
  }

  clearEmail(): void {
    this.accountForm.controls.email.patchValue('');
    this.accountForm.controls.email.markAsPristine();
    this.accountForm.controls.email.markAsUntouched();
    this.isEmailInUseShowModal = false;
  }

  isValid(controlName: string): boolean {
    return (
      this.accountForm.controls[controlName].valid &&
      (this.accountForm.controls[controlName].dirty || this.accountForm.controls[controlName].touched)
    );
  }

  isInValid(controlName: string): boolean {
    return (
      this.accountForm.controls[controlName].invalid &&
      (this.accountForm.controls[controlName].dirty || this.accountForm.controls[controlName].touched)
    );
  }

  isValidEmailForm(controlName: string): boolean {
    return (
      this.emailForm.controls[controlName].valid &&
      (this.emailForm.controls[controlName].dirty || this.emailForm.controls[controlName].touched)
    );
  }

  isInValidEmailForm(controlName: string): boolean {
    return (
      this.emailForm.controls[controlName].invalid &&
      (this.emailForm.controls[controlName].dirty || this.emailForm.controls[controlName].touched)
    );
  }

  passwordMatchValidator(g: FormGroup): any {
    if (g.get('password').value !== g.get('confirmPassword').value) {
      g.get('confirmPassword').setErrors({mismatch: true});
    }
    return null;
  }

  customValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const test = zxcvbn(control.value) as {
          feedback: { warning: string; suggestions: string[] };
          score: number;
        };
        if (test.feedback.warning) {
          let error = '';
          error += test.feedback.warning + '.';
          _.forEach(test.feedback.suggestions, (result) => {
            error += ' ' + result;
          });
          return {customValidator: error};
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  ngOnInit(): void {
    this.cbInstance = window['Chargebee'].init({
      site: environment.chargebee.site,
      apiKey: environment.chargebee.apiKey,
    });
  }

  goBack(): void {
    if (this.step === 1) {
      this.router.navigateByUrl('/signup-plans').then();
    } else {
      this.cardError = false;
      this.cardNumber = '';
      this.emailForm.controls.emailVC.patchValue('');
      this.step--;
    }
  }

  sendEmailVC(): void {
    if (!this.timerId) {
      this.timerId = setInterval(() => {
        if (this.timerSeconds < 1) {
          clearInterval(this.timerId);
          this.timerSeconds = 60;
          this.timerId = 0;
        } else {
          this.timerSeconds--;
        }
      }, 1000);
      this.userService
        .apiUserSendVerificationCodePost({
          email: this.accountForm.controls.email.value,
        })
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(() => {
        });
    }
  }

  setPhoneValue(v: {
    countryCode: string;
    dialCode: string;
    e164Number: string;
    internationalNumber: string;
    nationalNumber: string;
    number: string;
  }) {
    this.accountForm.controls.phoneNumber.setValue(v?.e164Number);
  }

  validateForm(): void {
    if (this.accountForm.invalid) {
      this.accountForm.controls.firstName.markAsTouched();
      this.accountForm.controls.lastName.markAsTouched();
      this.accountForm.controls.email.markAsTouched();
      this.accountForm.controls.password.markAsTouched();
      this.accountForm.controls.confirmPassword.markAsTouched();
    } else {
      this.openCheckout();
    }
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  showCurrentUserLink(plan: string): boolean {
    const promoPlans = this.promoCodeDetails?.plans.map((p) => p.toUpperCase());
    return (
      promoPlans?.indexOf(plan?.toUpperCase()) > -1 &&
      this.promoCodeDetails?.promoCodeClaims?.filter(
        (pcc) => pcc.claimType === 'Filter' && pcc.claimValue === 'NewUser'
      ).length === 0
    );
  }

  isFreeTrial(): boolean {
    return (
      this.promoCodeDetails?.promoCodeClaims?.filter(
        (pcc) => pcc.claimType === 'FreeTrial' && pcc.claimValue === 'True'
      ).length > 0
    );
  }

  openCheckout() {
    if (this.step === 1) {
      this.sendEmailVC();
      this.step = 2;
    } else {
      this.ra = {
        deviceId: getDeviceId(),
        planCode: '',
        firstName: this.accountForm.controls.firstName.value,
        lastName: this.accountForm.controls.lastName.value,
        email: this.accountForm.controls.email.value,
        phoneNumber: this.accountForm.controls.phoneNumber.value,
        password: this.accountForm.controls.password.value,
        isFreeTrial: undefined,
      };
      this.ra.planCode = this.planResult ? this.planResult.planCode : '';
      this.ra.promoCode = this.promoCode ? this.promoCode : '';
      this.ra.accountType = CheckoutNewCustomerErrorCommand.AccountTypeEnum.PAID;
      this.ra.startDate = this.planResult?.selectedDate
        ? moment(this.planResult?.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00.000') + 'Z'
        : moment().tz('America/Los_Angeles').format('YYYY-MM-DDT00:00:00.000') + 'Z';
      if (this.planResult?.selectedTrack) {
        this.ra.trackName = this.planResult.selectedTrack;
      }
      this.ra.isFreeTrial = this.planResult.isFreeTrial;
      this.ra.deviceId = getDeviceId();
      this.loginService.clearLocalStorage();
      this.registerNewUser();
    }
  }

  errorRegister() {
    this.loading = false;
    this.ref.markForCheck();
    this.errMsg = true;
    this.toastService.error(`Unable to create new subscription. Redirecting...`);
    return this.customerService
      .apiCustomerCheckoutNewErrorPost(this.ra)
      .toPromise()
      .then((resp) => {
        this.step = 1;
        this.goBack();
      });
  }

  registerNewUser() {
    if (this.ra && this.accountForm.valid && this.emailForm.valid) {
      let isSuccess = false;
      this.activityService.log('Signup - Register New User', this.ra);
      this.cbInstance.openCheckout({
        hostedPage: () => {
          this.loading = true;
          return this.customerService
            .apiCustomerCheckoutNewPost(this.ra as CheckoutNewCustomerCommand, 'response')
            .pipe(
              map((a) => {
                if (!a.isSuccess) {
                  this.errorRegister().then();
                  return null;
                } else {
                  return a.successData;
                }
              })
            )
            .toPromise();
        },
        loaded: () => {
        },
        error: () => {
          this.errorRegister().then();
        },
        close: () => {
          if (!isSuccess) {
            this.errorRegister().then();
          } else {
            this.loading = false;
            this.ref.detectChanges();
          }
        },
        success: (hostedPageId) => {
          // Hosted page id will be unique token for the checkout that happened
          // You can pass this hosted page id to your backend
          // and then call our retrieve hosted page api to get subscription details
          // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page

          // I am following the above instructions and don't see an automated order summary here....
          // the api call return a hostedpage

          // nopt sure what this does now...
          isSuccess = true;
          return this.customerService
            .apiCustomerCheckoutNewSuccessPost({hostedPageId})
            .toPromise()
            .then((resp) => {
              if (resp && resp?.authTokenResponse?.authBearerToken) {
                localStorage.removeItem('blocknavigation');
                this.loginService.setSession(resp.authTokenResponse.authBearerToken);
                window.location.href = '/';
              }
            });
        },
        step: (value) => {
          // value -> which step in checkout
          // console.log('sktasejlkajtlks' + value);
        },
      });
    } else {
      this.emailForm.controls.emailVC.markAllAsTouched();
    }
  }

  removeModal(): void {
    this.modalService.destroy();
  }
}
