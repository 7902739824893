import { Component, OnInit } from '@angular/core';
import {
  PlanSummaryDto,
  PromoCodeDetailDto,
  ProductService,
} from '@swagger-codegen/*';
import { AppSandboxService } from '@services/sandbox.service';
import { Router } from '@angular/router';
import { BlockUiService } from '@services/block-ui.service';
import { ModalService } from '../../modules/authenticated-module/services/modal.service';
import { ToastService } from '@services/toast.service';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import {forkJoin, Observable} from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { environment } from 'src/environments/environment';
import {ProductCodes} from "../../modules/shared-module/models/product-codes.model";

@TakeUntilDestroy
@Component({
  selector: 'app-plans-anonymous',
  templateUrl: './plans-anonymous.component.html',
  styleUrls: ['./plans-anonymous.component.scss'],
})
export class PlansAnonymousComponent implements OnInit {
  componentDestroy: () => Observable<boolean>;
  mobile$ = this.sb.mobile$;
  tablet$ = this.sb.tablet$;
  type: 'FreeTrial' | 'Memberships' | 'Passes' = 'Memberships';
  plans: PlanSummaryDto[];
  specialPlans: PlanSummaryDto[];
  promoCode: PromoCodeDetailDto = null;
  cbInstance: any;
  loading: boolean;
  errMsg: boolean;

  constructor(
    private sb: AppSandboxService,
    public toast: ToastService,
    private router: Router,
    public modalService: ModalService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getAnonymousPlans();
    this.cbInstance = window['Chargebee'].init({
      site: environment.chargebee.site,
      apiKey: environment.chargebee.apiKey,
    });
  }

  setPromoCode(v: PromoCodeDetailDto): void {
    this.promoCode = v;
    let notFind = true;
    _.forEach(this.plans, (x) => {
      if (this.promoCode && this.promoCode.plans.indexOf(x.planCode) !== -1) {
        notFind = false;
      }
    });

    if (notFind && this.promoCode?.plansSummary?.length) {
      this.plans = this.promoCode.plansSummary;
      switch (_.capitalize(this.promoCode.plansSummary[0].type)) {
        case 'Pass':
          this.changeType('Passes');
          break;
        case 'Membership':
          this.changeType('Memberships');
          break;
        case 'FreeTrial':
          this.changeType('FreeTrial');
          break;
      }
    }
  }

  changeType(v: 'FreeTrial' | 'Memberships' | 'Passes') {
    this.type = v;
  }

  get freetrial() {
    return this.plans?.filter((x) => x.planCode === 'FreeSignup-USD-Daily');
  }

  get memberships() {
    return this.plans?.filter((x) => _.capitalize(x.type) === 'Membership' && (x.planCode !== ProductCodes.starterPlanYearly && x.planCode !== ProductCodes.eliteWeekendYearly));
  }

  get passes() {
    return this.specialPlans.concat(this.plans?.filter((x) => _.capitalize(x.type) === 'Pass' && x.planCode !== 'FreeSignup-USD-Daily'));
  }

  private getAnonymousPlans(): void {
    BlockUiService.start('Preparing signup...');
    this.productService.apiProductGet()
      .pipe(finalize(BlockUiService.stop), takeUntil(this.componentDestroy()))
      .subscribe((results) => {
        this.specialPlans = results?.products['Pass']?.filter(p => p.planCode.includes('Surge'))?.sort((a, b) => a.amount - b.amount);
        this.plans = results?.plans.sort((a, b) => a.amount - b.amount)
        // .filter((p) => p.planCode !== ProductCodes.singleWeekend && !p.planCode.includes('Surge'));
      });
    /*forkJoin([this.productService
      .apiProductGet(null, null, "Special-Event-Pricing"), this.productService.apiProductGet()])
      .pipe(finalize(BlockUiService.stop), takeUntil(this.componentDestroy()))
      .subscribe((results) => {
        this.specialPlans = results[0]?.products['SpecialEvent']?.sort((a, b) => a.amount - b.amount);
        this.plans = results[1]?.plans.sort((a, b) => a.amount - b.amount)
          .filter((p) => p.planCode !== ProductCodes.singleWeekend);
      });*/
  }

  purchase(plan: PlanSummaryDto): void {
    const query = {
      code: plan.planCode,
      promoCode: undefined,
      selectedDate: undefined,
      selectedTrack: undefined,
      isFreeTrial: undefined,
    };
    if (this.promoCode && this.promoCode.plans.indexOf(plan.planCode) > -1) {
      query.promoCode = this.promoCode.code;
    }
    if (plan?.selectedDate) {
      query.selectedDate = plan.selectedDate;
    }
    if (plan?.selectedTrack) {
      query.selectedTrack = plan.selectedTrack;
    }
    if (plan?.isFreeTrial) {
      query.isFreeTrial = plan?.isFreeTrial;
    }
    this.router.navigate(['/signup'], { queryParams: query }).then();
  }

  protected readonly ProductCodes = ProductCodes;
}
