import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserDevice } from '../JtHttp';
import { IntercomService } from '@services/intercom.service';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { HistoryService, ActivityDto } from '@swagger-codegen/*';
import { getDeviceId, isAuthenticated } from './functions';
import moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@TakeUntilDestroy
@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private logs: ActivityDto[] = [];
  private logIndex = 0;
  componentDestroy: () => Observable<boolean>;

  constructor(
    private history: HistoryService,
    private deviceService: DeviceDetectorService,
    private intercom: IntercomService,
    private db: AngularFireDatabase
  ) {
    this.setupPeriodicSaves();
  }

  public log(action: string, ...parameters: any[]): void {
    if (isAuthenticated()) {
      const activity = {
        time: moment().tz('America/Los_Angeles').toISOString(),
        action,
        params: JSON.stringify(parameters),
        isProcessed: false,
      };
      if (environment.isDevelopment) {
        // console.log('activity', activity);
      }
      this.logs.push(activity);

      if (this.logIndex <= 5) {
        this.save();
      }

      if (this.logIndex < 1000) {
        this.logIndex += 1;
      }
    }
  }

  public logUserDevice(): void {
    if (isAuthenticated()) {
      let orientation = {};
      try {
        orientation = screen['msOrientation'] || screen['mozOrientation'] || (screen.orientation || {}).type;
      } catch (e) {
        orientation = 'not support';
      }
      const userDevice: UserDevice = {
        clientVersionHeader: '{{POST_BUILD_ENTERS_VERSION_HERE}}',
        deviceId: getDeviceId(),
        screenOrientation: orientation,
        viewportSize: { height: window.innerHeight, width: window.innerWidth },
        deviceInfo: this.deviceService.getDeviceInfo(),
      };
      this.log('deviceInfo', userDevice);
    }
  }

  private setupPeriodicSaves(): void {
    setInterval(() => {
      if (this.logs.length > 0) {
        this.save();

        this.intercom.update();
      }
    }, 1000 * 60);
  }

  private save(): void {
    if (this.logs.filter((l) => l.isProcessed === false).length <= 0) {
      return;
    }
    // immediately mark processed as true
    this.logs.filter((l) => l.isProcessed === false).forEach((l) => (l.isProcessed = true));
    _.chain([isAuthenticated()])
      .filter((auth) => auth === true)
      .map((item) => {
        // const email = _.replace(localStorage['email'] + '.#$[]. #$[  ]', /\.|#|\[|\$|\]| /g, '');
        // if (email) {
        //   console.log(['logs ' + email, this.logs]);
        //   _.forEach(this.logs, (x) => {
        //     this.db.list<PublicActivity>(environment.firebase.url.activityLogs + '/' + email).push(x).then(() => {
        //     });
        //   });
        //   this.logs.length = 0;
        //   const time = moment().tz('America/Los_Angeles').add('-35', 'minutes').toISOString();
        //   //  console.log(['time',this.db.list<PublicActivity>(environment.firebase.url.activityLogs + '/' + email)
        //   .query.ref.orderByChild('time').endAt(time).toJSON()]);
        //   this.db.list<PublicActivity>(environment.firebase.url.activityLogs + '/' + email).query.orderByChild('time')
        //     .endAt(time).on('value', (x) => {
        //     x.forEach((x) => {
        //       x.ref.remove().then();
        //     });
        //   });
        // }
        // firebase.database().ref('user-posts/' + myUserId).orderByChild('starCount');
        this.history
          .apiHistoryActivityPost(this.logs.filter((l) => l.isProcessed === true))
          .pipe(takeUntil(this.componentDestroy()))
          .subscribe(() => {
            this.logs = this.logs.filter((l) => l.isProcessed === false);
          });
      })
      .value();
  }
}
