import { Injectable } from '@angular/core';
import {
  AccountService,
  CurrentSubscriptionStatusResponse, PoolsDto, ProbablesDto,
  PublicCompactRace,
  PublicTicket,
  RaceDetails,
  RacesService,
  SimplePastPerformance,
  SpecialWagerDto,
  StronachFiveDto, WillpayDto1,
  RaceVersionHandlerService, RaceVersionDto, AuthorizationDto
} from '@swagger-codegen/*';
import { BehaviorSubject, interval, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { LiveUpdateService } from './realtime/live-update.service';
import { LiveOddsXform } from '../models/public-live-odds-tuple.model';
import { Functions, isNotEmpty } from '@services/functions';
import { FeatureFlagService, UserSubscriptionModel } from './feature-flag.service';
import { UserStatusModel } from '../models';
import moment, { relativeTimeThreshold } from 'moment-timezone';
import { LoginService } from '@services/login.service';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { auditTime, take, takeUntil, repeat, delay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { JtHttp } from '../../../JtHttp';
import _, { update } from 'lodash';
import { CacheObservable } from './models/cache-observable.model';
import { RacingDay } from './interfaces/racing-day.interface';
import { UpcomingRace } from './interfaces/upcoming-race.interface';
import { CacheEntry } from './models/cache-entry.model';
import {ProductCodes} from "../../shared-module/models/product-codes.model";
@TakeUntilDestroy
@Injectable({
  providedIn: 'root',
})
export class DataCache {
  public allRacesByDay: ReplaySubject<RacingDay[]> = new ReplaySubject(1);
  public allCompactRaces: ReplaySubject<PublicCompactRace[]> = new ReplaySubject(1);
  public runUpcomingRace: Subject<any> = new Subject<any>();
  public upcomingRaces: ReplaySubject<PublicCompactRace[]> = new ReplaySubject<PublicCompactRace[]>(1);
  public updateSelector: Subject<{
    raceId: number;
    raceDetails?: RaceDetails;
  }> = new Subject();
  public stronach5$: ReplaySubject<{ [date: string]: StronachFiveDto[] }> = new ReplaySubject<{
    [date: string]: StronachFiveDto[];
  }>(1);
  public goldenHours$: ReplaySubject<{ [date: string]: SpecialWagerDto[] }> = new ReplaySubject<{
    [date: string]: SpecialWagerDto[];
  }>(1);
  private _coastToCoast: { [date: string]: SpecialWagerDto[] } = {};
  public coastToCoast$: ReplaySubject<{ [date: string]: SpecialWagerDto[] }> = new ReplaySubject<{
    [date: string]: SpecialWagerDto[];
  }>(1);
  private _allStakesPick5: { [date: string]: SpecialWagerDto[] } = {};
  public allStakesPick5$: ReplaySubject<{ [date: string]: SpecialWagerDto[] }> = new ReplaySubject<{
    [date: string]: SpecialWagerDto[];
  }>(1);
  private _futureStarsPick5: { [date: string]: SpecialWagerDto[] } = {};
  public futureStarsPick5$: ReplaySubject<{ [date: string]: SpecialWagerDto[] }> = new ReplaySubject<{
    [date: string]: SpecialWagerDto[];
  }>(1);
  private _planViewModalCache: CacheEntry<UserSubscriptionModel>;
  public isRunning = false;
  public customizeTicket$: BehaviorSubject<PublicTicket> = new BehaviorSubject(null);
  public stronach5FirstLink$: BehaviorSubject<string> = new BehaviorSubject('');
  public showVideoRace$: ReplaySubject<{
    race: PublicCompactRace;
    pastRace: SimplePastPerformance;
    isReplay: boolean;
  }> = new ReplaySubject(1);
  // ];
  public tracksWithVideo = [
    {
      name: 'Du Quoin',
      value: 'duquoin_mbr',
      code: 'DQD',
      trackAbbreviation: 'DUQ',
    },
    {
      name: 'Emerald Downs',
      value: 'emerald_downs_mbr',
      code: 'EMD',
      trackAbbreviation: 'EMD',
    },
    {
      name: 'Fair Meadows',
      value: 'fair_meadows_mbr',
      code: 'FMN',
      trackAbbreviation: 'FMT',
    },
    {
      name: 'Golden Gate Fields',
      value: 'golden_gate_mbr',
      code: 'GGD',
      trackAbbreviation: 'GG',
    },
    {
      name: 'Gulfstream Park',
      value: 'gulfstream_mbr',
      code: 'GPM',
      trackAbbreviation: 'GP',
    },
    {
      name: 'Hawthorne',
      value: 'hawthorne_mbr',
      code: 'HAW',
      trackAbbreviation: 'HAW',
    },
    {
      name: 'Keeneland',
      value: 'keeneland_mbr',
      code: 'KED',
      trackAbbreviation: 'KEE',
    },
    {
      name: 'Laurel Park',
      value: 'laurel_mbr',
      code: 'LRM',
      trackAbbreviation: 'LRL',
    },
    {
      name: 'Meadowlands',
      value: 'meadowlands_mbr',
      code: 'MZN',
      trackAbbreviation: 'MED',
    },
    {
      name: 'Pimlico',
      value: 'pimlico_mbr',
      code: 'PIM',
      trackAbbreviation: 'PIM',
    },
    {
      name: 'Prairie Meadows',
      value: 'prairie_meadows_mbr',
      code: 'PMM',
      trackAbbreviation: 'PRM',
    },
    {
      name: 'Red Mile',
      value: 'red_mile_mbr',
      code: 'RLD',
      trackAbbreviation: 'RDM',
    },
    {
      name: 'Remington Park',
      value: 'remington_park_mbr',
      code: 'RED',
      trackAbbreviation: 'RP',
    },
    {
      name: 'Retama Park',
      value: 'retama_mbr',
      code: 'RTN',
      trackAbbreviation: 'RET',
    },
    {
      name: 'Ruidoso Downs',
      value: 'ruidoso_mbr',
      code: 'RUD',
      trackAbbreviation: 'RUI',
    },
    {
      name: 'Santa Anita Park',
      value: 'santa_anita_mbr',
      code: 'SAD',
      trackAbbreviation: 'SA',
    },
    {
      name: 'Sunray Park',
      value: 'sunray_mbr',
      code: 'RSM',
      trackAbbreviation: 'SRP',
    },
    {
      name: 'Tioga Downs',
      value: 'tioga_downs_mbr',
      code: 'TQN',
      trackAbbreviation: 'TGD',
    },
  ];
  haveRaces = false;
  componentDestroy: () => Observable<boolean>;
  private raceDetails: { [id: number]: CacheEntry<RaceDetails> } = {};
  compactRaces: { [id: number]: PublicCompactRace } = {};
  // public tracksWithVideo = [
  //   {name: 'Golden Gate Fields', value: 'golden_gate_mbr', code: 'GGD', trackAbbreviation: 'GG'},
  //   {name: 'Gulfstream Park', value: 'gulfstream_mbr', code: 'GPM', trackAbbreviation: 'GP'},
  //   {name: 'Laurel Park', value: 'laurel_mbr', code: 'LRM', trackAbbreviation: 'LR'},
  //   {name: 'Pimlico', value: 'pimlico_mbr', code: 'PIM', trackAbbreviation: 'PIM'},
  //   {name: 'Santa Anita Park', value: 'santa_anita_mbr', code: 'SAD', trackAbbreviation: 'SA'}
  private _stronach5: { [date: string]: StronachFiveDto[] } = {};
  private _goldenHours: { [date: string]: SpecialWagerDto[] } = {};
  allRacesCurrent: PublicCompactRace[];
  willpays$: Subject<WillpayDto1[]> = new Subject();
  willpaySubscription: Subscription;
  pools$: Subject<PoolsDto> = new Subject();
  poolSubscription: Subscription;
  probables$: Subject<ProbablesDto> = new Subject();
  probableSubscription: Subscription;
  userAuths: { [p: string]: AuthorizationDto } = {};

  public upcomingRaceList: PublicCompactRace[] = [];
  private raceIds: Array<number>;
  private raceVersionSubscription;
  private raceVersionResubscribe: boolean = false;

  constructor(
    private racesService: RacesService,
    private liveUpdates: LiveUpdateService,
    private loginService: LoginService,
    private accountService: AccountService,
    private raceVersionService: RaceVersionHandlerService,
    private httpCache: JtHttp
  ) {
    this.loginService.loggedIn$.pipe(takeUntil(this.componentDestroy())).subscribe((isLogin) => {
      this.isRunning = false;
      this.clearCache();
      if (isLogin) {
        this.isRunning = true;
        this.init();
      }
    });
  }

  public clearCache(): void {
    this.allRacesByDay = new ReplaySubject(1);
    this.upcomingRaces = new ReplaySubject<UpcomingRace[]>();
    this.raceDetails = {};
    this.compactRaces = {};
    this._planViewModalCache = null;
  }

  public updateAllRaces(allRaces?: PublicCompactRace[]): void {
    if (allRaces?.length) {
      this.allRacesCurrent = allRaces;
    } else if (this.allRacesCurrent?.length) {
      allRaces = this.allRacesCurrent;
    }
    if (allRaces && allRaces.length) {
      for (const race of allRaces) {
        this.updateCompactRace(race);
      }
      this.haveRaces = true;
      this.allRacesByDay.next(this.constructRacesByDay(allRaces));
      this.allCompactRaces.next(allRaces);
      this.runUpcomingRace.next();
    }
  }

  public init(): void {
    if (!environment.firebase.enable) {
      this.refreshAllRaces();
    }
    this.accountService
      .apiAccountMyAccountGet()
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((user) => {
        this.userAuths = user?.authorizations?.authorizations;
        this.allRacesByDay.next(this.constructRacesByDay(this.allRacesCurrent));
      });

    this.getUpcomingRaces();
    this.allRacesByDay.pipe(take(1), takeUntil(this.componentDestroy())).subscribe(() =>
      interval(10000)
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(() => this.runUpcomingRace.next())
    );
    this.refreshAllRaces();
    setInterval(() => {
      if(this.raceVersionSubscription && this.raceVersionSubscription.closed) {
        this.subscribeToRaceVersionFeed();
      }
    },1000);
  }
  subscribeToRaceVersionFeed(): void {
    this.raceVersionSubscription = this.raceVersionService
    .apiPollRaceVersionPost(this.raceIds)
    .pipe(take(1))
    .subscribe((versions) => {
      let updateFlag = false;
      if (versions) {
        versions.forEach((raceVersion) => {
          const race = this.upcomingRaceList.find((r) => {
            return r.id == raceVersion.raceId;
          });
          if (race && race?.dataVersion === undefined || race?.dataVersion === null) {
            race.dataVersion = 0;
          }
          if (race && race.dataVersion < raceVersion.version) {
            race.dataVersion = raceVersion.version;
            updateFlag = true;
          }
        });
      }
      if (updateFlag) {
        this.getUpcomingRaces();
      }
      this.raceVersionSubscription.unsubscribe();
    },
    (error) => {
      this.raceVersionSubscription.unsubscribe();
    });
  }
  getUpcomingRaces(): void {
    this.liveUpdates.subscribeToUpcomingRaces().pipe(take(1))
      .subscribe((upcomingRaces) => {
        if(upcomingRaces) {
          const now = Date.now();
          let entireUpdateTime = 0;
          const validRaces: PublicCompactRace[] = upcomingRaces.filter(race => {
            return (
              (
                race.id
                && race.date
                && race.trackName
                && race.raceNumber
                && race.trackAbbreviation
                && !!race.postTimeUtc
              )
              && (
                (race.trackAbbreviation == 'HOU' && race.postTimeUtc >= now)
                ||
                (race.trackAbbreviation == 'GOLDENHOUR' && [1,3].includes(race.raceNumber))
                ||
                (['EQ','SW','STRONACH5','COAST2COAST'].includes(race.trackAbbreviation) && race.raceNumber == 1)
                ||
                (race.postTimeUtc - now > -30 * 60 * 1000)
              )
            );
          }).map(r => {
            r.raceUrl = Functions.getRaceUrl(r.id, r.date, r.trackName, r.raceNumber);
            return r;
          });
          if(validRaces.length) {
            validRaces.forEach((validRace) => {
              let oldRace = this.upcomingRaceList.find(r => r.id == validRace.id);
              if(oldRace) {
                validRace.dataVersion = oldRace.dataVersion;
                oldRace = validRace;
              } else {
                validRace.dataVersion = 0;
                this.upcomingRaceList.push(validRace);
              }
              this.compactRaces[validRace.id] = validRace;
            });
            validRaces.map((r)=> {
              if(r.updatedAt > entireUpdateTime) {
                entireUpdateTime = r.updatedAt;
              }
            });
          }
          this.raceIds = Object.keys(this.compactRaces).map(id => parseInt(id));
          this.upcomingRaces.next(validRaces);
          this.subscribeToRaceVersionFeed();
        }
      },
      (error) => {
        this.subscribeToRaceVersionFeed();
      });
  }

  public getRaceById(id: number, updateCache = false): CacheObservable<RaceDetails> {
    if (!this.isRunning) {
      return null;
    }
    return new CacheObservable((subscriber) => {
      if (!updateCache) {
        if (id in this.raceDetails) {
          const entry = this.raceDetails[id];
          subscriber.next(entry.value);
          if (!entry.canBeRefreshed) {
            subscriber.complete();
            return () => {
            };
          }
        }
      }
      const serverGet = this.racesService
        .apiRacesIdGet(id)
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(
          (raceDetails) => {
            if (raceDetails && raceDetails.hasAccess) {
              this.updateRaceDetails(raceDetails);
            }
            subscriber.next(raceDetails);
          },
          (error) => subscriber.error(error),
          () => subscriber.complete()
        );

      return () => serverGet.unsubscribe();
    });
  }

  public prefetchRace(id: number): void {
    if (!this.isRunning) {
      return null;
    }
    const entry = this.raceDetails[id];
    if (entry && !entry.canBeRefreshed) {
      return;
    }
    this.racesService
      .apiRacesIdGet(id)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((raceDetails) => {
        this.updateRaceDetails(raceDetails);
      });
  }

  public updatePostTime(raceId: number, postTime: number): void {
    this.getCompactRace(raceId).postTimeUtc = postTime;
  }

  /// this may return an empty compact race that will be filled in later
  public getCompactRace(id: number): PublicCompactRace {
    const cachedVersion = this.compactRaces[id];
    if (cachedVersion) {
      return cachedVersion;
    }
    return (this.compactRaces[id] = {
      id,
    });
  }

  public saveHandicapping(race: RaceDetails): Subject<{}> {
    if (!this.isRunning) {
      return null;
    }
    race.handicapping.version += 1;
    const subject = new Subject();
    if (race.handicappingSavingSubscription) {
      race.handicappingSavingSubscription.unsubscribe();
    }
    race.handicappingSavingSubscription = this.racesService
      .apiRacesHandicappingPost(race.handicapping)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe(() => {
        subject.next();
        subject.complete();
      });
    if (race.date in this._goldenHours) {
      _.forEach(this._goldenHours[race.date], (x) => {
        if (race.trackName === 'Golden Hour Wagers') {
          if (x.raceId in this.raceDetails) {
            delete this.raceDetails[x.raceId];
          }
        }
        if (x.raceId === race.id) {
          this.clearCacheGhwRaces(race.date);
          return true;
        }
      });
    }
    if (race.date in this._stronach5) {
      _.forEach(this._stronach5[race.date], (x) => {
        if (race.trackName === 'Stronach 5') {
          if (x.raceId in this.raceDetails) {
            delete this.raceDetails[x.raceId];
          }
        }
        if (x.raceId === race.id) {
          this.clearCacheStronach5Races(race.date);
          return true;
        }
      });
    }
    if (race.date in this._coastToCoast) {
      _.forEach(this._coastToCoast[race.date], (x) => {
        if (race.trackName === 'Coast To Coast Pick 5') {
          if (x.raceId in this.raceDetails) {
            delete this.raceDetails[x.raceId];
          }
        }
        if (x.raceId === race.id) {
          this.clearCacheCoastToCoastRaces(race.date);
          return true;
        }
      });
    }
    if (race.date in this._allStakesPick5) {
      _.forEach(this._allStakesPick5[race.date], (x) => {
        if (race.trackName === 'All-Stakes Pick 5') {
          if (x.raceId in this.raceDetails) {
            delete this.raceDetails[x.raceId];
          }
        }
        if (x.raceId === race.id) {
          this.clearCacheAllStakesPick5(race.date);
          return true;
        }
      });
    }
    if (race.date in this._futureStarsPick5) {
      _.forEach(this._futureStarsPick5[race.date], (x) => {
        if (race.trackName === 'Future Stars Pick 5') {
          if (x.raceId in this.raceDetails) {
            delete this.raceDetails[x.raceId];
          }
        }
        if (x.raceId === race.id) {
          this.clearCacheFutureStarsPick5(race.date);
          return true;
        }
      });
    }
    return subject;
  }

  clearCacheCoastToCoastRaces(date: string): void {
    this.allRacesByDay.pipe(take(1), takeUntil(this.componentDestroy())).subscribe((result) => {
      if (result && result?.length > 0) {
        const raceDay = _.chain(result)
          .findLast((x) => x?.date === date)
          .value();
        if (raceDay) {
          const races = _.findLast(raceDay.tracks, (x) => x.abbreviation === 'EQK')?.races;
          if (races?.length) {
            _.forEach(races, (r) => {
              if (r.id in this.raceDetails) {
                delete this.raceDetails[r.id];
              }
            });
          }
        }
      }
    });
  }
  clearCacheAllStakesPick5(date: string): void {
    this.allRacesByDay.pipe(take(1), takeUntil(this.componentDestroy())).subscribe((result) => {
      if (result && result?.length > 0) {
        const raceDay = _.chain(result)
          .findLast((x) => x?.date === date)
          .value();
        if (raceDay) {
          const races = _.findLast(raceDay.tracks, (x) => x.abbreviation === 'EQX')?.races;
          if (races?.length) {
            _.forEach(races, (r) => {
              if (r.id in this.raceDetails) {
                delete this.raceDetails[r.id];
              }
            });
          }
        }
      }
    });
  }
  clearCacheFutureStarsPick5(date: string): void {
    this.allRacesByDay.pipe(take(1), takeUntil(this.componentDestroy())).subscribe((result) => {
      if (result && result?.length > 0) {
        const raceDay = _.chain(result)
          .findLast((x) => x?.date === date)
          .value();
        if (raceDay) {
          const races = _.findLast(raceDay.tracks, (x) => x.abbreviation === 'SWD')?.races;
          if (races?.length) {
            _.forEach(races, (r) => {
              if (r.id in this.raceDetails) {
                delete this.raceDetails[r.id];
              }
            });
          }
        }
      }
    });
  }
  clearCacheGhwRaces(date: string): void {
    this.allRacesByDay.pipe(take(1), takeUntil(this.componentDestroy())).subscribe((result) => {
      if (result && result?.length > 0) {
        const raceDay = _.chain(result)
          .findLast((x) => x?.date === date)
          .value();
        if (raceDay) {
          const races = _.findLast(raceDay.tracks, (x) => x.abbreviation === 'GOLDENHOUR')?.races;
          if (races?.length) {
            _.forEach(races, (r) => {
              if (r.id in this.raceDetails) {
                delete this.raceDetails[r.id];
              }
            });
          }
        }
      }
    });
  }

  clearCacheStronach5Races(date: string): void {
    this.allRacesByDay.pipe(take(1), takeUntil(this.componentDestroy())).subscribe((result) => {
      if (result && result?.length > 0) {
        const raceDay = _.chain(result)
          .findLast((x) => x?.date === date)
          .value();
        if (raceDay) {
          const races = _.findLast(raceDay.tracks, (x) => x.abbreviation === 'STRONACH5')?.races;
          if (races?.length) {
            _.forEach(races, (r) => {
              if (r.id in this.raceDetails) {
                delete this.raceDetails[r.id];
              }
            });
          }
        }
      }
    });
  }

  public getUserSubscriptionModel(inValidate: boolean): CacheObservable<UserSubscriptionModel> {
    if (!this.isRunning) {
      return null;
    }
    return new CacheObservable((subscriber) => {
      if (inValidate === true) {
        this._planViewModalCache = null;
      }
      if (isNotEmpty(this._planViewModalCache)) {
        if (this._planViewModalCache.hasValue()) {
          subscriber.next(this._planViewModalCache.value);
        }

        if (!this._planViewModalCache.canBeRefreshed) {
          subscriber.complete();
          return () => {
          };
        }
      }
      const userAccountSubscription = this.getAccountStatus()
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(
          (response) => {
            const userSubscriptionModel: UserSubscriptionModel = {
              weekPassStartDate: '',
              hasMonthly: false,
              hasWarrior: false,
              hasWeekPass: false,
              hasWeekendPass: false,
              hasMeetPass: false,
              hasDayPasses: [],
              needsNewPaymentMethod: false,
              recurringSubscriptions: [],
              dayPasses: [],
              meetPasses: [],
              weekPasses: [],
              weekendPasses: [],
              freePass: {},
              isNotFreePass: false,
              weekendPassStartDate: null,
              firstSubscription: null,
              currentDaySubscription: null,
            };
            if (isNotEmpty(response?.account) && isNotEmpty(response?.account?.activeSubscriptions)) {
              response?.account?.activeSubscriptions.map((userSubscription) => {
                if (userSubscription.type === 'RECURRING') {
                  userSubscriptionModel.recurringSubscriptions.push(userSubscription);
                  userSubscriptionModel.hasMonthly = userSubscription.code === 'UNLIMITED';
                  userSubscriptionModel.hasWarrior = userSubscription.code === 'WEEKENDWARRIOR';
                  userSubscriptionModel.hasMonthly = userSubscription.code === 'EAPKG';
                  userSubscriptionModel.hasMonthly = userSubscription.code === 'EMPKG';
                  userSubscriptionModel.hasWarrior = userSubscription.code === 'EWPKG';

                  userSubscriptionModel.hasMonthly = userSubscription.code === '113-USD-Yearly';
                  userSubscriptionModel.hasMonthly = userSubscription.code === '113-USD-Monthly';
                  userSubscriptionModel.hasWarrior = userSubscription.code === '114-USD-Monthly';
                }

                if (userSubscription.code === 'WEEKEND'
                    || userSubscription.code === ProductCodes.singleWeekend
                    || userSubscription.code === ProductCodes.specialWeekend) {
                  userSubscriptionModel.weekendPasses.push(userSubscription);
                  userSubscriptionModel.hasWeekendPass = userSubscription.code === 'WEEKEND';
                  userSubscriptionModel.hasWeekendPass = userSubscription.code === ProductCodes.singleWeekend;
                  userSubscriptionModel.hasWeekendPass = userSubscription.code === ProductCodes.specialWeekend;
                }

                if (userSubscription.code === 'WEEKPASS' || userSubscription.code === '129-USD-Weekly') {
                  userSubscriptionModel.weekPasses.push(userSubscription);
                  userSubscriptionModel.hasWeekPass = userSubscription.code === 'WEEKPASS';
                  userSubscriptionModel.hasWeekPass = userSubscription.code === '129-USD-Weekly';
                }

                if (userSubscription.code === 'WPASS') {
                  userSubscriptionModel.weekendPasses.push(userSubscription);
                  userSubscriptionModel.hasWeekendPass = userSubscription.code === 'WPASS';
                }

                if (
                  userSubscription.code === 'SDSTPASS'
                  || userSubscription.code === 'SDATPASS'
                  || userSubscription.code === ProductCodes.singleTrack
                  || userSubscription.code === ProductCodes.singleDay
                  || userSubscription.code === ProductCodes.specialSingleTrack
                  || userSubscription.code === ProductCodes.specialAllTrack
                  || userSubscription.code === 'StarterPlan-USD-Daily'
                  || userSubscription.code === 'FreeSignup-USD-Daily'
                ) {
                  userSubscriptionModel.dayPasses.push(userSubscription);
                  userSubscriptionModel.hasDayPasses.push(userSubscription.startDate);
                }

                if (userSubscription.code === 'DAY') {
                  userSubscriptionModel.dayPasses.push(userSubscription);
                  userSubscriptionModel.hasDayPasses.push(userSubscription.startDate);
                }

                if (userSubscription.type === 'MEET') {
                  userSubscriptionModel.meetPasses.push(userSubscription);
                  userSubscriptionModel.hasMeetPass = userSubscription.type === 'MEET';
                }

                if (userSubscription.code === 'FREE') {
                  userSubscriptionModel.freePass = userSubscription;
                  userSubscriptionModel.isNotFreePass = false;
                } else {
                  userSubscriptionModel.isNotFreePass = true;
                }
                userSubscriptionModel.needsNewPaymentMethod =
                  userSubscription.subscriptionStatus === 'GracePeriod' ||
                  userSubscription.subscriptionStatus === 'PastDue';
              });
            }
            if (
              isNotEmpty(response?.account?.authorizations?.authorizations) &&
              response?.account?.authorizations?.authorizations[moment().tz('America/Los_Angeles').format('yyyy/MM/DD')]
            ) {
              const todayPlan =
                response?.account?.authorizations?.authorizations[
                  moment().tz('America/Los_Angeles').format('yyyy/MM/DD')
                  ];
              userSubscriptionModel.currentDaySubscription = todayPlan;
              if (todayPlan.planCode === 'FREE') {
                userSubscriptionModel.freePass = {
                  firstRaceUrl: todayPlan.redirectUrl,
                  startDate: moment().tz('America/Los_Angeles').format('yyyy/MM/DD'),
                };
                userSubscriptionModel.isNotFreePass = false;
              } else {
                userSubscriptionModel.isNotFreePass = true;
              }
            }
            userSubscriptionModel.firstSubscription = _.orderBy(
              []
                .concat(userSubscriptionModel.recurringSubscriptions)
                .concat(userSubscriptionModel.weekPasses)
                .concat(userSubscriptionModel.weekendPasses)
                .concat(userSubscriptionModel.dayPasses),
              (o: any) => {
                return moment(o.startDate, ['yyyy/MM/DD']);
              },
              ['asc']
            ).find((sub) => sub);

            // tslint:disable-next-line: no-use-before-declare
            this._planViewModalCache = new CacheEntry<UserSubscriptionModel>(userSubscriptionModel);
            subscriber.next(userSubscriptionModel);
            subscriber.complete();
          },
          (error) => subscriber.error(error),
          () => subscriber.complete
        );
      return () => userAccountSubscription.unsubscribe();
    });
  }

  public getAccesViewModel(): CacheObservable<FeatureFlagService> {
    if (!this.isRunning) {
      return null;
    }
    return new CacheObservable((subscriber) => {
      const userAccountSubscription = this.accountService
        .apiAccountMyAccountGet()
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(
          (response) => {
            if (response?.authorizations?.authorizations) {
              subscriber.next(
                new FeatureFlagService({
                  model: response.authorizations.authorizations,
                })
              );
            }
            subscriber.complete();
          },
          (error) => subscriber.error(error),
          () => subscriber.complete
        );
      return () => userAccountSubscription.unsubscribe();
    });
  }

  public getAccountCurrentSubscriptionStatus(): CacheObservable<CurrentSubscriptionStatusResponse> {
    if (!this.isRunning) {
      return null;
    }
    return new CacheObservable((subscriber) => {
      const userStatusSubscription = this.accountService
        .apiAccountMyAccountGet()
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(
          (account) => {
            subscriber.next(_.first(account.activeSubscriptions));
            subscriber.complete();
          },
          (error) => {
            subscriber.error(error);
          },
          () => subscriber.complete
        );

      return () => userStatusSubscription.unsubscribe();
    });
  }

  public getAccountStatus(): CacheObservable<UserStatusModel> {
    if (!this.isRunning) {
      return null;
    }
    return new CacheObservable((subscriber) => {
      const userStatusSubscription = this.accountService
        .apiAccountMyAccountGet()
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe(
          (account) => {
            subscriber.next({
              subscription: _.first(account.activeSubscriptions),
              account,
            });
            subscriber.complete();
          },
          (error) => {
            subscriber.error(error);
          },
          () => subscriber.complete
        );

      return () => userStatusSubscription.unsubscribe();
    });
  }

  public checkStronach5(raceDate: string): void {
    if (environment.enable.stronach5 && !this._stronach5[raceDate]) {
      this._stronach5[raceDate] = [];
      this.racesService
        .apiRacesStronachFivePost({
          raceDate: moment(raceDate, 'yyyy/MM/DD').format('yyyy-MM-DDT00:00:00.000'),
        })
        .subscribe((races) => {
          this._stronach5[raceDate] = races ? races : null;
          this.stronach5$.next(this._stronach5);
        });
    }
  }

  public checkGoldenHour(raceDate: string): void {
    if (environment.enable.enableGoldenHours && !this._goldenHours[raceDate]) {
      this._goldenHours[raceDate] = [];
      this.racesService
        .apiRacesSpecialWagersPost({
          raceDate: moment(raceDate, 'yyyy/MM/DD').format('yyyy-MM-DDT00:00:00.000'),
          wagerName: 'Golden Hour Pick 4',
        })
        .subscribe((r) => {
          this._goldenHours[raceDate] = r ? r : null;
          this.goldenHours$.next(this._goldenHours);
        });
    }
  }

  public checkCoastToCoast(raceDate: string): void {
    if (environment.enable.enableCoastToCoast && !this._coastToCoast[raceDate]) {
      this._coastToCoast[raceDate] = [];
      this.racesService
        .apiRacesSpecialWagersPost({
          raceDate: moment(raceDate, 'yyyy/MM/DD').format('yyyy-MM-DDT00:00:00.000'),
          wagerName: 'Coast To Coast',
        })
        .subscribe((r) => {
          this._coastToCoast[raceDate] = r ? r : null;
          this.coastToCoast$.next(this._coastToCoast);
        });
    }
  }

  public checkAllStakesPick5(raceDate: string): void {
    if (environment.enable.enableCoastToCoast && !this._allStakesPick5[raceDate]) {
      this._allStakesPick5[raceDate] = [];
      this.racesService
        .apiRacesSpecialWagersPost({
          raceDate: moment(raceDate, 'yyyy/MM/DD').format('yyyy-MM-DDT00:00:00.000'),
          wagerName: 'All-Stakes Pick 5',
        })
        .subscribe((r) => {
          this._allStakesPick5[raceDate] = r ? r : null;
          this.allStakesPick5$.next(this._allStakesPick5);
        });
    }
  }

  public checkFutureStarsPick5(raceDate: string): void {
    if (environment.enable.enableCoastToCoast && !this._futureStarsPick5[raceDate]) {
      this._futureStarsPick5[raceDate] = [];
      this.racesService
        .apiRacesSpecialWagersPost({
          raceDate: moment(raceDate, 'yyyy/MM/DD').format('yyyy-MM-DDT00:00:00.000'),
          wagerName: 'Future Stars Pick 5',
        })
        .subscribe((r) => {
          this._futureStarsPick5[raceDate] = r ? r : null;
          this.futureStarsPick5$.next(this._futureStarsPick5);
        });
    }
  }

  public getSettingByTrackName(trackName: string): string {
    const tracks = _.filter(this.tracksWithVideo, (x) => x.name === trackName);
    if (tracks?.length === 1) {
      return tracks[0].value;
    } else {
      return '';
    }
  }

  public getCodeByTrackName(trackName: string): string {
    const tracks = _.filter(this.tracksWithVideo, (x) => x.name === trackName);
    if (tracks?.length === 1) {
      return tracks[0].code;
    } else {
      return '';
    }
  }

  public getSettingByTrackAbbreviation(trackAbbreviation: string): string {
    const tracks = _.filter(this.tracksWithVideo, (x) => x.trackAbbreviation === trackAbbreviation);
    if (tracks?.length === 1) {
      return tracks[0].code;
    } else {
      return '';
    }
  }

  getTrackAbbreviationByTrackName(trackName: string): string {
    return this.allRacesCurrent.find(x => x.trackName === trackName)?.trackAbbreviation;
  }

  private constructRacesByDay(allRaces: PublicCompactRace[]): RacingDay[] {
    if (!this.isRunning) {
      return null;
    }
    return _.chain(allRaces)
      .groupBy((r) => r.date)
      .map((racingDayRaces, racingDate) => {

        const result = this.userAuths[racingDate];

        return ({
          date: racingDate,
          tracks: _.chain(racingDayRaces)
            .groupBy((r) => r.trackId)
            .filter((r) => {
              if (result?.unavailableTracks != null) {
                return r.filter(race => {
                  return result?.unavailableTracks?.length == 0
                    || (result?.unavailableTracks?.length > 0 && result?.unavailableTracks.find(_race => _race === race.trackAbbreviation) == null);
                }).length > 0;
              }
              return true;
            })
            .map((trackDayRaces) => ({
                id: trackDayRaces[0].trackId,
                name: trackDayRaces[0].trackName,
                abbreviation: trackDayRaces[0].trackAbbreviation,
                races: _.sortBy(trackDayRaces, 'raceNumber'),
                isHotTrack: trackDayRaces[0].isHotTrack,
                isCancelled: _.every(trackDayRaces, ['isCancelled', true]),
                hasScottyPicks: _.some(trackDayRaces, ['raceHasScottyPicks', true]),
              })
            )
            .sortBy('name')
            .value(),
        });
      })
      .sortBy('date')
      .value();
  }

  private refreshAllRaces(): void {
    if (!this.isRunning) {
      return null;
    }
    this.racesService
      .apiRacesAllGet()
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((allRaces) => {
        if (!environment.enable.enableGoldenHours) {
          allRaces = _.filter(allRaces, (x) => x.trackName && x.trackName !== 'Golden Hour Wagers');
        }
        if (!environment.enable.enableCoastToCoast) {
          allRaces = _.filter(allRaces, (x) => x.trackName && x.trackName !== 'Coast To Coast Pick 5');
          allRaces = _.filter(allRaces, (x) => x.trackName && x.trackName !== 'All-Stakes Pick 5');
          allRaces = _.filter(allRaces, (x) => x.trackName && x.trackName !== 'Future Stars Pick 5');
        }
        if (!environment.enable.stronach5) {
          allRaces = _.filter(allRaces, (x) => x.trackName && x.trackName !== 'Stronach 5');
        } else {
          const today = moment().tz('America/Los_Angeles').format('yyyy/MM/DD');
          const stronachRaces = _.filter(
            allRaces,
            (x) => x.trackName && x.trackName === 'Stronach 5' && x.date === today
          );
          if (stronachRaces?.length > 0) {
            this.stronach5FirstLink$.next(
              `/races/${stronachRaces[0].id}/${stronachRaces[0].date
                .toString()
                .replace(/\//g, '-')}_${stronachRaces[0].trackName.replace(
                / /g,
                '-'
              )}_${stronachRaces[0].raceNumber.toString()}`
            );
          }
        }
        this.updateAllRaces(allRaces);
      });
  }

  private updateRaceDetails(newDetails: RaceDetails): void {
    if (!this.isRunning) {
      return;
    }
    if (newDetails) {
      if (newDetails.id in this.raceDetails) {
        const localHandicapping = this.raceDetails[newDetails.id].value.handicapping;
        // if (localHandicapping.version < newDetails.handicapping.version) {
        //   console.error('server has a newer handicapping version. eh?', localHandicapping, newDetails.handicapping);
        // }
        newDetails.handicapping = localHandicapping;
      }
      if (newDetails.liveOdds) {
        newDetails.liveOddsTuple = LiveOddsXform.toLiveOddsTuple(newDetails.liveOdds);
      }
      this.raceDetails[newDetails.id] = new CacheEntry<RaceDetails>(newDetails);
    }
  }

  public updateWillpays(raceId: number): void {
    if (this.willpaySubscription) {
      this.willpaySubscription.unsubscribe();
    }
    this.willpaySubscription = this.liveUpdates.subscribeToWillpays(raceId)
      .subscribe((result: WillpayDto1[]) => {
        this.willpays$.next(result ?? []);
    });
  }

  public updateProbables(raceId: number): void {
    if (this.probableSubscription) {
      this.probableSubscription.unsubscribe();
    }
    this.probableSubscription = this.liveUpdates.subscribeToProbables(raceId)
      .subscribe((result: ProbablesDto) => {
        this.probables$.next(result ?? {});
      });
  }

  public updatePools(raceId: number): void {
    if (this.poolSubscription) {
      this.poolSubscription.unsubscribe();
    }
    this.poolSubscription = this.liveUpdates.subscribeToPools(raceId)
      .subscribe((result: PoolsDto) => {
        this.pools$.next(result ?? {});
      });
  }


  public updateCompactRace(race: PublicCompactRace, isSingle = false): void {
    if (!this.isRunning) {
      return;
    }
    const cachedVersion = this.compactRaces[race.id];
    if (!cachedVersion) {
      this.compactRaces[race.id] = race;
    } else {
      cachedVersion.postTimeUtc = race.postTimeUtc;
      cachedVersion.date = race.date;
      cachedVersion.trackName = _.replace(race.trackName, ' Wager', '');
      cachedVersion.trackAbbreviation = race.trackAbbreviation;
      cachedVersion.raceNumber = race.raceNumber;
      cachedVersion.isHotTrack = race.isHotTrack;
      cachedVersion.trackId = race.trackId;
      cachedVersion.raceStatus = race.raceStatus;
      cachedVersion.raceDescription = race.raceDescription;
      cachedVersion.dependantRaceId = race.dependantRaceId;
      cachedVersion.postTimeUpdatedAt = race.postTimeUpdatedAt;
      cachedVersion.raceStatusUpdatedAt = race.raceStatusUpdatedAt;
      cachedVersion.trackAddress = race.trackAddress;
      cachedVersion.trackCity = race.trackCity;
      cachedVersion.trackCountry = race.trackCountry;
      cachedVersion.trackState = race.trackState;
      cachedVersion.trackWeatherUrl = race.trackWeatherUrl;
      cachedVersion.isCancelled = race.isCancelled;
    }

    if (isSingle) {
      // this.allRacesByDay.next(this.constructRacesByDay(this.allRaces));
    }
  }
}

// export interface CacheResponse<T> {
//   data: T;
//   isFirst: boolean;
// }

declare module '../../../swagger-codegen/model/raceDetails' {
  interface RaceDetails {
    handicappingSavingSubscription: Subscription;
  }
}
