import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountService } from '@swagger-codegen/*';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GroupAdminGuard implements CanActivate {
  constructor(private accountService: AccountService) {}

  canActivate(): Observable<boolean> {
    return this.accountService.apiAccountMyAccountGet().pipe(
      map((result) => {
        return !!(result?.authorizations?.group?.name && result?.authorizations?.group?.isAdmin);
      })
    );
  }
}
