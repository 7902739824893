<div class="logo-white"></div>
<section class="login responsive-width">
  <div class="form-section">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()">
      <div class="form-row">
        <div class="img-login"></div>
      </div>
      <div class="form-row">
        <label for="inputEmail" class="col-form-label">Email</label>
        <input
          type="email"
          formControlName="email"
          class="form-control"
          id="inputEmail"
          placeholder="Email"
          [ngClass]="{
            'is-valid': isValid('email'),
            'is-invalid': isInValid('email')
          }"
        />
      </div>
      <div class="form-row position-relative">
        <label for="inputPassword" class="col-form-label">Password</label>
        <input
          [type]="showPassword?'text':'password'"
          formControlName="password"
          class="form-control"
          id="inputPassword"
          placeholder="Password"
          [ngClass]="{
            'is-valid': isValid('password'),
            'is-invalid': isInValid('password')
          }"
        />
        <img src="assets/icons/eye.svg" alt="eye" (click)="toggleShowPassword()" class="img-eye">
      </div>
      <div class="form-row" *ngIf="loginService.loginError">
        <p class="text-danger">{{ loginService.loginError }}</p>
      </div>
      <div class="form-row mt-3">
        <button type="submit" [disabled]="loginForm.invalid" class="btn m-auto btn-primary btn-login">LOG IN</button>
      </div>

      <div class="form-row mt-2">
        <a class="link m-auto" routerLink="/signup-plans">Signup</a>
      </div>
      <div class="form-row mt-2">
        <span class="link m-auto" (click)="open(content)">Forgot password?</span>
      </div>
    </form>
  </div>
</section>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Forgot password?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <p>Enter your email here and we'll send you a link to reset your password:</p>
    </div>
    <div class="form-group">
      <input
        type="email"
        [(ngModel)]="resetEmail"
        #reset="ngModel"
        class="form-control"
        placeholder="Email"
        email
        (keydown.enter)="modal.close(reset)"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="modal.close(reset)"
      [disabled]="reset.invalid || !reset.dirty"
    >
      Send password reset link
    </button>
  </div>
</ng-template>
