import { PublicCompactRace, PublicTicket } from '@swagger-codegen/*';
import _ from 'lodash';
import moment from 'moment';

export class Functions {
  constructor() {}

  public static SummarizeTicketSelections(ticket: PublicTicket): any {
    if (ticket?.slots) {
      ticket.expandedSlots = _.map(ticket.slots, (slot) => {
        const expanded = {};
        for (const selection of slot.selections) {
          expanded[selection.bettingNumber] = true;
        }
        return expanded;
      });
    }
  }

  public static getMinutesToPost(race: PublicCompactRace): number | null {
    if (!race || !race.postTimeUtc) {
      return null;
    }
    return Math.ceil((race.postTimeUtc - Date.now()) / 1000 / 60);
  }

  public static getMinutesToPostFromTime(postTimeUtc: number): number | null {
    if (!postTimeUtc) {
      return null;
    }
    return Math.ceil((postTimeUtc - Date.now()) / 1000 / 60);
  }

  public static checkTooltipsShown(nameTooptip: string): boolean {
    let temp = JSON.parse(localStorage.getItem('tooltipsShown'));
    let needToShow = false;
    if (!(temp && _.find(temp, (x) => x === nameTooptip))) {
      needToShow = true;
      if (!temp) {
        temp = [];
      }
      temp.push(nameTooptip);
      localStorage.setItem('tooltipsShown', JSON.stringify(temp));
    }
    return needToShow;
  }

  public static getRaceUrl(raceId: number, date: string, track: string, n: number): string {
    return `/races/${raceId}/${_.replace(date, /\//g, '-')}_${_.replace(track, / /g, '-')}_${n}`;
  }

  public static getWagerNameByTypeAndSlotCount(wagerType: string, slotCount: number): string {
    if (!wagerType) {
      return null;
    }
    switch (wagerType) {
      case 'Horizontal':
        switch (slotCount) {
          case 2:
            return 'Daily Double';
          case 3:
            return 'Pick 3';
          case 4:
            return 'Pick 4';
          case 5:
            return 'Pick 5';
          case 6:
            return 'Pick 6';
        }
        break;
      case 'Vertical':
        switch (slotCount) {
          case 2:
            return 'Exacta';
          case 3:
            return 'Trifecta';
          case 4:
            return 'Superfecta';
          case 5:
            return 'Super Hi 5';
        }
    }
    return null;
  }

  public static isSafari(): boolean {
    try {
      const isSafari =
        !!navigator.userAgent.match(/safari/i) &&
        !navigator.userAgent.match(/chrome/i) &&
        typeof document.body.style.webkitFilter !== 'undefined';
      return isSafari;
    } catch (e) {}
    return false;
  }

  public static typeSort(x: any, sortColumn: string): any {
    let prop;
    const sb = sortColumn.indexOf('.');
    prop = sb !== -1 ? x[sortColumn.substr(0, sb)][sortColumn.substr(sb + 1)] : x[sortColumn];
    switch (typeof prop) {
      case 'number':
        return prop === null || prop === undefined ? 0 : prop;
      case 'string':
        return prop === null || prop === undefined ? '' : prop;
      case 'object':
        return prop === null || prop === undefined ? '' : prop;
      case 'undefined':
        return prop === null || prop === undefined ? '' : prop;
      default:
        return prop;
    }
  }
}

declare module '../swagger-codegen/model/publicTicket' {
  interface PublicTicket {
    expandedSlots?: Array<{ [bettingNumber: number]: boolean }>;
  }
}

export interface ParsedRaceUrl {
  raceId: string;
  date: string;
  track: string;
  raceNumber: string;
}

export const emptyParsedRaceUrl: ParsedRaceUrl = {
  raceId: '',
  date: '',
  track: '',
  raceNumber: '',
};

export const createDateNoTime = (currentDate?: string): Date => {
  let _date = new Date();
  if (!_.isEmpty(currentDate)) {
    _date = new Date(currentDate);
  }
  _date.setHours(0, 0, 0, 0);
  return _date;
};

export const isNotEmpty = (value: any): boolean => !_.isEmpty(value);
export const isWeekendDay = (date: string): boolean => _.includes([0, 5, 6], createDateNoTime(date).getDay());
export const isPastDate = (date: Date, currentDate: Date): boolean => date < currentDate;
export const isToday = (date: Date, currentDate: Date): boolean => date === currentDate;

export const isValidRaceUrl = (raceUrl: string): boolean => isNotEmpty(raceUrl) && raceUrl.indexOf('/') > -1;

export const getDateTrackRaceNumberFromUrl = (raceUrl: string): ParsedRaceUrl => {
  // if raceUrl is empty or there are no / in the url, return empty.
  if (isValidRaceUrl(raceUrl)) {
    const _url = _.flatMap(raceUrl.split('/').map((item) => item.split('_')));
    if (_url.length === 6) {
      return {
        raceId: _url[2],
        date: _url[3].replace(/-/g, '/'),
        track: _url[4].replace(/-/g, ' '),
        raceNumber: _url[5],
      };
    }
    if (_url.length === 8) {
      return {
        raceId: _url[2],
        date: _url[5].replace(/-/g, '/'),
        track: _url[6].replace(/-/g, ' '),
        raceNumber: _url[7],
      };
    }
  }
  return emptyParsedRaceUrl;
};

export const formatDate = (dateToFormat): string => {
  if (dateToFormat) {
    return moment(dateToFormat, ['YYYY/MM/DD']).format('MM/DD/YYYY');
  } else {
    return '';
  }
};

export const formatDateWithShortYear = (dateToFormat: string, format: string[] = ['YYYY/MM/DD']): string => {
  if (dateToFormat) {
    return moment(dateToFormat, format).format('MM/DD/YY');
  } else {
    return '';
  }
};

export const createUuidv4 = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line:no-bitwise
    const r = (Math.random() * 16) | 0;
    // tslint:disable-next-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('deviceId');
  if (_.isEmpty(deviceId)) {
    deviceId = createUuidv4();
  }
  return deviceId;
};

export const setDeviceId = (deviceId: string): any => {
  try {
    localStorage.setItem('deviceId', deviceId);
  } catch (ex) {
    throw ex;
  }
};

export const isAuthenticated = (): boolean =>
  localStorage.getItem('access_token') !== null && localStorage.getItem('access_token').length > 0;
