<div class="row py-4">
  <div class="btn-group" role="group" aria-label="Basic example" [ngClass]="{ 'btn-mobile-group': (mobile$ | async) }">
    <button
      type="button"
      class="btn btn-primary font-m"
      [ngClass]="{ active: type === 'FreeTrial' }"
      (click)="changeType('FreeTrial')"
    >
      Free Signup
    </button>
    <button
      type="button"
      class="btn btn-primary font-m"
      [ngClass]="{ active: type === 'Memberships' }"
      (click)="changeType('Memberships')"
    >
      Memberships
    </button>
    <button
      type="button"
      class="btn btn-primary font-m"
      [ngClass]="{ active: type === 'Passes' }"
      (click)="changeType('Passes')"
    >
      Passes
    </button>
  </div>
</div>
<div class="row m-0 align-items-center">
  <ng-container *ngIf="type === 'FreeTrial'">
    <ng-container *ngFor="let item of freetrial">
      <app-plan-passes [plan]="item" (submit)="purchase(item)" [promoCode]="promoCode"></app-plan-passes>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="type === 'Memberships'">
    <ng-container *ngFor="let item of memberships">
      <app-plan-memberships [plan]="item" (submit)="purchase(item)" [promoCode]="promoCode"></app-plan-memberships>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="type === 'Passes'">
      <div style="display:grid;grid-template-columns: repeat(3,minmax(0,1fr));gap:20px;">
      <ng-container *ngFor="let item of passes.slice(0,3)">
        <app-plan-passes [plan]="item" (submit)="purchase(item)" [promoCode]="promoCode"></app-plan-passes>
      </ng-container>
      </div>
    <div style="display:grid;grid-template-columns: repeat(4,minmax(0,1fr));gap:20px;">
      <ng-container *ngFor="let item of passes.slice(3)">
        <app-plan-passes [plan]="item" (submit)="purchase(item)" [promoCode]="promoCode"></app-plan-passes>
      </ng-container>
    </div>
  </ng-container>
</div>
<!-- <app-promo-code (promoCodeIsUpdated)="setPromoCode($event)"></app-promo-code> -->
