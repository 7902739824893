export interface BettingNumberOdds {
  bettingNumber?: number;
  formattedOdds?: string;
  isFavorite?: boolean;
  isScratched?: boolean;
  isAnEdgeBet?: boolean;
  wasAnEdgeBet?: boolean;
}

export interface LiveOddsTupleResponse {
  raceId?: number;
  bettingNumberOdds?: BettingNumberOdds[];
}

export interface LiveOddsTuple {
  raceId?: number;
  bettingNumberOdds?: { [key: number]: BettingNumberOdds };
}

export class LiveOddsXform {
  public static toLiveOddsTuple(liveOddsResponse: LiveOddsTupleResponse): LiveOddsTuple {
    const liveOddsReformat: LiveOddsTuple = {};
    if (liveOddsResponse && liveOddsResponse.raceId) {
      liveOddsReformat.raceId = liveOddsResponse.raceId;
    }

    if (liveOddsResponse && liveOddsResponse.bettingNumberOdds) {
      liveOddsReformat.bettingNumberOdds = liveOddsResponse.bettingNumberOdds.reduce((object, bno) => {
        object[bno.bettingNumber] = bno;
        return object;
      }, {}) as { [key: number]: BettingNumberOdds };
    }
    return liveOddsReformat;
  }
}
