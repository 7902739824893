<div class="popup-blue">
  <div class="close-container">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="title">
    <h1 class="font-b">Handiview Plus Features</h1>
  </div>
</div>
<div class="popup-second">
  <div class="f-s-20 font-m pt-3">
    <div class="text-left">
      Bullet Points:
      <ul>
        <li>Video Replays</li>
        <li>Access to past races</li>
      </ul>
      Upgrade your access for $1 for sdst or $3 for all other passes and plans replace the drf Formulator with
    </div>
    <div class="py-5">
      <div class="btn" routerLink="/account-settings/subscription" (click)="close()">EE Handiview Plus Upgrade</div>
    </div>
  </div>
</div>
