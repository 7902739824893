import {createAction, props} from '@ngrx/store';
export const MOBILE_MAX_WIDTH = 1023;
export const TABLET_MAX_WIDTH = 1365;

export enum ScreenActionTypes {
  SET_SCREEN = '[Screen] SetScreen',
  RESET = '[Screen] Reset',
}
export interface SetScreenPayload {
  width: number;
}

export const setScreen = createAction(ScreenActionTypes.SET_SCREEN, props<SetScreenPayload>());
export const reset = createAction(ScreenActionTypes.RESET);
