import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '@services/login.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: LoginService, private router: Router) {}

  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      if (localStorage.getItem('blocknavigation') == null) {
        return true;
      }
    }
    this.router.navigate(['/login']).then();
    return false;
  }
}
