import { BehaviorSubject, Observable } from 'rxjs';

export class VariablesService {
  public IsticketTab: boolean;

  private parentData: BehaviorSubject<boolean> = new BehaviorSubject(false);

  setParentData(data): void {
    this.parentData.next(data);
  }
  getParentData(): Observable<any> {
    return this.parentData.asObservable();
  }
}
