<div class="logo-white"></div>
<section class="signup responsive-width">
  <div class="text-center" *ngIf="!(mobile$ | async)">
    <img src="/assets/login/main-logo.png" class="img" alt="equinedge" />
  </div>
  <div class="account-section">
    <app-plans-anonymous></app-plans-anonymous>
    <div class="text-center my-3">
      <a routerLink="/login" class="btn btn-primary">Click here to sign in instead</a>
    </div>
  </div>
</section>

<!-- Custom modal setup -->
<div id="custom-overlay" (click)="removeModal()" class="hidden"></div>
<div id="custom-modal-container" class="hidden"></div>
