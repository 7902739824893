import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppSandboxService } from '@services/sandbox.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BlockUiService } from '@services/block-ui.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { AccountService } from '@swagger-codegen/*';
import { Observable } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { Router } from '@angular/router';
import _ from 'lodash';
import zxcvbn from 'zxcvbn';

@TakeUntilDestroy
@Component({
  selector: 'app-account-change-password',
  templateUrl: './account-change-password.component.html',
  styleUrls: ['./account-change-password.component.scss'],
})
export class AccountChangePasswordComponent implements OnInit {
  mobile$ = this.sb.mobile$;
  isMobile = false;
  passwordForm: FormGroup;
  componentDestroy: () => Observable<boolean>;
  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private sb: AppSandboxService,
    public accountService: AccountService,
    public toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.passwordForm = new FormGroup(
      {
        currentPassword: new FormControl(null, [Validators.required, Validators.minLength(8), this.customValidator()]),
        newPassword: new FormControl(null, [Validators.required, Validators.minLength(8), this.customValidator()]),
        confirmNewPassword: new FormControl(null, [Validators.required]),
      },
      this.passwordMatchValidator
    );

    this.mobile$.pipe(takeUntil(this.componentDestroy())).subscribe((result) => {
      this.isMobile = result;
    });
  }

  isValid(controlName: string): boolean {
    return (
      this.passwordForm.controls[controlName].valid &&
      (this.passwordForm.controls[controlName].dirty || this.passwordForm.controls[controlName].touched)
    );
  }

  isInValid(controlName: string): boolean {
    return (
      this.passwordForm.controls[controlName].invalid &&
      (this.passwordForm.controls[controlName].dirty || this.passwordForm.controls[controlName].touched)
    );
  }

  passwordMatchValidator(g: FormGroup): any {
    if (g.get('newPassword').value !== g.get('confirmNewPassword').value) {
      g.get('confirmNewPassword').setErrors({ mismatch: true });
    }
    return null;
  }

  customValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const test = zxcvbn(control.value) as {
          feedback: { warning: string; suggestions: string[] };
          score: number;
        };
        if (test.feedback.warning) {
          let error = '';
          error += test.feedback.warning + '.';
          _.forEach(test.feedback.suggestions, (result) => {
            error += ' ' + result;
          });
          return { customValidator: error };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  setNewPassword(): void {
    if (this.passwordForm.invalid) {
      this.passwordForm.controls.currentPassword.markAsTouched();
      this.passwordForm.controls.newPassword.markAsTouched();
      this.passwordForm.controls.confirmNewPassword.markAsTouched();
    } else {
      const passwordUpdateArguments = {
        currentPassword: this.passwordForm.controls.currentPassword.value,
        newPassword: this.passwordForm.controls.newPassword.value,
      };
      BlockUiService.start();
      this.accountService
        .apiAccountPasswordPut(passwordUpdateArguments, 'response')
        .pipe(
          finalize(() => {
            BlockUiService.stop();
            this.passwordForm.markAsPristine();
            this.passwordForm.markAsUntouched();
            this.passwordForm.reset();
          }),
          takeUntil(this.componentDestroy())
        )
        .subscribe((result) => {
          if (result?.isSuccess) {
            if (this.isMobile) {
              this.router.navigate(['/account-settings']).then();
            } else {
              this.cancel();
            }
            this.toast.success('Change password successful!');
          } else {
            this.toast.error('Change password failed. Please try again.');
          }
        });
    }
  }

  cancel() {
    this.closeModal$.emit(true);
  }

  cancell() {
    this.router.navigate(['/account-settings']);
  }
}
