<div
  [ngClass]="{
    'blue-product': currentPlan?.planCode === '113-USD-Monthly' && !smallSize
  }"
  class="selected-product">
  <div class="product">
    <div class="product-body">
      <ng-container
        *ngIf="currentPlan?.planCode === '113-USD-Monthly' || currentPlan?.planCode === 'StarterPlan-USD-Monthly'">
        <div *ngIf="currentPlan?.planCode === '113-USD-Monthly' && !smallSize && _promoCode === null" class="font-b pt-2">Recommended</div>
        <div *ngIf="_promoCode !== null" class="font-b pt-2">
          <br/>
        </div>
        <div *ngIf="currentPlan?.planCode === 'StarterPlan-USD-Monthly' && !smallSize" class="font-b pt-2">Great for
          Starters!
        </div>
      </ng-container>
      <ng-container
        *ngIf="currentPlan?.planCode !== '113-USD-Monthly' && currentPlan?.planCode !== 'StarterPlan-USD-Monthly'">
        <div class="pt-2 marginTop3"></div>
      </ng-container>
      <h4 [ngClass]="{
          'pt-32': currentPlan?.planCode === '113-USD-Monthly' && !smallSize
        }"
          class="font-b"
          [innerHTML]="name"
      >
      </h4>
      <ng-container *ngIf="currentPlan?.firstMonthRate !== null">
        <div class="price font-m">${{ currentPlan?.firstMonthRate }}</div>
        <div class="period font-m">then ${{ currentPlan?.amount }}{{ pricePeriod }}</div>
      </ng-container>
      <ng-container *ngIf="!currentPlan.isFreeTrial && currentPlan?.firstMonthRate === null">
        <div class="price font-m">
          ${{ currentPlan?.amount }}<span class="period">{{ pricePeriod }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="currentPlan.points.includes('Tutorial')">
        <ng-container *ngIf="!disableLink">
          <ng-container *ngIf="!(mobile$ | async)">
            <a class="pointer tutorial marginTop3" (click)="modalService.showTutorialStarterModal()">
              <img src="/assets/icons/play.svg" alt="play">
              Take a sneak peek!
            </a>
          </ng-container>
          <ng-container *ngIf="(mobile$ | async)">
            <a class="pointer tutorial marginTop3"
               href="https://www.youtube-nocookie.com/embed/vroS_I7bXco?si=ZMD2N4bFbXx6LC-R" target="_blank">
              <img src="/assets/icons/play.svg" alt="play">
              Take a sneak peek!
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="disableLink">
          <a class="pointer tutorial marginTop3">
            <img src="/assets/icons/play.svg" alt="play">
            Take a sneak peek!
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!currentPlan.points.includes('Tutorial')">
        <div class="marginTop12 pt-2"></div>
      </ng-container>
      <div *ngIf="!hideButton" class="purchase flex-grow-1 justify-content-center align-items-center">
        <div
          (click)="purchase()"
          [ngClass]="{
            disabled: disableButton,
            'green-button': currentPlan?.planCode === '113-USD-Monthly' && !disableButton,
            'red-button': currentPlan?.action === 'Cancel'
          }"
          class="button font-m"
        >
          {{ currentPlan?.action && (currentPlan?.action !== 'None' && currentPlan?.action !== 'Signup') ? currentPlan.action : 'Join' }}
        </div>
      </div>
      <div class="points">
        <div *ngFor="let item of currentPlan.points" class="point-item">
          <ng-container *ngIf="item !== 'Tutorial'">
            <i class="fa fa-check"></i>{{ item }}
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="currentPlan.isFreeTrial || currentPlan.promoCode">
        <div [innerHTML]="currentPlan?.description" class="description"></div>
      </ng-container>
      <!-- <div *ngIf="currentPlan?.coachingSession && !currentPlan.isFreeTrial && !currentPlan.promoCode"
           class="font-b coaching d-flex">
        <div class="scotty-box">
          <div class="scotty"></div>
        </div>
        <div class="flex-grow-1 scotty-text">
          {{currentPlan?.coachingSession}}
        </div>
      </div> -->
      <br/>
      <ng-container *ngIf="current?.code === currentPlan?.planCode">
        <div class="renewalDate">
          <span
          >{{ current?.subscriptionStatus === 'Active' ? 'Renewal Date' : 'Canceling Date' }} :
            {{ current?.endDate }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="pending?.code === currentPlan?.planCode">
        <div class="renewalDate">
          <span>Start Date: {{ current?.endDate }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
