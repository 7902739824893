<div class="popup-title" [ngClass]="{ 'mobile-popup': (mobile$ | async) }">
  <div class="close-container">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="start-access" *ngIf="reason === 'race'">Would you like access to the rest of {{getTodaysFreeTrack()}}?</div>
</div>
<div class="popup-second" [ngClass]="{ 'mobile-popup': (mobile$ | async) }">
  <div class="mt-2">
    <p class="f-s-20 font-b">
      <b>Upgrade to get access to the {{getTodaysFreeTrack()}} for $5.95</b>
    </p>
    <button
      class="btn btn-primary btn-access"
      (click)="purchase({})"
      [ngClass]="{ 'f-s-14': (mobile$ | async) }"
    >
      Get access now
    </button>
  </div>
  <div>
    <div class="mt-2">
      <p class="f-s-16 font-b">
        <b>Or, check out our other plans and passes</b>
      </p>
      <button
        class="btn btn-success"
        (click)="routeTo('/account-settings/subscription')"
        [ngClass]="{ 'f-s-14': (mobile$ | async) }"
      >
        Pick a plan or pass
      </button>
    </div>
  </div>
  <div class="mt-3" *ngIf="currentSub?.redirectUrl">
    <p class="f-s-14">
      <b>{{ getTodaysFreeRaceMessage() }}</b>
    </p>
    <button
      class="btn btn-primary btn-access"
      (click)="routeTo(currentSub?.redirectUrl)"
      class="f-s-12"
    >
      Access Today's Free Races
    </button>
  </div>
</div>
