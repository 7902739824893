import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContestantDetails, SimplePastPerformance, TrainerStatistics } from "@swagger-codegen/*";
import { AppSandboxService } from "@services/sandbox.service";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { TakeUntilDestroy } from "@services/take-until-destroy.decorator";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

@TakeUntilDestroy
@Component({
  selector: 'app-modal-trainer-with-carousel',
  templateUrl: './modal-trainer-with-carousel.component.html',
  styleUrls: ['./modal-trainer-with-carousel.component.scss'],
  providers: [NgbCarouselConfig],
})
export class ModalTrainerWithCarouselComponent implements OnInit {
  componentDestroy: () => Observable<boolean>;
  @Input()
  data: ContestantDetails = null;
  @Input()
  pastData: SimplePastPerformance = null;
  @Input()
  raceDate: string = null;
  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();
  backgroundId = Math.floor(Math.random() * 13);
  mobile$ = this.sb.mobile$;
  tablet$ = this.sb.tablet$;
  desktop$ = this.sb.desktop$;
  showCarousel = false;
  trainerStatistics: TrainerStatistics = null;
  claimedModel: {
    claimedPrice?: number,
    claimedAt?: string
  } = null;
  daysSinceLastRace: number;
  private modalElementId = 'custom-modal-container';

  constructor(private sb: AppSandboxService, config: NgbCarouselConfig) {
    config.wrap = false;
    config.interval = 0;
    const modal = document.getElementById(this.modalElementId);
    this.mobile$.pipe(takeUntil(this.componentDestroy())).subscribe((result) => {
      if (!result) {
        modal.style.minWidth = '550px';
        modal.style.marginLeft = '-275px'
      }
    })
    modal.style.top = '10%';
  }

  isPastPerformance(): boolean {
    return this.pastData !== null;
  }

  isFirstTimeTrainer(): boolean {
    return this.isPastPerformance()
      ? this.pastData?.isFirstTimeTrainer
      : this.data?.trainerStats?.isFirstTimeWithTrainer;
  }

  wasClaimed(): boolean {
    return !this.isPastPerformance()
      ? this.data?.trainerStats?.modalClaimedAt !== null && this.data?.trainerStats?.modalClaimedPrice !== null
      : this.pastData?.showModalClaim;
  }

  updateBackgroundId() {
    this.backgroundId = Math.floor(Math.random() * 13);
  }

  getTrainerName() {
    return this.isPastPerformance() ? this.pastData?.trainerName : this.data?.trainerStats?.name;
  }

  getHorseName() {
    return this.data?.horseName;
  }

  getTrainerWinPct() {
    const wp = this.isPastPerformance() ? (this.pastData?.trainerWinPct * 100) : (this.data?.trainerStats?.winPercentage * 100);
    return `${wp?.toFixed(0)}%`;
  }

  getWinPct(value: number) {
    return `${(value * 100)?.toFixed(0)}%`;
  }

  getClaimDate() {
    return ``;
  }

  getClaimPrice() {
    return ``;
  }

  getShortName(): string {
    const name = (this.isPastPerformance() ? this.pastData?.trainerName : this.data?.trainerStats?.name) ?? '';
    if (name) {
      if (name.indexOf(' ') === -1) {
        return name;
      } else {
        const nameArray = name.split(' ');
        const shortName = nameArray[0][0] + '. ' + nameArray.slice(1, nameArray.length).join(' ');
        if (shortName?.length > 14) {
          return shortName.slice(0, 14) + '...';
        }
        return shortName;
      }
    }
    return name;
  }

  isoStringToShortDate(isoString, format) {
    const date = new Date(isoString);

    const day = (date.getDate() + 1).toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    if (format === "MM/DD/YYYY") {
      return `${month}/${day}/${year}`;
    } else if (format === "DD/MM/YYYY") {
      return `${day}/${month}/${year}`;
    } else {
      // Handle other formats if needed
      return "Invalid Format";
    }
  }


  ngOnInit(): void {
    if (this.pastData) {
      this.trainerStatistics = this.pastData.trainerStatistics;
    } else {
      this.trainerStatistics = this.data.trainerStats.trainerStatistics;
    }

    const sortedPps = this.data?.pastPerformances.slice();
    if (sortedPps) {
      sortedPps.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    }

    const claimedRaces = sortedPps?.filter(pp => pp?.claimedAt != null);
    const layoffRaces = sortedPps?.filter(pp => pp?.isLayoffRace === true);

    const matches = claimedRaces?.map((pp) => {
      const currentPpIndex = sortedPps?.findIndex(p => p.date === this.pastData?.date);
      const claimIndex = sortedPps?.findIndex(p => p.date === pp?.date);

      if (currentPpIndex === -1) {
        return {
          claimedPrice: claimedRaces[claimedRaces.length - 1]?.claimedPrice,
          claimedAt: this.isoStringToShortDate(claimedRaces[claimedRaces.length - 1]?.claimedAt, "MM/DD/YYYY")
        };
      }
      return ((claimIndex - currentPpIndex) == -1) ? {
        claimedPrice: pp.claimedPrice,
        claimedAt: this.isoStringToShortDate(pp?.claimedAt, "MM/DD/YYYY")
      } : ((claimIndex - currentPpIndex) == -2)
        ? {
          claimedPrice: pp.claimedPrice,
          claimedAt: this.isoStringToShortDate(pp?.claimedAt, "MM/DD/YYYY")
        }
        : null;
    }).filter(pp => pp !== null)
      .reduce((acc, pp) => {
        if (acc === undefined) {
          acc = [];
        }
        if (acc?.length === 0) {
          acc.push(pp);
          return acc;
        }
        if (acc[acc?.length - 1].claimedPrice !== pp.claimedPrice &&
          acc[acc?.length - 1].claimedAt !== pp.claimedAt) {
          acc.push(pp);
        }
        return acc;
      }, []);

    this.claimedModel = matches?.length >= 2 ? matches[1] : matches?.length == 1 ? matches[0] : null;

    const daysBack = layoffRaces?.map((pp) => {
      const currentPpIndex = sortedPps?.findIndex(p => p.date === this.pastData?.date);
      const claimIndex = sortedPps?.findIndex(p => p.date === pp?.date);

      if (currentPpIndex === -1 && pp?.daysSinceLastRace > 60) {
        return layoffRaces[layoffRaces?.length - 1]?.daysSinceLastRace;
      }
      return ((claimIndex - currentPpIndex) == -1) ? pp?.daysSinceLastRace : ((claimIndex - currentPpIndex) == -2)
        ? pp?.daysSinceLastRace : null;
    })
      .filter(pp => pp !== null).reduce((acc, currVal, index, arr) => {
        if (acc === undefined) {
          acc = [];
        }
        if (acc?.length === 0) {
          acc.push(currVal);
          return acc;
        }
        if (acc[acc.length - 1] !== currVal) {
          acc.push(currVal);
        }
        return acc;
      }, []);

    this.daysSinceLastRace = daysBack?.length == 2? daysBack[1] : daysBack?.length == 1 ? daysBack[0] : null;

    const temp = +!!this.trainerStatistics?.firstTimeOffLayoff?.numberOfRaces + +!!this.trainerStatistics?.firstTimeOffClaim?.numberOfRaces
      + +!!this.trainerStatistics?.firstTimeOffTrainerChange?.numberOfRaces + +!!this.trainerStatistics?.firstTimeStarter?.numberOfRaces;

    const secondTemp = + +!!this.trainerStatistics?.secondTimeOffLayoff?.numberOfRaces + +!!this.trainerStatistics?.secondTimeOffClaim?.numberOfRaces
      + +!!this.trainerStatistics?.secondTimeOffTrainerChange?.numberOfRaces + +!!this.trainerStatistics?.secondTimeStarter?.numberOfRaces;

    this.showCarousel = temp > 1 || secondTemp > 1;
  }

  closeModal() {
    this.closeModal$.emit(true);
  }
}
