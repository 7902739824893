import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  trackCache: { event: string; metadata: { [field: string]: any } }[] = [];
  isOpen = false;

  constructor() {
    if (environment.intercom.appId !== undefined) {
      // tslint:disable
      const whenReady = window['whenReady'];
      (function () {
        const w: any = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i: any = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${environment.intercom.appId}`;
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            whenReady(() => w.attachEvent('onload', l));
          } else {
            whenReady(() => w.addEventListener('load', l, false));
          }
        }
      })();
      // tslint:enable
    }
  }

  private doWithIntercom(action: (intercom: any) => void): void {
    if (!window['Intercom']) {
      return;
    }
    action(window['Intercom']);
  }

  // update is being called by the activity service every minute.
  // this will allow the track to be quick and then we send to intercom at a later time.
  public update(): void {
    this.doWithIntercom((intercom) => intercom('update'));
    _.map(this.trackCache, (entry) => {
      this.doWithIntercom((intercom) => intercom('trackEvent', entry.event, entry.metadata));
      return entry;
    });
    this.trackCache.length = 0;
  }

  public intercomShow(): void {
    if (this.isOpen) {
      this.doWithIntercom((intercom) => intercom('hide'));
    } else {
      this.doWithIntercom((intercom) => intercom('show'));
    }
    this.isOpen = !this.isOpen;
  }

  public trackImmediate(event: string, metadata: { [field: string]: any } = null): void {
    this.doWithIntercom((intercom) => intercom('trackEvent', event, metadata));
  }

  public track(event: string, metadata: { [field: string]: any } = null): void {
    this.trackCache.push({event, metadata});
    // this.doWithIntercom(intercom => intercom('trackEvent', event, metadata));
  }

  public login(firstName: string, lastName: string, email: string): void {
    this.doWithIntercom((intercom) =>
      intercom('boot', {
        app_id: 'fx8e4g9d',
        name: `${firstName} ${lastName}`,
        email, // created_at: "<%= current_user.created_at.to_i %>" // Signup date as a Unix timestamp});
      })
    );
  }

  public visitor(): void {
    this.doWithIntercom((intercom) => intercom('boot', { app_id: 'fx8e4g9d' })
    );
  }

  public logout(): void {
    this.doWithIntercom((intercom) => intercom('shutdown'));
  }
}
