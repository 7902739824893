import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminRedirectComponent } from './components/admin-redirect/admin-redirect.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ResetDeviceComponent } from './components/reset-device/reset-device.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignupPlansComponent } from './components/signup-plans/signup-plans.component';
import { SignupComponent } from './components/signup/signup.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AuthGuard } from './guards/auth.guard';
import { GroupAdminGuard } from './guards/group-admin.guard';

const routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/authenticated-module/authenticated.module').then((m) => m.AuthenticatedModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'group-admin',
    loadChildren: () => import('./modules/group-module/group.module').then((m) => m.GroupModule),
    canActivate: [AuthGuard, GroupAdminGuard],
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'signup-plans',
    component: SignupPlansComponent,
    data: { title: 'Choose a plan' },
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: { title: 'Signup' },
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    data: { title: 'Terms of use' },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Privacy policy' },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'Reset password' },
  },
  {
    path: 'reset-device',
    component: ResetDeviceComponent,
    data: { title: 'Reset device' },
  },
  {
    path: 'admin-hgt5x',
    component: AdminRedirectComponent,
    data: { title: 'Admin' },
  },
  {
    path: 'admin-hgt5x/:id',
    component: AdminRedirectComponent,
    data: { title: 'Admin' },
  },
  { path: '**', redirectTo: '', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
