import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { LiveOddsTuple, LiveOddsXform } from '../../models/public-live-odds-tuple.model';
import _ from 'lodash';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { environment } from '../../../../../environments/environment';
import {
  ContestantScratchedDto,
  LiveOddsDto, PoolsDto, ProbablesDto,
  PublicCompactRace,
  RaceStatusDto,
  RealTimeService,
  WillpayDto1

} from '@swagger-codegen/*';
import { poller } from '@services/rxjs-operators';
import {addDays, addHours, subDays, subHours} from "date-fns";

@TakeUntilDestroy
@Injectable({
    providedIn: 'root',
})
export class PollingLiveUpdateService {
    componentDestroy: () => Observable<boolean>;

    constructor(private readonly realTimeService: RealTimeService) {
    }

    /**
     * Subscribes to live odds for a specific race
     * @param raceId - the id of the race to subscribe to
     * @returns an observable that emits live odds for the specified race
     */
    public subscribeToRaceLiveOdds(raceId: number): Observable<LiveOddsTuple> {
        return poller<LiveOddsDto>(() => this.realTimeService.apiPollLiveoddsIdGet(raceId), 15000).pipe(
            filter((x) => x?.bettingNumberOdds?.length > 0),
            map((result) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('liveodds', [environment.firebase.url.liveOdds, result]);
                }
                return LiveOddsXform.toLiveOddsTuple({
                    raceId,
                    bettingNumberOdds: result?.bettingNumberOdds,
                });
            }),
            takeUntil(this.componentDestroy())
        );
    }

    /**
     * Polls the real-time service for scratch updates for a specific race.
     * @param raceId - the id of the race to subscribe to
     * @returns an observable that emits scratch updates for the specified race
     */
    public subscribeToRaceScratches(raceId: number): Observable<ContestantScratchedDto> {
        return poller<ContestantScratchedDto>(() => this.realTimeService.apiPollScratchesIdGet(raceId), 15000).pipe(
            filter((x) => {
                const winPer = _.get(x, 'winPercents');
                return x && x?.contestantIds?.length > 0 || (winPer && Object.keys(winPer).length > 0);
            }),
            map((result) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('race scratches', [environment.firebase.url.liveOdds, result]);
                }
                return result;
            }),
            takeUntil(this.componentDestroy())
        );
    }

    public subscribeToRaceUpdates(updatedAt: string = undefined): Observable<RaceStatusDto[]> {
        // ensure we grab the latest update
        updatedAt = updatedAt ?? (new Date()).toISOString();
        return poller<RaceStatusDto[]>(() => this.realTimeService.apiPollStatusUpdatedAtGet(updatedAt), 15000).pipe(
            filter((raceStatusDtos) =>
                raceStatusDtos.filter(raceStatusDto => raceStatusDto.raceId !== undefined).length > 0),
            map((results) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('race scratches', [environment.firebase.url.liveOdds, results]);
                }
                return results;
            }),
            takeUntil(this.componentDestroy())
        );
    }

    public getRaceStatus(raceId: number): Observable<RaceStatusDto> {
        // ensure we grab the latest update
        return poller<RaceStatusDto>(() => this.realTimeService.apiPollStatusRaceIdGet(raceId), 15000).pipe(
            map((result) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('race scratches', [environment.firebase.url.liveOdds, result]);
                }
                return result;
            }),
            takeUntil(this.componentDestroy())
        );
    }

    public subscribeToUpcomingRaces(): Observable<PublicCompactRace[]> {
        const currentDate = new Date();

        const dateRange = {
          startTime: subHours(currentDate, 1).toISOString(),
          endTime: addHours(currentDate,24).toISOString(),
        }
        return poller<PublicCompactRace[]>(() => interval(3000).pipe(
            switchMap((result, index) => {
                return this.realTimeService.apiV2PollUpcomingRacesPost(dateRange, 'body')
                    .pipe(
                        map((result) => {
                            if (environment.firebase.showConsoleLog) {
                                console.log('race scratches', [environment.firebase.url.liveOdds, result]);
                            }
                            return result;
                        }),
                    )
            }), takeUntil(this.componentDestroy())
        ));
    }

    /**
     * Polls the real-time service for willpays updates for a specific race.
     * @param raceId - the id of the race to subscribe to
     * @returns an observable that emits willpays for the specified race
     */
    public subscribeToWillpays(raceId: number): Observable<WillpayDto1[]> {
        return poller<WillpayDto1[]>(() => this.realTimeService.apiPollWillpaysGet([raceId]), 15000).pipe(
            map((result) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('willpays', [environment.firebase.url.liveOdds, result]);
                }
                return result;
            }),
            takeUntil(this.componentDestroy())
        );
    }

    /**
     * Polls the real-time service for probables updates for a specific race.
     * @param raceId - the id of the race to subscribe to
     * @returns an observable that emits probables for the specified race
     */
    public subscribeToProbables(raceId: number): Observable<ProbablesDto> {
        return poller<ProbablesDto>(() => this.realTimeService.apiPollProbablesGet([raceId]), 15000).pipe(
            map((result) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('probables', [environment.firebase.url.liveOdds, result]);
                }
                return result;
            }),
            takeUntil(this.componentDestroy())
        );
    }

    /**
     * Polls the real-time service for pools updates for a specific race.
     * @param raceId - the id of the race to subscribe to
     * @returns an observable that emits pools for the specified race
     */
    public subscribeToPools(raceId: number): Observable<PoolsDto> {
        return poller<PoolsDto>(() => this.realTimeService.apiPollPoolsGet([raceId]), 15000).pipe(
            map((result) => {
                if (environment.firebase.showConsoleLog) {
                    console.log('probables', [environment.firebase.url.liveOdds, result]);
                }
                return result;
            }),
            takeUntil(this.componentDestroy())
        );
    }
}

export interface PostTimeUpdate {
    raceId: number;
    postTime: number;
}

export interface RaceStatusUpdate {
    raceId: number;
    raceStatus: string;
}
