import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../statemanagement/state/application.state';
import {setScreen} from '../statemanagement/actions/screen.actions';
import { Observable } from 'rxjs';
import { ScreenState } from '../statemanagement/state/screen.state';

@Injectable({
  providedIn: 'root',
})
export class AppSandboxService {
  mobile$: Observable<boolean> = this.store.select((state) => state.screen.mobile);
  tablet$: Observable<boolean> = this.store.select((state) => state.screen.tablet);
  desktop$: Observable<boolean> = this.store.select((state) => state.screen.desktop);
  allScreens$: Observable<ScreenState> = this.store.select((state) => state.screen);

  constructor(private store: Store<ApplicationState>) {}

  setWindowWidth(windowWidth: number): void {
    this.store.dispatch(setScreen({width: windowWidth}));
  }
}
