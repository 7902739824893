import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './guards/auth.guard';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ToastService } from '@services/toast.service';
import { VariablesService } from '@services/variables.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModule } from '@swagger-codegen/*';
import {ServiceWorkerModule, SwRegistrationOptions} from '@angular/service-worker';
import { JtHttp } from './JtHttp';
import { LoginService } from '@services/login.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { VersionCheckService } from '@services/version-check.service';
import { SignupComponent } from './components/signup/signup.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { getMetaReducers } from './statemanagement/root.reducer';
import { AppSandboxService } from '@services/sandbox.service';
import { StoreModule } from '@ngrx/store';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from '@services/rollbar.service';
import { BlockUiComponent } from './components/block-ui/block-ui.component';
import { ResetDeviceComponent } from './components/reset-device/reset-device.component';
import { Configuration } from './swagger-codegen';
import { PlansAnonymousComponent } from './components/plans-anonymous/plans-anonymous.component';
import { IntercomService } from '@services/intercom.service';
import { ActivityService } from '@services/activity.service';
import { JoyrideModule, JoyrideService } from 'ngx-joyride';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from './modules/shared-module/shared.module';
import { SignupPlansComponent } from './components/signup-plans/signup-plans.component';
import { GroupAdminGuard } from './guards/group-admin.guard';
import { AdminRedirectComponent } from './components/admin-redirect/admin-redirect.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationService } from '@services/missing-translation.service';
import { AppRoutingModule } from './app-routing.module';
import {screenReducer} from "./statemanagement/reducers/screen.reducer";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetDeviceComponent,
    SignupComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    PlansAnonymousComponent,
    BlockUiComponent,
    SignupPlansComponent,
    AdminRedirectComponent,
  ],
  imports: [
    NgSelectModule,
    ApiModule.forRoot(apiConfig),
    JoyrideModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: [
          'localhost:20002',
          'dashboard-staging.equinedge.com',
          'dashboard-stage.equinedge.com',
          'dashboard-dev.equinedge.com',
          'dashboard.equinedge.com',
        ],
      },
    }),
    NgbModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      messageClass: 'toast-fa-icon',
      closeButton: false,
      enableHtml: true,
      toastClass: 'custom-toast',
      preventDuplicates: true,
    }),
    StoreModule.forRoot({screen: screenReducer}, {metaReducers: getMetaReducers()}),
    StoreDevtoolsModule.instrument({}),
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.enable.enableServiceWorker}),
    },
    ActivityService,
    AuthGuard,
    GroupAdminGuard,
    LoginService,
    ToastService,
    VariablesService,
    VersionCheckService,
    JtHttp,
    AppSandboxService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    IntercomService,
    JoyrideService,
    Title,
    TranslateStore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function apiConfig(): any {
  return new Configuration({
    basePath: environment.ApiBaseUrl,
    apiKeys: { ['Authorization']: 'Bearer ' + jwtTokenGetter() },
  });
}

export function jwtTokenGetter(): any {
  return localStorage.getItem('access_token');
}

export function setCookie(name: string, value: string, days: number): void {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}; ${expires}; domain=.equinedge.com; path=/; samesite=None; secure`;
}

export function deleteCookie(name: string): void {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = `${name}=;${expires};`;
}
