import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TakeUntilDestroy } from "@services/take-until-destroy.decorator";
import { Observable } from "rxjs";
import {ContestantDetails, HorseService, SimplePastPerformance, SireDamPpsDto} from "@swagger-codegen/*";
import { takeUntil } from "rxjs/operators";
import _ from "lodash";
import { HorseData } from "../../models/HorseData";
import { AppSandboxService } from "@services/sandbox.service";

export enum View {
  GsrPlusView = 'GsrPlusView',
  SireOrDamPpsView = 'SireOrDamPpsView',
}

@TakeUntilDestroy
@Component({
  selector: 'app-modal-sire-dam',
  templateUrl: './modal-sire-dam.component.html',
  styleUrls: ['./modal-sire-dam.component.scss']
})
export class ModalSireDamComponent implements OnInit {
  componentDestroy: () => Observable<boolean>;
  private modalElementId = 'custom-modal-container';
  toggleJockey = false;
  toggleTrouble = false;
  _data: { raceId?: number, registrationNumber?: string, parentType?: "sire" | "dam", horseData?: HorseData };
  @Input()
  set data(value: {
    raceId?: number,
    registrationNumber?: string,
    parentType?: "sire" | "dam",
    horseData?: HorseData
  }) {
    if (value) {
      this._data = value;
      this.init();
      this.getSireDamData();
    }
  }

  changeView(raceId?: number, registrationNumber?: string, parentType?: "sire" | "dam"): void {
    this.data = {
      raceId: raceId,
      registrationNumber: registrationNumber,
      parentType: parentType,
      horseData: this._data.horseData
    };
  }

  toggleView(value: View) {
    this.currentView = value;
  }

  get data() {
    return this._data;
  }

  @Output()
  closeModal$: EventEmitter<boolean> = new EventEmitter<boolean>();
  sireDamPpsDto?: SireDamPpsDto;
  sireDamPps?: SimplePastPerformance[];
  sireDamPpsLoading = true;
  public currentView: View = View.SireOrDamPpsView;

  constructor(private horseService: HorseService, private sb: AppSandboxService) {
    this.sb.desktop$.pipe(takeUntil(this.componentDestroy())).subscribe((result) => {
      const modal = document.getElementById(this.modalElementId);
      modal.style.top = '10%';
      if (result) {
        modal.style.minWidth = '1300px';
        modal.style.marginLeft = '-650px'
      } else {
        modal.style.minWidth = '1024px';
        modal.style.marginLeft = '-512px'
      }
    })
  }

  ngOnInit(): void {
  }

  changeToggleJockey() {
    this.toggleJockey = !this.toggleJockey;
  }

  init() {
    this.sireDamPpsDto = null;
    this.sireDamPps = [];
    this.sireDamPpsLoading = true;
  }

  generateSireName(): string {
    let name = `${this.data?.horseData?.horseProfile?.sireName}`;

    let studFee = '';
    if (this.sireDamPpsDto?.studFee > 0) {
      if (this.sireDamPpsDto?.studFee >= 1000000) {
        studFee = (this.sireDamPpsDto?.studFee / 1000000).toFixed(1) + 'M';
      } else {
        if (this.sireDamPpsDto?.studFee >= 1000) {
          studFee = (this.sireDamPpsDto?.studFee / 1000).toFixed(0) + 'k';
        } else {
          studFee = this.sireDamPpsDto?.studFee.toString();
        }
      }
      name += ` - $${studFee}`;
    }
    return name;
  }

  getSireDamData() {
    this.horseService.apiHorseSiredamppsGet(this.data.raceId, this.data.registrationNumber)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((response: SireDamPpsDto) => {
        this.sireDamPpsDto = response;
        if (response) {
          this.sireDamPps = response.pastPerformances;
        } else {
          this.sireDamPps = [];
        }
        this.sireDamPpsLoading = false;
      });
  }

  changeToggle() {
    this.toggleTrouble = !this.toggleTrouble;
  }

  replaceClm(v?: string): string {
    return v ? _.replace(v, /Claiming/g, 'Clm') : '';
  }

  replaceString(value: string): string {
    return _.chain(value).replace(/,/g, ', ').replace(/ {2}/g, ' ').value();
  }

  replaceSurface(value: string): string {
    switch (value) {
      case 'Turf':
      case 'Inner Turf':
      case 'Outer Turf':
        return 'T';
      case 'Dirt':
      case 'Inner Dirt':
      case 'Outer Dirt':
        return 'D';
      case 'Syn':
      case 'Inner Syn':
      case 'Outer Syn':
        return 'S';
      default:
        return value;
    }
  }

  checkInner(v: string) {
    return _.includes(v, 'Inner');
  }

  checkTurf(v: string) {
    return _.includes(v, 'Turf');
  }

  checkDirt(v: string) {
    return _.includes(v, 'Dirt');
  }

  checkSyn(v: string) {
    return _.includes(v, 'Syn');
  }

  checkOuter(v: string) {
    return _.includes(v, 'Outer');
  }

  closeModal() {
    this.closeModal$.emit(true);
  }

  replaceDashes(v?: string): string {
    return v ? _.replace(v, /-/g, '') : '';
  }

  clearDot(v) {
    if (v as string) {
      return v.replace('LLC', '').replace('.', '').replace(',', '');
    } else {
      return v;
    }
  }

  public readonly View = View;
}
