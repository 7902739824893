import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { PlanPassesComponent } from './plan-passes/plan-passes.component';
import { PlanMembershipsComponent } from './plan-memberships/plan-memberships.component';
import { PlanMeetsComponent } from './plan-meets/plan-meets.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TwitterButtonComponent } from './twitter-button/twitter-button.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    PromoCodeComponent,
    PlanPassesComponent,
    PlanMembershipsComponent,
    PlanMeetsComponent,
    TwitterButtonComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFirestoreModule,
        FormsModule,
        NgbModule,
        FormsModule,
        TranslateModule,
    ],
  exports: [
    PromoCodeComponent,
    PlanPassesComponent,
    PlanMembershipsComponent,
    PlanMeetsComponent,
    CommonModule,
    TwitterButtonComponent,
  ],
})
export class SharedModule {}
