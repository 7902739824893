import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlockUiService } from '@services/block-ui.service';

@Component({
  selector: 'app-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss'],
})
export class BlockUiComponent implements OnInit, OnDestroy {
  blockUiMessage: string = null;
  blockUiShowReloadButton = false;
  blockUiSubscription: Subscription;
  blockUiTimer;

  constructor() {}

  ngOnInit(): void {
    this.blockUiSubscription = BlockUiService.getBlockingMessageSubject().subscribe((result) => {
      if (this.blockUiTimer) {
        clearTimeout(this.blockUiTimer);
        this.blockUiTimer = null;
      }
      this.blockUiMessage = result;
      if (this.blockUiMessage !== null) {
        this.blockUiTimer = setTimeout(() => {
          this.blockUiShowReloadButton = true;
        }, 10000);
      } else {
        this.blockUiShowReloadButton = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.blockUiSubscription) {
      this.blockUiSubscription.unsubscribe();
    }
  }

  reload(): void {
    location.reload();
  }
}
