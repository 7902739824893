import { Component, OnInit } from '@angular/core';
import { VersionCheckService } from '@services/version-check.service';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import { Observable } from 'rxjs';

@TakeUntilDestroy
@Component({
  selector: 'app-admin-redirect',
  templateUrl: './admin-redirect.component.html',
  styleUrls: ['./admin-redirect.component.scss'],
})
export class AdminRedirectComponent implements OnInit {
  componentDestroy: () => Observable<boolean>;

  constructor(private vc: VersionCheckService) {}

  ngOnInit(): void {
    sessionStorage.clear();
    this.vc.clearSwCaches();
  }
}
