import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public warning: (message: string) => void;
  public warningWithClick: (message: string) => void;
  public success: (message: string) => void;
  public successRequiresClick: (message: string) => void;
  public error: (message: string) => void;
  public showApiError: (error: any) => void;
  public clearAllToasts: () => void;

  timerNumber: any;
  lastMessage = '';

  constructor(toastsManager: ToastrService) {
    this.success = (message: string): void => {
      message += '<br><div class="toast-ok"><span>Ok</span></div>';
      this.startMessageShowingTimer();
      toastsManager.success(message, null, {
        timeOut: 5000 + 0.1 * message.length,
        enableHtml: true,
      });
    };

    this.successRequiresClick = (message: string): void => {
      message += '<br><div class="toast-ok"><span>Ok</span></div>';
      this.startMessageShowingTimer();
      toastsManager.success(message, null, {
        disableTimeOut: true,
      });
    };

    this.warning = (message: string): void => {
      message += '<br><div class="toast-ok"><span>Ok</span></div>';
      this.startMessageShowingTimer();
      toastsManager.warning(message, null, {
        timeOut: 5000 + 0.1 * message.length,
        enableHtml: true,
      });
    };

    this.warningWithClick = (message: string): void => {
      message += '<br><div class="toast-ok"><span>Ok</span></div>';
      this.startMessageShowingTimer();
      toastsManager.warning(message, null, { disableTimeOut: true });
    };

    this.error = (message: string) => {
      message += '<br><div class="toast-ok"><span>Ok</span></div>';
      if (this.lastMessage && message === this.lastMessage) {
        toastsManager.clear();
      }
      this.lastMessage = message;
      this.startMessageShowingTimer();
      toastsManager.error(message, null, {
        timeOut: 3000,
        enableHtml: true,
      });
    };
    this.showApiError = (error: Error) => {
      this.error(error.message);
    };

    this.clearAllToasts = () => {
      if (!this.timerNumber) {
        toastsManager.clear();
      }
    };
  }

  startMessageShowingTimer(): void {
    // if time < 0.5 sec  not need clear all message
    if (this.timerNumber) {
      clearTimeout(this.timerNumber);
    }

    this.timerNumber = setTimeout(() => {
      this.timerNumber = null;
    }, 500);
  }
}
