<ng-template #timeOffClaim>
  <div class="blue-inner-block px-1 py-1 d-flex">
    <div>
      {{trainerStatistics?.secondTimeOffClaim?.shouldHighlight ? 'SECOND' : 'FIRST'}} RACE WITH NEWLY CLAIMED HORSE
    </div>
<!--    <div class="flex-fill text-right" *ngIf="isFirstTimeTrainer()">
      <img ngSrc="assets/icons/first-time-started.svg" alt="first time started" height="18" width="13">
    </div>-->
  </div>
  <div class="data" [ngClass]="{'mobile':(mobile$ | async)}">
    <ng-container *ngIf="trainerStatistics?.hasNeverBeenTrainerBefore">
      This is the {{trainerStatistics?.secondTimeOffClaim?.shouldHighlight ? 'second' : 'first'}} race for {{getTrainerName()}} and
      {{getHorseName()}} after being claimed {{claimedModel?.claimedPrice | currency:'USD':'symbol':'4.0' }} on {{claimedModel?.claimedAt}}.
      {{getTrainerName()}}'s historical
      record
      with
      {{trainerStatistics?.secondTimeOffClaim?.shouldHighlight ? 'second time out' : 'newly claimed'}} horses is as follows
    </ng-container>
    <ng-container *ngIf="!trainerStatistics?.hasNeverBeenTrainerBefore">
      This is the {{trainerStatistics?.secondTimeOffClaim?.shouldHighlight ? 'second' : 'first'}} race for {{getTrainerName()}} off claim for
      {{claimedModel?.claimedPrice | currency:'USD':'symbol':'4.0' }} on {{claimedModel?.claimedAt}}.
      {{getTrainerName()}}'s historical
      record
      with
      {{trainerStatistics?.secondTimeOffClaim?.shouldHighlight ? 'second time off claim' : 'first off claimed'}} horses is as follows
    </ng-container>
  </div>
  <ng-container *ngIf="trainerStatistics?.firstTimeOffClaim?.shouldHighlight">
    <div class="inner-block mt-2">
      <span class="label">1st Race Off Claim:</span>
      <span [ngClass]="{'font-gotham-b':trainerStatistics?.firstTimeOffClaim?.shouldHighlight}"
            class="value">{{trainerStatistics?.firstTimeOffClaim?.numberOfRaces}}
        | {{getWinPct(trainerStatistics?.firstTimeOffClaim?.winPercentage)}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="trainerStatistics?.secondTimeOffClaim?.shouldHighlight">
    <div class="inner-block mt-2">
      <span class="label">2nd Race Off Claim:</span>
      <span [ngClass]="{'font-gotham-b':trainerStatistics?.secondTimeOffClaim?.shouldHighlight}"
            class="value">{{trainerStatistics?.secondTimeOffClaim?.numberOfRaces}}
        | {{getWinPct(trainerStatistics?.secondTimeOffClaim?.winPercentage)}}</span>
    </div>
  </ng-container>
</ng-template>
<ng-template #timeOffLayoff>
  <div class="blue-inner-block px-1 py-1 d-flex">
    <div>
      {{trainerStatistics?.secondTimeOffLayoff?.shouldHighlight ? 'SECOND' : 'FIRST'}} RACE BACK FROM LAYOFF
    </div>
<!--    <div class="flex-fill text-right" *ngIf="isFirstTimeTrainer()">
      <img ngSrc="assets/icons/first-time-started.svg" alt="first time started" height="18" width="13">
    </div>-->
  </div>
  <div class="data" [ngClass]="{'mobile':(mobile$ | async)}">
    This is the {{trainerStatistics?.secondTimeOffLayoff?.shouldHighlight ? 'second' : 'first'}} race for {{getHorseName()}} in
    {{daysSinceLastRace}} days.
    {{getTrainerName()}}’s historical record, for horses {{trainerStatistics?.secondTimeOffLayoff?.shouldHighlight ? 'second' :
    'first'}} race off a layoff, is as follows
  </div>
  <ng-container *ngIf="trainerStatistics?.firstTimeOffLayoff?.shouldHighlight">
  <div class="inner-block mt-2">
    <span class="label">1st Race Back:</span>
    <span [ngClass]="{'font-gotham-b':trainerStatistics?.firstTimeOffLayoff?.shouldHighlight}"
          class="value">{{trainerStatistics?.firstTimeOffLayoff?.numberOfRaces}}
      | {{getWinPct(trainerStatistics?.firstTimeOffLayoff?.winPercentage)}}</span>
  </div>
  </ng-container>
  <ng-container *ngIf="trainerStatistics?.secondTimeOffLayoff?.shouldHighlight">
  <div class="inner-block mt-2">
    <span class="label">2nd Race Back:</span>
    <span [ngClass]="{'font-gotham-b':trainerStatistics?.secondTimeOffLayoff?.shouldHighlight}"
          class="value">{{trainerStatistics?.secondTimeOffLayoff.numberOfRaces}}
      | {{getWinPct(trainerStatistics?.secondTimeOffLayoff?.winPercentage)}}</span>
  </div>
  </ng-container>
</ng-template>
<ng-template #timeStarter>
  <div class="blue-inner-block px-1 py-1 d-flex">
    <div>
      {{trainerStatistics?.secondTimeStarter?.shouldHighlight ? 'SECOND' : 'FIRST'}} TIME STARTER
    </div>
<!--    <div class="flex-fill text-right" *ngIf="isFirstTimeTrainer()">
      <img ngSrc="assets/icons/first-time-started.svg" alt="first time started" height="18" width="13">
    </div>-->
  </div>
  <div class="data" [ngClass]="{'mobile':(mobile$ | async)}">
    This is the {{trainerStatistics?.secondTimeStarter?.shouldHighlight ? 'second' : 'first'}} lifetime race for {{getHorseName()}}.
    {{getTrainerName()}}’s historical record with {{trainerStatistics?.secondTimeStarter?.shouldHighlight ? 'second' : 'first'}} time starters is as follows
  </div>
  <ng-container *ngIf="trainerStatistics?.firstTimeStarter?.shouldHighlight">
    <div class="inner-block mt-2">
      <span class="label">1st Lifetime Start:</span>
      <span [ngClass]="{'font-gotham-b':trainerStatistics?.firstTimeStarter?.shouldHighlight}"
            class="value">{{trainerStatistics?.firstTimeStarter?.numberOfRaces}}
        | {{getWinPct(trainerStatistics?.firstTimeStarter?.winPercentage)}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="trainerStatistics?.secondTimeStarter?.shouldHighlight">
    <div class="inner-block mt-2">
      <span class="label">2nd Lifetime Start:</span>
      <span [ngClass]="{'font-gotham-b':trainerStatistics?.secondTimeStarter?.shouldHighlight}"
            class="value">{{trainerStatistics?.secondTimeStarter.numberOfRaces}}
        | {{getWinPct(trainerStatistics?.secondTimeStarter?.winPercentage)}}</span>
    </div>
  </ng-container>
</ng-template>
<ng-template #timeOffTrainerChange>
  <div class="blue-inner-block px-1 py-1 d-flex">
    <div>
      {{trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight ? 'SECOND' : 'FIRST'}} RACE WITH TRAINER:
    </div>
<!--    <div class="flex-fill text-right" *ngIf="isFirstTimeTrainer()">
      <img ngSrc="assets/icons/first-time-started.svg" alt="first time started" height="18" width="13">
    </div>-->
  </div>
  <div class="data" [ngClass]="{'mobile':(mobile$ | async)}">
    <ng-container *ngIf="trainerStatistics?.hasNeverBeenTrainerBefore">
      This is the {{trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight ? 'second' : 'first'}} race for {{getTrainerName()}} and {{getHorseName()}}.
      {{getTrainerName()}}’s historical record with {{trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight ?
      'horses second time out' : 'new horses'}} is as follows
    </ng-container>
    <ng-container *ngIf="!trainerStatistics?.hasNeverBeenTrainerBefore">
      This is the {{trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight ? 'second' : 'first'}} race back for {{getTrainerName()}}.
      {{getTrainerName()}}’s historical record with {{trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight ?
      'horses second time out' : 'new horses'}} is as follows
    </ng-container>
  </div>
  <ng-container *ngIf="trainerStatistics?.firstTimeOffTrainerChange?.shouldHighlight">
    <div class="inner-block mt-2">
      <span class="label">1st Race With Horse:</span>
      <span [ngClass]="{'font-gotham-b':trainerStatistics?.firstTimeOffTrainerChange?.shouldHighlight}"
            class="value">{{trainerStatistics?.firstTimeOffTrainerChange?.numberOfRaces}}
        | {{getWinPct(trainerStatistics?.firstTimeOffTrainerChange?.winPercentage)}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight">
    <div class="inner-block mt-2">
      <span class="label">2nd Race With Horse:</span>
      <span [ngClass]="{'font-gotham-b':trainerStatistics?.secondTimeOffTrainerChange?.shouldHighlight}"
            class="value">{{trainerStatistics?.secondTimeOffTrainerChange.numberOfRaces}}
        | {{getWinPct(trainerStatistics?.secondTimeOffTrainerChange?.winPercentage)}}</span>
    </div>
  </ng-container>
</ng-template>
<div class="close-block">
  <u (click)="closeModal()">close</u>
</div>
<div class="d-flex modal-trainer">
  <div [attr.class]="'image-block p' + backgroundId"></div>
  <div class="trainer-block">
    <div class="title text-left">TRAINER
    </div>
    <div class="px-2 pt-2">
      <div class="inner-block">
        <span class="label">Trainer name:</span> <span
        [ngClass]="{ 'first-time': isFirstTimeTrainer() }" class="value">{{ getTrainerName() }} </span>
      </div>
      <div class="inner-block mt-2">
        <span class="label">Win Percentage:</span>
        <span class="value"
              [ngClass]="{'font-gotham-b':trainerStatistics?.totalStat?.shouldHighlight}">{{trainerStatistics?.totalStat?.numberOfRaces}}
          | {{getTrainerWinPct()}} </span>
      </div>
      <ng-container *ngIf="!showCarousel">
        <ng-container *ngIf="trainerStatistics?.firstTimeOffClaim?.numberOfRaces">
          <ng-container *ngTemplateOutlet="timeOffClaim"></ng-container>
        </ng-container>
        <ng-container *ngIf="trainerStatistics?.firstTimeOffLayoff?.numberOfRaces">
          <ng-container *ngTemplateOutlet="timeOffLayoff"></ng-container>
        </ng-container>
        <ng-container *ngIf="trainerStatistics?.firstTimeStarter?.numberOfRaces">
          <ng-container *ngTemplateOutlet="timeStarter"></ng-container>
        </ng-container>
        <ng-container *ngIf="trainerStatistics?.firstTimeOffTrainerChange?.numberOfRaces">
          <ng-container *ngTemplateOutlet="timeOffTrainerChange"></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="showCarousel">
        <ngb-carousel [wrap]="false">
          <ng-template ngbSlide *ngIf="trainerStatistics?.firstTimeOffClaim?.numberOfRaces">
            <ng-container *ngTemplateOutlet="timeOffClaim"></ng-container>
          </ng-template>
          <ng-template ngbSlide *ngIf="trainerStatistics?.firstTimeOffLayoff?.numberOfRaces">
            <ng-container *ngTemplateOutlet="timeOffLayoff"></ng-container>
          </ng-template>
          <ng-template ngbSlide *ngIf="trainerStatistics?.firstTimeStarter?.numberOfRaces">
            <ng-container *ngTemplateOutlet="timeStarter"></ng-container>
          </ng-template>
          <ng-template ngbSlide *ngIf="trainerStatistics?.firstTimeOffTrainerChange?.numberOfRaces">
            <ng-container *ngTemplateOutlet="timeOffTrainerChange"></ng-container>
          </ng-template>
        </ngb-carousel>
      </ng-container>
    </div>
    <div class="trainer-footer" [ngClass]="{'mobile':(mobile$ | async)}">
      All statistics are calculated using trailing two years.
    </div>
  </div>
</div>
