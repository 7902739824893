import { Observable, Subscription } from 'rxjs';

export class CacheObservable<T> extends Observable<T> {
  public subscribeWithNextAndError(nextAndError: () => void, next: (T) => void): Subscription {
    return this.subscribe(
      (data) => {
        next(data);
        nextAndError();
      },
      (error) => nextAndError()
    );
  }
}
