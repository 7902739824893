import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  public childComponentRef: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public appendComponentTo(parentId: string, child: any, childConfig?: ChildConfig): void {
    // Create a component reference from the component
    const childComponentRef = this.componentFactoryResolver.resolveComponentFactory(child).create(this.injector);

    // Attach the config to the child (inputs and outputs)
    this.attachConfig(childConfig, childComponentRef);

    this.childComponentRef = childComponentRef;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(childComponentRef.hostView);

    // Get DOM element from component
    const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.getElementById(parentId).appendChild(childDomElem);
  }

  public removeComponent(): void {
    this.appRef.detachView(this.childComponentRef.hostView);
    if (this.childComponentRef) {
      this.childComponentRef.destroy();
    }
  }

  private attachConfig(config, componentRef): void {
    const inputs = config.inputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key)) {
        componentRef.instance[key] = inputs[key];
      }
    }
  }
}

interface ChildConfig {
  inputs: object;
}
