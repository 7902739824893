import { ComponentRef, Injectable } from '@angular/core';
import { DomService } from './dom.service';
import { ModalSubUpsellComponent } from '../components/modal-sub-upsell/modal-sub-upsell.component';
import { Subscriber } from 'rxjs';
import { ModalHandiviewPlusComponent } from '../components/modal-handiview-plus/modal-handiview-plus.component';
import {
  AccountChangePasswordComponent
} from '../components/account/account-change-password/account-change-password.component';
import { ModalTgUpsellComponent } from "../components/modal-tg-upsell/modal-tg-upsell.component";
import {
  ModalTrainerWithCarouselComponent
} from "../components/modal-trainer-with-carousel/modal-trainer-with-carousel.component";
import {
  ContestantDetails,
  GetHorsesToWatchDto, GetHorsesToWatchResultsDto,
  SimplePastPerformance
} from "@swagger-codegen/*";
import { ModalSireDamComponent } from "../components/modal-sire-dam/modal-sire-dam.component";
import { HorseData } from "../models/HorseData";
import {
  ModalHorsesToWatchComponent
} from "../components/horses-to-watch/modal-horses-to-watch/modal-horses-to-watch.component";
import { ModalTutorialStarterComponent } from "../components/modal-tutorial-starter/modal-tutorial-starter.component";


@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private domService: DomService) {
  }

  private modalElementId = 'custom-modal-container';
  private overlayElementId = 'custom-overlay';
  private closeSub: Subscriber<any>;

  /**
   * Attach the upsell modal so we can show plans to the user...
   *
   * @param reason
   */
  public showMainUpsellModal(
    reason:
      | 'date'
      | 'morning-line'
      | 'trainers-edge'
      | 'track'
      | 'race'
      | 'handiview'
      | 'handiview-plus'
      | 'notes'
      | 'pre-race-sor'
      | 'ticket-book'
      | 'gsr'
      | 'dashboard' = 'date'
  ): void {
    if (document.getElementById(this.modalElementId) && this.modalIsHidden) {
      this.init<ModalSubUpsellComponent>(ModalSubUpsellComponent, {
        reason: reason,
        close: this.destroy,
      })
    }
  }

  public showTgUpsellModal(
    reason:
      | 'date'
      | 'morning-line'
      | 'trainers-edge'
      | 'track'
      | 'race'
      | 'handiview'
      | 'handiview-plus'
      | 'notes'
      | 'pre-race-sor'
      | 'ticket-book'
      | 'gsr'
      | 'dashboard' = 'date'
  ): void {
    if (document.getElementById(this.modalElementId) && this.modalIsHidden) {
      this.init<ModalTgUpsellComponent>(ModalTgUpsellComponent, {reason: reason, close: this.destroy});
    }
  }

  init<T>(component: any, inputs: object): ComponentRef<T> {
    const componentConfig = {
      inputs,
    };
    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'show';
    document.getElementById(this.overlayElementId).className = 'show';
    if ((this.domService.childComponentRef as ComponentRef<any>)?.instance?.closeModal$) {
      this.closeSub = (this.domService.childComponentRef as ComponentRef<any>).instance.closeModal$.subscribe(
        (close) => {
          if (close) {
            this.destroy();
          }
        }
      );
    }
    return this.domService.childComponentRef as ComponentRef<T>;
  }

  public changePassword(): void {
    if (document.getElementById(this.modalElementId) && this.modalIsHidden) {
      this.init<AccountChangePasswordComponent>(AccountChangePasswordComponent, {
        close: this.destroy,
      });
    }
  }

  public showHandiViewModal(): void {
    this.init<ModalHandiviewPlusComponent>(ModalHandiviewPlusComponent, {});
  }

  get modalIsHidden(): boolean {
    return document.getElementById(this.modalElementId)?.className !== 'show';
  }

  public showTrainerModal(data: ContestantDetails, pastData: SimplePastPerformance, raceDate: string) {
    this.init<ModalTrainerWithCarouselComponent>(ModalTrainerWithCarouselComponent, {
      data: data, pastData: pastData, raceDate: raceDate,
      close: this.destroy
    });
  }

  public showHorsesToWatchModal(horsesToWatch: GetHorsesToWatchDto, horsesToWatchResults: GetHorsesToWatchResultsDto) {
    this.init<ModalHorsesToWatchComponent>(ModalHorsesToWatchComponent, {
      horsesToWatch, horsesToWatchResults,
      close: this.destroy
    });
  }

  public showTutorialStarterModal() {
    this.init<ModalTutorialStarterComponent>(ModalTutorialStarterComponent, {
      close: this.destroy
    });
  }

  public showSireDamModal(data: {
    raceId?: number,
    registrationNumber?: string,
    parentType?: "sire" | "dam",
    horseData?: HorseData
  }) {

    this.init<ModalSireDamComponent>(ModalSireDamComponent, {
      data: data,
      close: this.destroy
    });
  }

  public getShortName(pastData: SimplePastPerformance, data: ContestantDetails): string {
    const name = (pastData !== null ? pastData?.trainerName : data?.trainerStats?.name) ?? '';
    if (name) {
      if (name.indexOf(' ') === -1) {
        return name;
      } else {
        const nameArray = name.split(' ');
        const shortName = nameArray[0][0] + '. ' + nameArray.slice(1, nameArray.length).join(' ');
        if (shortName?.length > 14) {
          return shortName.slice(0, 14) + '...';
        }
        return shortName;
      }
    }
    return name;
  }

  destroy(): void {
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = 'hidden';
    document.getElementById(this.overlayElementId).className = 'hidden';
  }
}
