/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddUserCommand } from '../model/addUserCommand';
import { CancelExistingCommand } from '../model/cancelExistingCommand';
import { CheckoutExistingCustomerCommand } from '../model/checkoutExistingCustomerCommand';
import { CheckoutExistingCustomerErrorCommand } from '../model/checkoutExistingCustomerErrorCommand';
import { CheckoutExistingCustomerSuccessCommand } from '../model/checkoutExistingCustomerSuccessCommand';
import { CheckoutNewCustomerCommand } from '../model/checkoutNewCustomerCommand';
import { CheckoutNewCustomerErrorCommand } from '../model/checkoutNewCustomerErrorCommand';
import { CheckoutNewCustomerSuccessCommand } from '../model/checkoutNewCustomerSuccessCommand';
import { HostedPage } from '../model/hostedPage';
import { PortalSession } from '../model/portalSession';
import { ReactivateExistingCommand } from '../model/reactivateExistingCommand';
import { Unit } from '../model/unit';
import { UpgradeExistingSubscriptionCommand } from '../model/upgradeExistingSubscriptionCommand';
import { UserListCommand } from '../model/userListCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class CustomerService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerAddUserPost(body?: AddUserCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerAddUserPost(body?: AddUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerAddUserPost(body?: AddUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerAddUserPost(body?: AddUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/add-user`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCancelSubscriptionPost(body?: CancelExistingCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiCustomerCancelSubscriptionPost(body?: CancelExistingCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiCustomerCancelSubscriptionPost(body?: CancelExistingCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiCustomerCancelSubscriptionPost(body?: CancelExistingCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Customer/cancel-subscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCheckoutExistingErrorPost(body?: CheckoutExistingCustomerErrorCommand, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiCustomerCheckoutExistingErrorPost(body?: CheckoutExistingCustomerErrorCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiCustomerCheckoutExistingErrorPost(body?: CheckoutExistingCustomerErrorCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiCustomerCheckoutExistingErrorPost(body?: CheckoutExistingCustomerErrorCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Customer/checkout-existing-error`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCheckoutExistingPost(body?: CheckoutExistingCustomerCommand, observe?: 'body', reportProgress?: boolean): Observable<HostedPage>;
    public apiCustomerCheckoutExistingPost(body?: CheckoutExistingCustomerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HostedPage>>;
    public apiCustomerCheckoutExistingPost(body?: CheckoutExistingCustomerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HostedPage>>;
    public apiCustomerCheckoutExistingPost(body?: CheckoutExistingCustomerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HostedPage>('post',`${this.basePath}/api/Customer/checkout-existing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCheckoutExistingSuccessPost(body?: CheckoutExistingCustomerSuccessCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerCheckoutExistingSuccessPost(body?: CheckoutExistingCustomerSuccessCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerCheckoutExistingSuccessPost(body?: CheckoutExistingCustomerSuccessCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerCheckoutExistingSuccessPost(body?: CheckoutExistingCustomerSuccessCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/checkout-existing-success`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCheckoutNewErrorPost(body?: CheckoutNewCustomerErrorCommand, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiCustomerCheckoutNewErrorPost(body?: CheckoutNewCustomerErrorCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiCustomerCheckoutNewErrorPost(body?: CheckoutNewCustomerErrorCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiCustomerCheckoutNewErrorPost(body?: CheckoutNewCustomerErrorCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Customer/checkout-new-error`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCheckoutNewPost(body?: CheckoutNewCustomerCommand, observe?: 'body', reportProgress?: boolean): Observable<HostedPage>;
    public apiCustomerCheckoutNewPost(body?: CheckoutNewCustomerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HostedPage>>;
    public apiCustomerCheckoutNewPost(body?: CheckoutNewCustomerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HostedPage>>;
    public apiCustomerCheckoutNewPost(body?: CheckoutNewCustomerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HostedPage>('post',`${this.basePath}/api/Customer/checkout-new`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCheckoutNewSuccessPost(body?: CheckoutNewCustomerSuccessCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerCheckoutNewSuccessPost(body?: CheckoutNewCustomerSuccessCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerCheckoutNewSuccessPost(body?: CheckoutNewCustomerSuccessCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerCheckoutNewSuccessPost(body?: CheckoutNewCustomerSuccessCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/checkout-new-success`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param CustomerId 
     * @param Uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerPortalStartGet(CustomerId?: string, Uuid?: string, observe?: 'body', reportProgress?: boolean): Observable<PortalSession>;
    public apiCustomerPortalStartGet(CustomerId?: string, Uuid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PortalSession>>;
    public apiCustomerPortalStartGet(CustomerId?: string, Uuid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PortalSession>>;
    public apiCustomerPortalStartGet(CustomerId?: string, Uuid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (CustomerId !== undefined && CustomerId !== null) {
            queryParameters = queryParameters.set('CustomerId', <any>CustomerId);
        }
        if (Uuid !== undefined && Uuid !== null) {
            queryParameters = queryParameters.set('Uuid', <any>Uuid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PortalSession>('get',`${this.basePath}/api/Customer/portal-start`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerReactivateSubscriptionPost(body?: ReactivateExistingCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiCustomerReactivateSubscriptionPost(body?: ReactivateExistingCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiCustomerReactivateSubscriptionPost(body?: ReactivateExistingCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiCustomerReactivateSubscriptionPost(body?: ReactivateExistingCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Customer/reactivate-subscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerUpgradeSubscriptionPost(body?: UpgradeExistingSubscriptionCommand, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiCustomerUpgradeSubscriptionPost(body?: UpgradeExistingSubscriptionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiCustomerUpgradeSubscriptionPost(body?: UpgradeExistingSubscriptionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiCustomerUpgradeSubscriptionPost(body?: UpgradeExistingSubscriptionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/Customer/upgrade-subscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerUsersPost(body?: UserListCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerUsersPost(body?: UserListCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerUsersPost(body?: UserListCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerUsersPost(body?: UserListCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/Users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
