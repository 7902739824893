import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@services/login.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TakeUntilDestroy } from '@services/take-until-destroy.decorator';
import _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  resetEmail: string;
  code: string;
  componentDestroy: () => Observable<boolean>;
  promoCode: string;
  showPassword = false;

  constructor(
    public loginService: LoginService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.code = '';
    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl('', {validators: [Validators.required]}),
    });

    this.route.queryParams.pipe(takeUntil(this.componentDestroy())).subscribe((params) => {
      if (params && params['code']) {
        this.code = params['code'];
      }
      if (this.code === 'W3lC0m3.TVG.2022.Nonce$=') {
        this.loginForm.controls['email'].setValue(decodeURIComponent(params['email'] + ''));
        this.loginForm.controls['password'].setValue(decodeURIComponent(params['password'] + ''));

        this.onSubmit();
      }
      if (params && params['promoCode']) {
        this.promoCode = _.toUpper(params['promoCode']);
      }
    });
  }

  isValid(controlName: string): boolean {
    return (
      this.loginForm.controls[controlName].valid &&
      (this.loginForm.controls[controlName].dirty || this.loginForm.controls[controlName].touched)
    );
  }

  isInValid(controlName: string): boolean {
    return (
      this.loginForm.controls[controlName].invalid &&
      (this.loginForm.controls[controlName].dirty || this.loginForm.controls[controlName].touched)
    );
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
        if (result.valid) {
          this.loginService.changePassword(this.resetEmail);
          this.resetEmail = '';
        }
      },
      () => {
        this.resetEmail = '';
      }
    );
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl('', {validators: [Validators.required]}),
    });
  }

  ngOnDestroy(): void {
  }

  onSubmit(): void {
    this.loginService.customLogin(this.loginForm.value.email, this.loginForm.value.password, this.code);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
