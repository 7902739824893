/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthTokenResponse } from '../model/authTokenResponse';
import { BasicInfoUpdateArguments } from '../model/basicInfoUpdateArguments';
import { CancelSubscriptionArguments } from '../model/cancelSubscriptionArguments';
import { CancelSubscriptionCommand } from '../model/cancelSubscriptionCommand';
import { CurrentSubscriptionStatusResponse } from '../model/currentSubscriptionStatusResponse';
import { ForgotPasswordArguments } from '../model/forgotPasswordArguments';
import { IsEmailInUseResponse } from '../model/isEmailInUseResponse';
import { LoginUserCommand } from '../model/loginUserCommand';
import { MyAccountResponse } from '../model/myAccountResponse';
import { PaymentInfoUpdateArguments } from '../model/paymentInfoUpdateArguments';
import { PreDowngradeDto } from '../model/preDowngradeDto';
import { PreDowngradeQuery } from '../model/preDowngradeQuery';
import { PreJoinDto } from '../model/preJoinDto';
import { PreJoinQuery } from '../model/preJoinQuery';
import { PreUpgradeDto } from '../model/preUpgradeDto';
import { PreUpgradeQuery } from '../model/preUpgradeQuery';
import { PublicProfile } from '../model/publicProfile';
import { ReactivateSubscriptionCommand } from '../model/reactivateSubscriptionCommand';
import { ReactivateSubscriptionnArguments } from '../model/reactivateSubscriptionnArguments';
import { RegisterCommand } from '../model/registerCommand';
import { ResetPasswordCommand } from '../model/resetPasswordCommand';
import { SubscribeCommand } from '../model/subscribeCommand';
import { SubscriptionDowngradeCommand } from '../model/subscriptionDowngradeCommand';
import { SubscriptionDto } from '../model/subscriptionDto';
import { SubscriptionUpgradeCommand } from '../model/subscriptionUpgradeCommand';
import { UpdatePasswordCommand } from '../model/updatePasswordCommand';
import { UpgradeSubscriptionCommand } from '../model/upgradeSubscriptionCommand';
import { UserFileArgument } from '../model/userFileArgument';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class AccountService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountAllActiveSubscriptionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CurrentSubscriptionStatusResponse>>;
    public apiAccountAllActiveSubscriptionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CurrentSubscriptionStatusResponse>>>;
    public apiAccountAllActiveSubscriptionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CurrentSubscriptionStatusResponse>>>;
    public apiAccountAllActiveSubscriptionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CurrentSubscriptionStatusResponse>>('get',`${this.basePath}/api/Account/AllActiveSubscriptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountAvatarFileBase64Get(id?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAccountAvatarFileBase64Get(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAccountAvatarFileBase64Get(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAccountAvatarFileBase64Get(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/Account/AvatarFileBase64`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountAvatarFileGet(id?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountAvatarFileGet(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountAvatarFileGet(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountAvatarFileGet(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Account/AvatarFile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MyAccountResponse>('post',`${this.basePath}/api/Account/BasicInfo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountCancelSubPost(body?: CancelSubscriptionCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountCancelSubPost(body?: CancelSubscriptionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountCancelSubPost(body?: CancelSubscriptionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountCancelSubPost(body?: CancelSubscriptionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/CancelSub`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/CancelSubscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountCurrentSubscriptionStatusGet(observe?: 'body', reportProgress?: boolean): Observable<CurrentSubscriptionStatusResponse>;
    public apiAccountCurrentSubscriptionStatusGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentSubscriptionStatusResponse>>;
    public apiAccountCurrentSubscriptionStatusGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentSubscriptionStatusResponse>>;
    public apiAccountCurrentSubscriptionStatusGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CurrentSubscriptionStatusResponse>('get',`${this.basePath}/api/Account/CurrentSubscriptionStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountDayPassCheckGet(observe?: 'body', reportProgress?: boolean): Observable<PreUpgradeDto>;
    public apiAccountDayPassCheckGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PreUpgradeDto>>;
    public apiAccountDayPassCheckGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PreUpgradeDto>>;
    public apiAccountDayPassCheckGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PreUpgradeDto>('get',`${this.basePath}/api/Account/DayPassCheck`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountDeleteAvatarDelete(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountDeleteAvatarDelete(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountDeleteAvatarDelete(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountDeleteAvatarDelete(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Account/DeleteAvatar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ForgotPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountIsEmailInUseGet(email?: string, observe?: 'body', reportProgress?: boolean): Observable<IsEmailInUseResponse>;
    public apiAccountIsEmailInUseGet(email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IsEmailInUseResponse>>;
    public apiAccountIsEmailInUseGet(email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IsEmailInUseResponse>>;
    public apiAccountIsEmailInUseGet(email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IsEmailInUseResponse>('get',`${this.basePath}/api/Account/IsEmailInUse`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountLogoutPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountLogoutPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountLogoutPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountLogoutPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/Logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountMyAccountGet(observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAccountMyAccountGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAccountMyAccountGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAccountMyAccountGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MyAccountResponse>('get',`${this.basePath}/api/Account/MyAccount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPasswordLoginPost(body?: LoginUserCommand, observe?: 'body', reportProgress?: boolean): Observable<AuthTokenResponse>;
    public apiAccountPasswordLoginPost(body?: LoginUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthTokenResponse>>;
    public apiAccountPasswordLoginPost(body?: LoginUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthTokenResponse>>;
    public apiAccountPasswordLoginPost(body?: LoginUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AuthTokenResponse>('post',`${this.basePath}/api/Account/PasswordLogin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPasswordPut(body?: UpdatePasswordCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountPasswordPut(body?: UpdatePasswordCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountPasswordPut(body?: UpdatePasswordCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountPasswordPut(body?: UpdatePasswordCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Account/Password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPaymentInfoDelete(observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAccountPaymentInfoDelete(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAccountPaymentInfoDelete(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAccountPaymentInfoDelete(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MyAccountResponse>('delete',`${this.basePath}/api/Account/PaymentInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MyAccountResponse>('put',`${this.basePath}/api/Account/PaymentInfo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPreDowngradeCheckPost(body?: PreDowngradeQuery, observe?: 'body', reportProgress?: boolean): Observable<PreDowngradeDto>;
    public apiAccountPreDowngradeCheckPost(body?: PreDowngradeQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PreDowngradeDto>>;
    public apiAccountPreDowngradeCheckPost(body?: PreDowngradeQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PreDowngradeDto>>;
    public apiAccountPreDowngradeCheckPost(body?: PreDowngradeQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PreDowngradeDto>('post',`${this.basePath}/api/Account/PreDowngradeCheck`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPreJoinCheckPost(body?: PreJoinQuery, observe?: 'body', reportProgress?: boolean): Observable<PreJoinDto>;
    public apiAccountPreJoinCheckPost(body?: PreJoinQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PreJoinDto>>;
    public apiAccountPreJoinCheckPost(body?: PreJoinQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PreJoinDto>>;
    public apiAccountPreJoinCheckPost(body?: PreJoinQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PreJoinDto>('post',`${this.basePath}/api/Account/PreJoinCheck`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe?: 'body', reportProgress?: boolean): Observable<PreUpgradeDto>;
    public apiAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PreUpgradeDto>>;
    public apiAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PreUpgradeDto>>;
    public apiAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PreUpgradeDto>('post',`${this.basePath}/api/Account/PreUpgradeCheck`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountProfileGet(observe?: 'body', reportProgress?: boolean): Observable<PublicProfile>;
    public apiAccountProfileGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublicProfile>>;
    public apiAccountProfileGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublicProfile>>;
    public apiAccountProfileGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PublicProfile>('get',`${this.basePath}/api/Account/Profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountReactivateSubPost(body?: ReactivateSubscriptionCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountReactivateSubPost(body?: ReactivateSubscriptionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountReactivateSubPost(body?: ReactivateSubscriptionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountReactivateSubPost(body?: ReactivateSubscriptionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ReactivateSub`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ReactivateSubscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountRegisterPost(body?: RegisterCommand, observe?: 'body', reportProgress?: boolean): Observable<AuthTokenResponse>;
    public apiAccountRegisterPost(body?: RegisterCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthTokenResponse>>;
    public apiAccountRegisterPost(body?: RegisterCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthTokenResponse>>;
    public apiAccountRegisterPost(body?: RegisterCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AuthTokenResponse>('post',`${this.basePath}/api/Account/register`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountResetPasswordPost(body?: ResetPasswordCommand, observe?: 'body', reportProgress?: boolean): Observable<AuthTokenResponse>;
    public apiAccountResetPasswordPost(body?: ResetPasswordCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthTokenResponse>>;
    public apiAccountResetPasswordPost(body?: ResetPasswordCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthTokenResponse>>;
    public apiAccountResetPasswordPost(body?: ResetPasswordCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AuthTokenResponse>('post',`${this.basePath}/api/Account/ResetPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountSaveAvatarBase64Post(body?: UserFileArgument, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiAccountSaveAvatarBase64Post(body?: UserFileArgument, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiAccountSaveAvatarBase64Post(body?: UserFileArgument, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiAccountSaveAvatarBase64Post(body?: UserFileArgument, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/Account/SaveAvatarBase64`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountSubscribePost(body?: SubscribeCommand, observe?: 'body', reportProgress?: boolean): Observable<SubscriptionDto>;
    public apiAccountSubscribePost(body?: SubscribeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubscriptionDto>>;
    public apiAccountSubscribePost(body?: SubscribeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubscriptionDto>>;
    public apiAccountSubscribePost(body?: SubscribeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SubscriptionDto>('post',`${this.basePath}/api/Account/Subscribe`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/SubscriptionDowngrade`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/SubscriptionUpgrade`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountTestResetPasswordPost(body?: ResetPasswordCommand, observe?: 'body', reportProgress?: boolean): Observable<AuthTokenResponse>;
    public apiAccountTestResetPasswordPost(body?: ResetPasswordCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthTokenResponse>>;
    public apiAccountTestResetPasswordPost(body?: ResetPasswordCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthTokenResponse>>;
    public apiAccountTestResetPasswordPost(body?: ResetPasswordCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AuthTokenResponse>('post',`${this.basePath}/api/Account/TestResetPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountUpgradeSubscriptionPost(body?: UpgradeSubscriptionCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountUpgradeSubscriptionPost(body?: UpgradeSubscriptionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountUpgradeSubscriptionPost(body?: UpgradeSubscriptionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountUpgradeSubscriptionPost(body?: UpgradeSubscriptionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/UpgradeSubscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
