export class CacheEntry<T> {
  private _lastUpdated: Date;

  constructor(entity: T) {
    this._lastUpdated = new Date();
    this._value = entity;
  }

  private _value: T;

  public get value(): T {
    return this._value;
  }

  public get canBeRefreshed(): boolean {
    return (new Date().getTime() - this._lastUpdated.getTime()) / 1000 >= 60;
  }

  public hasValue(): boolean {
    return this._value !== null && this._value !== undefined;
  }
}
